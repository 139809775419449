import React, { useContext, useState } from 'react';
import { Trash2 } from 'lucide-react';
import { LoaderSpinner } from '../../../../components/LoaderSpinner';
import * as ShadeDialog from "components/ui/dialog";
import { Button } from "components/ui/button";
import { Context }  from 'context/GlobalState';
import { ragService } from 'api/services/PROJECT-O';
import { OrganizationContext } from 'context/OrganizationContext';
import { AITeacherContext } from 'pages/PromptingAndRagTraining/context/AITeacherContext';

const DeleteDialog = ({doc, loadFiles}) => {
    const { addNewNotifcation } = useContext(Context);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { selectedOrganization } = useContext(OrganizationContext);
    const { selectedDocs, setSelectedDocs } = useContext(AITeacherContext);
    

    const handleDelete = async () => {
        setIsLoading(true); // Set loading to true before starting the delete operation
        try {
            // Filter out the document that is being deleted from selectedDocs
            const updatedDocs = selectedDocs.filter(selectedDoc => selectedDoc._id !== doc._id);
            setSelectedDocs(updatedDocs);

            // Make API call to delete the document
            const res = await ragService.deleteDocument(
                doc.organization_id,
                doc.sub_organization_id,
                doc._id,
                `${selectedOrganization._id}-bex-demo-mode`
            );
            console.log(res);
            addNewNotifcation('Successfully deleted the document.', 'success');
        } catch (err) {
            console.error(err);
            addNewNotifcation('Document is not deleted. Please try again.', 'error');
        } finally {
            setIsLoading(false); // Ensure loading is turned off after operation
            setIsOpen(false); // Close the dialog
            loadFiles(); // Reload the list of files
        }
    };

    return (
        <ShadeDialog.Dialog open={isOpen} onOpenChange={setIsOpen}>
            <ShadeDialog.DialogTrigger asChild>
                <Button variant="ghost" size="icon" className="px-2" onClick={() => setIsOpen(true)}>
                    {isLoading ? <LoaderSpinner/> : <Trash2 className="w-5 h-5" />}
                </Button>
            </ShadeDialog.DialogTrigger>
            <ShadeDialog.DialogContent className="sm:max-w-[425px]">
                <ShadeDialog.DialogHeader>
                    <ShadeDialog.DialogTitle>Delete Document?</ShadeDialog.DialogTitle>
                    <ShadeDialog.DialogDescription>
                        Are you sure you want to delete this document?
                    </ShadeDialog.DialogDescription>
                </ShadeDialog.DialogHeader>
                <div className="grid gap-4 py-2">
                    <span className=''>{doc.name}</span>
                </div>
                <ShadeDialog.DialogFooter>
                    <ShadeDialog.DialogClose asChild>
                        <Button type="button" variant="secondary" onClick={() => setIsOpen(false)}>
                            Close
                        </Button>
                    </ShadeDialog.DialogClose>
                    <Button
                        type="button"
                        className="bg-red-500 hover:bg-red-600"
                        onClick={handleDelete}
                        disabled={isLoading}
                    >
                        {isLoading ? <LoaderSpinner/> : "Delete Permanently"}
                    </Button>
                </ShadeDialog.DialogFooter>
            </ShadeDialog.DialogContent>
        </ShadeDialog.Dialog>
    )
};

export default DeleteDialog;