import React, { useContext, useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from "components/ui/card";
import { Button } from "components/ui/button";
import { AITeacherContext } from 'pages/PromptingAndRagTraining/context/AITeacherContext';
import * as pdfjs from "pdfjs-dist";
import { ChevronRight, ChevronLeft } from 'lucide-react';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.mjs",
    import.meta.url
  ).toString();

const isPDF = (fileNameOrUrl) => {
    return fileNameOrUrl.toLowerCase().endsWith(".pdf");
};
const isPPT = (filename) => {
    const pptExtensions = ['.ppt', '.pptx'];
    return pptExtensions.some((ext) => filename.toLowerCase().endsWith(ext));
};

const isWORD = (filename) => {
    const wordExtensions = ['.doc', '.docx'];
    return wordExtensions.some((ext) => filename.toLowerCase().endsWith(ext));
};

const isSPREADSHEET = (filename) => {
    const spreadsheetExtensions = ['.xls', '.xlsx', '.csv'];
    return spreadsheetExtensions.some((ext) => filename.toLowerCase().endsWith(ext));
};

const DocumentDisplay = () => {
    const { selectedDocs } = useContext(AITeacherContext);
    const [selectedIndex, setSelectedIndex] = useState(0); // State for tracking the current index

    // Selected Docs
    useEffect(() => {
        console.log("selected docs", selectedDocs);
        setSelectedIndex(0);
    }, [selectedDocs]);

    const handlePrev = () => {
        setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    };

    const handleNext = () => {
        setSelectedIndex((prevIndex) => 
            (prevIndex < selectedDocs.length - 1 ? prevIndex + 1 : prevIndex)
        );
    };

    const currentDocName = selectedDocs[selectedIndex]?.name || "";

    return (
        <Card className="w-full flex flex-col justify-between h-[700px] p-2 gap-0 p-0 overflow-hidden">
            {selectedDocs.length > 0 && (
                <CardHeader className="p-0 bg-[#34343c] text-gray-100 rounded-t-md text-xs">
                    <div className="flex flex-row items-center justify-start p-2 gap-4 shadow select-none">
                        <Button onClick={handlePrev} variant="ghost" disabled={selectedIndex === 0}>
                            <ChevronLeft className="w-5 h-5" />
                        </Button>
                        <span className="text-center">
                            Document {selectedIndex + 1} of {selectedDocs.length}
                        </span>
                        <Button onClick={handleNext} variant="ghost" disabled={selectedIndex === selectedDocs.length - 1}>
                            <ChevronRight className="w-5 h-5" />
                        </Button>
                        <div className="max-w-[300px] truncate">
                            {selectedDocs[selectedIndex]?.name}
                        </div>
                    </div>
                    
                </CardHeader>
            
            )}
            <CardContent className="flex flex-row p-0 h-full overflow-hidden">
                {selectedDocs.length < 1 ? (
                    <div className='flex items-center justify-center w-full text-gray-600'>
                        <span>Select document(s) to display</span>
                    </div>
                ) : isPDF(currentDocName) ? 
                    <PDFViewer selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex}/>
                : <OfficeViewer selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex}/>}
            </CardContent>
        </Card>
    );
};

const PDFViewer = ({ selectedIndex, setSelectedIndex }) => {
    const { selectedDocs, clickedLink, setClickedLink, pageNumber, setPageNumber, changed, setChanged } = useContext(AITeacherContext);
    const [page, setPage] = useState(1); // Local state for the current page
    const [iframeSrc, setIframeSrc] = useState(""); // Current iframe source
    const [rerender, setRerender] = useState (1);

    useEffect(() => {
        if (clickedLink) {
            const currentFileUrl = selectedDocs[selectedIndex]?.file_url;

            // Check if clicked link differs from the currently selected document
            if (clickedLink !== currentFileUrl) {
                const newIndex = selectedDocs.findIndex(doc => doc.file_url === clickedLink);
                if (newIndex !== -1) {
                    setSelectedIndex(newIndex); // Update the selected index
                    setChanged(true);
                }
            } else {
                setChanged(false);
                setIframeSrc("");
                setRerender((prevRerender) => prevRerender + 1);
                const updatedSrc = `${currentFileUrl}#page=${pageNumber || 1}&toolbar=1&navpanes=0`;
                setIframeSrc(updatedSrc)
                setRerender((prevRerender) => prevRerender + 1);
            }
            
            setClickedLink(null); // Reset clicked link after processing
        }
    }, [clickedLink, pageNumber, selectedDocs, selectedIndex, setClickedLink, setPageNumber, setSelectedIndex]);

    useEffect(() => {
        const currentUrl = selectedDocs[selectedIndex]?.file_url || "";
        setIframeSrc(`${currentUrl}#page=${page}&toolbar=1&navpanes=0`); // Update iframe source
        setRerender((prevRerender) => prevRerender + 1);
    }, [selectedDocs, selectedIndex, page]);

    useEffect(() => {
        // Reset to the first page when a new document is selected (not from a clicked link)
        if (changed){
            setPage(pageNumber);
        } else {
            setPage(1);
        }
        setChanged(false); 
        setPageNumber(1);
    }, [selectedIndex]);

    return (
        <div className="h-full flex flex-col w-full">
            <div className="w-full h-full relative">
                <div className="absolute z-10 bg-opacity-0 bg-blue-200 w-[200px] h-[3rem] top-0 right-0"></div>
                <iframe
                    key={rerender} // Force re-render by using iframeSrc as key
                    src={iframeSrc}
                    title="PDF Viewer"
                    className="w-full h-full rounded-b-md"
                    onContextMenu={(e) => console.log(e)}
                />
            </div>
        </div>
    );
};




const OfficeViewer = ({ selectedIndex, setSelectedIndex }) => {
    const { selectedDocs, clickedLink, setClickedLink, pageNumber, setPageNumber, setChanged } = useContext(AITeacherContext);
    const [iframeSrc, setIframeSrc] = useState(""); // Current iframe source

    useEffect(() => {
        if (clickedLink) {
            const currentFileUrl = selectedDocs[selectedIndex]?.file_url;

            // Check if clicked link differs from the currently selected document
            if (clickedLink !== currentFileUrl) {
                const newIndex = selectedDocs.findIndex(doc => doc.file_url === clickedLink);
                if (newIndex !== -1) {
                    setSelectedIndex(newIndex); // Update the selected index
                    setChanged(true);
                }
            }
            
            
            setClickedLink(null); // Reset clicked link after processing
        }
    }, [clickedLink, pageNumber, selectedDocs, selectedIndex, setClickedLink, setPageNumber, setSelectedIndex]);

    useEffect(() => {
        // Update iframe source whenever the page number changes
        const url = selectedDocs[selectedIndex]?.file_url || "";
        setIframeSrc(`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`);
    }, [selectedDocs, selectedIndex]);

    const currentDocName = selectedDocs[selectedIndex]?.name || "";

    return (
        <div className="h-full flex flex-col w-full">
            <div className="h-full w-full relative">
                {/* Download prevent overlay */}
                {isPPT(currentDocName) ? 
                <div className="absolute z-10 bg-opacity-0 bg-blue-200 w-[200px] h-[1.6rem] bottom-0 right-0"></div>
                : isWORD(currentDocName) ?
                <div className="absolute z-10 bg-opacity-0 bg-blue-200 w-[200px] h-[1.3rem] bottom-0 right-0"></div>
                : isSPREADSHEET(currentDocName) ?
                <div className="absolute z-10 bg-opacity-0 bg-blue-200 w-[200px] h-[1.6rem] bottom-0 right-0"></div>
                : ""
                }
                {/* <div className="absolute z-10 bg-white w-full h-[28px] bottom-0 left-0 border-t pointer-events-none"></div> */}
                <iframe
                    // ref={iframeRef}
                    key={iframeSrc} // Force re-render by using iframeSrc as key
                    src={iframeSrc}
                    title="PDF Viewer"
                    className="w-full h-full rounded-b-md"
                    onContextMenu={(e) => {console.log(e)}}
                />
            </div>
        </div>
    );
};

export default DocumentDisplay;