import { Button } from "components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "components/ui/dialog";
import { useState, useContext } from "react";
import handler from "./OpCodeBuilderHandler";
import { Label } from "components/ui/label";
import InputValidation from "components/InputValidation";
import { Textarea } from "components/ui/textarea";
import { opCodeService } from "api/services/PROJECT-O/OpCodeBuilder.service";
import { Context } from "context/GlobalState";

export default function ViewOpCodeModal({ organizationId, subOrganizationId, workflow}) {
    const [isOpen, setIsOpen] = useState(false)
    const { addNewNotifcation } = useContext(Context);
    const [loadingDetails, setLoadingDetails] = useState(false)
    const [opCodeName, setOpCodeName] = useState("")
    const [opCodeDescription, setOpCodeDescription] = useState("")
    const [steps, setSteps] = useState([])

    const initializeModal = async () => {
        console.log("Retrieving workflow details for ", workflow);
        try {
            setLoadingDetails(true)
            setOpCodeName("")
            setOpCodeDescription("")


            const body = {
                org_id: organizationId,
                sub_org_id: subOrganizationId,
                unique_names: [workflow],
            };
            const res = await opCodeService.getOpCodesByUniqueNames(body);
            const details = res.data.OpCodes[workflow];
            console.log("Retrieved details:", details);
            setOpCodeName(details.opcode_id)
            setOpCodeDescription(details.description)

            if (details?.steps && details?.steps?.length > 0){
                setSteps(details.steps)
            }

        } catch (error) {
            console.error("Error getting workflow details:", error);
            addNewNotifcation("Failed to retrieve workflow details. Please try again.", "danger");
        } finally {
            setLoadingDetails(false)
        }
    };


    return (
        <Dialog open={isOpen} onOpenChange={(open) => {
            setIsOpen(open);
            if (open) {
                initializeModal(); // Initialize modal data when the dialog is opened
            }
        }}>
            <DialogTrigger asChild>
                <Button
                    variant="secondary"
                    size="sm"
                    className="w-1/2 text-xs"
                >
                    View
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-5xl">
                <DialogHeader className="select-none">
                    <DialogTitle>View Workflow</DialogTitle>
                    <DialogDescription>
                        You are viewing the workflow: {loadingDetails ?  "Loading Details..." : workflow}
                    </DialogDescription>
                </DialogHeader>
                {/* <div className="grid gap-4 py-4">
                    {workflow.steps.map((step, stepIndex) => (
                        <li
                            key={stepIndex}
                            className="line-clamp-1"
                        >
                            <span className="font-medium">
                                Step {stepIndex + 1}:
                            </span>{" "}
                            {step.params.unique_name}
                        </li>
                    ))}
                </div> */}
                <div className="mt-4 space-y-2">
                    <div>
                        <Label>Workflow ID</Label>
                        <InputValidation
                            value={opCodeName}
                            onChange={(e) => setOpCodeName(e.target.value)}
                            placeholder="Enter a unique name for this workflow"
                            validation={handler.validateOpCodeName}
                            readOnly
                            />
                    </div>
                    <div>
                        <Label>Workflow Description</Label>
                        <Textarea value={opCodeDescription} onChange={(e) => setOpCodeDescription(e.target.value)} placeholder="Enter a description for this workflow" />
                    </div>
                    <div>
                        {/* <Button onClick={handleGetSavedModels} className="flex gap-2" type="button" disabled={isModelLoading}>{isModelLoading ? "Loading Instructions..." : "Reload Instructions"}</Button> */}
                    </div>
                    <div className="border pl-2 py-2 rounded h-fit">
                        {steps.map((step, stepIndex) => (
                            <li
                                key={stepIndex}
                                className="w-full"
                            >
                                <span className="font-medium">
                                    Step {stepIndex + 1}:
                                </span>{" "}
                                {step.type === "LLM" ? step.params.unique_name : "RAG API"}
                                {/* <div className="">
                                    <span>Input:</span>
                                    <ul>\
                                        {extractKeys(step.params.query).map((keys) => {
                                            <li>{keys}</li>
                                        })}
                                        
                                    </ul>
                                    <span>Output:</span>
                                    <ul>\
                                        {extractKeys(step.params.query).map((keys) => {
                                            <li>{keys}</li>
                                        })}
                                        
                                    </ul>
                                </div> */}
                            </li>
                        ))}
                    </div>
                    {/* <div className="flex">
                        <Button onClick={handleAddGroup} className="w-full flex gap-2" type="button"><Plus className="h-4 w-4" />Add Step</Button>
                    </div> */}
                </div>
                <div className="flex gap-2 justify-end">
                    <DialogTrigger asChild>
                        <Button variant="outline">Close</Button>
                    </DialogTrigger>
                    {/* <Button variant="default" onClick={handleUpdate} disabled={isUpdating}>{isUpdating ? "Updating..." : "Update"}</Button> */}
                    
                </div>
            </DialogContent>
        </Dialog>
    );
}
