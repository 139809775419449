// CreateSubOrganizationDialog.js

import React, { useState, useEffect, useRef } from 'react';
import { httpRequest } from '../utils/httpsRequest';
import { DialogTitle, DialogDescription, DialogHeader, DialogFooter, DialogContent, Dialog } from '../components/ui/dialog';
import { Label } from "../components/ui/label";
import { Input } from "../components/ui/input";
import { Badge } from "../components/ui/badge";
import { ScrollArea } from "../components/ui/scroll-area";
import { MagnifyingGlassIcon, Cross1Icon, CheckIcon } from "@radix-ui/react-icons";
import { LoaderSpinner, AppLoader } from '../components/LoaderSpinner';
import { Button } from '../components/ui/button';
import { cn } from "../lib/utils";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select';
import { workflowService } from 'api';
import WorkflowMetadata from 'pages/Dashboard/SubOrganization/Settings/WorkflowMetadata';
import { convertArrayToObjectV2 } from 'utils/arrayUtils';
import { useParams } from 'react-router-dom';

function checkNameLengthWithSuffix(name, maxBytes = 38, suffix = '') {
    // Append the suffix to the name
    const fullName = name + suffix;
    
    // Use TextEncoder to calculate the byte length
    const encoder = new TextEncoder();
    const byteLength = encoder.encode(fullName).length;

    // Check if the byte length exceeds the maxBytes limit
    if (byteLength > maxBytes) {
        return true
    } else {
        return false
    }
}



const CreateSubOrganizationDialog = ({ show, setShow, addNewNotifcation }) => {
    const subOrgNameRef = useRef();
    const [btnLoading, setBtnLoading] = useState(false);
    const [members, setMembers] = useState([]);
    const [loadingMembers, setLoadingMembers] = useState(true);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [workflowTypes, setWorkflowTypes] = useState([])
    const [selectedWorkflow, setSelectedWorkflow] = useState(null)
    const [metadata, setMetadata] = useState([])
    const { oragID } = useParams()
    

    const getData = async () => {
        if (!show) return
        try {
            const orgUsers = await httpRequest.get(`/db/users/getUsers/${oragID}`)
            setMembers(orgUsers.data);

            const workflowTypesRes = await workflowService.getWorkflowTypes('regular')
            setWorkflowTypes(workflowTypesRes.data?.map((item) => ({id: item._id, ...item})))
        } catch (error) {
            console.log('error: ', error);
        } finally {
            setLoadingMembers(false);
        }
    }

    useEffect(() => {
        getData()
    }, [show, oragID]);

    const handleCreateSubOrganization = () => {
        const subOrgName = subOrgNameRef.current.value;
        
        if (subOrgName.length < 3) {
            return addNewNotifcation('Sub-Organization name must be at least 3 characters', 'warning');
        } else if (!selectedWorkflow) {
            return addNewNotifcation('Please select workflow', 'warning');
        } else if (checkNameLengthWithSuffix(subOrgName.trim().replaceAll(' ', '-'))) {
            return addNewNotifcation('Sub organization name is too long. Please shorten it to fit within 30 characters or less.', 'warning');
        }
        
        setBtnLoading(true);
        httpRequest.post('db/sub-organizations/insert-sub-organization', {
            name: subOrgName.trim(),
            organizationId: oragID,
            members: selectedMembers.map(member => member._id),
            workflowType: selectedWorkflow._id,
            workflowMetadata: convertArrayToObjectV2(metadata, 'key', 'value')
        })
        .then((res) => {
            addNewNotifcation('Sub-Organization created successfully', 'success');
            setTimeout(() => {
                window.location = `/organizations/${oragID}/sub`
            }, 1500)
        })
        .catch((error) => {
            console.log('error: ', error);
            if (error?.response?.status === 404) {
                addNewNotifcation('Organization not found', 'danger');
            } else if (error?.response?.status === 403) {
                addNewNotifcation('You are not authorized to create sub-organization', 'danger');
            } else if (error?.response?.status === 429) {
                addNewNotifcation('Yuo are not allowed to create more than 3 sub organizations', 'danger');
            } else {
                addNewNotifcation('Something went wrong', 'danger');
            }
        })
        .finally(() => {
            setBtnLoading(false);
        });
    };

    const handleSelectMember = (member) => {
        if (!selectedMembers.some(m => m._id === member._id)) {
            setSelectedMembers([...selectedMembers, member]);
        }
    };

    const handleRemoveMember = (member) => {
        setSelectedMembers(selectedMembers.filter((m) => m._id !== member._id));
    };

    const filteredMembers = members.filter((member) =>
        member.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        member.email.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Dialog open={show} onOpenChange={setShow}>
            <DialogContent className="w-full max-w-lg p-0 bg-white rounded-lg shadow-lg">
                <ScrollArea className='max-h-[650px] p-4'>
                    <DialogHeader>
                        <DialogTitle className="text-2xl font-semibold">Create Sub Organization</DialogTitle>
                        <DialogDescription className="text-md text-muted-foreground">Set up a new suborganization and add members.</DialogDescription>
                    </DialogHeader>
                    <div className="space-y-6 mt-4">
                        <div className="space-y-3">
                            <Label htmlFor="suborg-name" className="text-md">Name</Label>
                            <Input
                                ref={subOrgNameRef}
                                id="suborg-name"
                                placeholder="Enter suborganization name"
                                className="w-full p-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                            />
                        </div>
                        {!loadingMembers && (
                            <div className="my-2">
                                <Label htmlFor="workflow">Workflow</Label>
                                <Select onValueChange={(value) => setSelectedWorkflow(workflowTypes.find(item => item.id === value))} id="workflow" className="w-full">
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select a workflow" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {workflowTypes.map((item) => (
                                            <SelectItem key={item.id} value={item.id}>{item.name}</SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                                <div className='my-8 flex flex-col gap-2'>
                                    <Label>Workflow Metadata</Label>
                                    <span className='text-sm text-muted-foreground'> Workflow metadata helps the AI personalize replies in emails, chats, and more. </span>
                                    <WorkflowMetadata metadata={metadata} setMetadata={setMetadata} />
                                </div>

                            </div>
                        )}

                        <div className="flex">
                            <ScrollArea className="rounded-md min-h-0 max-h-[80px]">
                                <div className="flex flex-wrap gap-2">
                                    {selectedMembers.map((member) => (
                                        <Badge key={member._id} variant="secondary" className="p-2 text-sm">
                                            {member.name}
                                            <Cross1Icon className="ml-2 cursor-pointer" onClick={() => handleRemoveMember(member)} />
                                        </Badge>
                                    ))}
                                </div>
                            </ScrollArea>
                        </div>
                        <div className="relative space-y-3">
                            <Label htmlFor="member-search" className="text-md">Member Selection</Label>
                            <MagnifyingGlassIcon className="absolute top-7 left-3 h-6 w-6 text-muted-foreground" />
                            <Input
                                id="member-search"
                                placeholder="Search members..."
                                type="search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="w-full pl-12 pr-5 py-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                            />
                            <ScrollArea className="border-solid rounded-md h-56">
                                <div className="flex flex-col p-3 space-y-3">
                                    {loadingMembers ? <AppLoader size={60} /> : filteredMembers.length === 0 ? <div className="text-center text-muted-foreground">No members found</div> : filteredMembers.map((member) => (
                                        <div key={member._id} className="flex items-center space-x-3 cursor-pointer" onClick={() => handleSelectMember(member)}>
                                            <div>
                                                <div className="font-medium text-md">{member.name}</div>
                                                <div className="text-sm text-muted-foreground">{member.email}</div>
                                            </div>
                                            <CheckIcon className={cn("ml-auto h-6 w-6", selectedMembers.some(m => m._id === member._id) ? "opacity-100" : "opacity-0")} />
                                        </div>
                                    ))}
                                </div>
                            </ScrollArea>
                        </div>
                    </div>
                    <DialogFooter className="flex justify-end space-x-5 mt-6">
                        <Button variant="outline" onClick={() => setShow(false)} className="px-5 py-3 size-md border-solid shadow-none text-accent-foreground">Cancel</Button>
                        <Button onClick={handleCreateSubOrganization} className="px-5 py-3 text-md">
                            {btnLoading ? <LoaderSpinner /> : 'Create Sub Organization'}
                        </Button>
                    </DialogFooter>
                </ScrollArea>
            </DialogContent>
        </Dialog>
    );
};

export default CreateSubOrganizationDialog;