import React, { useContext, useEffect, useRef, useState } from 'react'
import { Send, Mic, XIcon } from 'lucide-react'
import { Textarea } from 'components/ui/textarea';
import { BsRecordCircle } from 'react-icons/bs';
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import { Context } from 'context/GlobalState';

const WebchatFooter = ({loading, emitMessage, socket, convoHistory}) => {
  const [userMessage, setUserMessage] = useState("");
  const [recording, setRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const recorder  = useRef(null)
  const {addNewNotifcation} = useContext(Context)

    useEffect(() => {
        let timer;
        if (recording) {
          timer = setInterval(() => {
            setRecordingTime((prevTime) => prevTime + 1);
          }, 1000);
        } else {
          clearInterval(timer);
        }
        return () => clearInterval(timer);
    }, [recording]);

    const handleSendMessage = async () => {
      if(loading) {
        loadingWarning()
        return ;
      }

      if (userMessage.trim()){
          emitMessage(userMessage)
          setUserMessage("");
      }
  };

    const handleVoiceRecording = async () => {
      if (recording) {
        stopRecording();
      } else {
        startRecording();
      }
    };

    const handleCancelRecording = async () => {
      stopRecording();
      socket.emit("cancel")
    }
  
    const startRecording = async () => {
      console.log("Recording started")
      if(loading) {
        loadingWarning()
        return ;
      }

      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const newRecorder = RecordRTC(stream, 
          { 
            type: 'audio',
            mimeType: 'audio/wav',
            timeSlice: 250, // Send audio chunks every 250ms (adjust based on latency)
            recorderType: StereoAudioRecorder, // Ensures uncompressed audio
            desiredSampRate: 16000, // Down-sample if the input device has a higher sample rate
            numberOfAudioChannels: 1, // Mono audio
            ondataavailable: (blob) => {
              // Send each audio chunk to the WebSocket server
              emitMessage(blob, true);
              // socket.emit("voice", blob, widgetId, convoHistory, conversation_id)
            }, 
          }
        );
        newRecorder.startRecording();
        recorder.current = newRecorder;
        setRecording(true);
        setRecordingTime(0);
      } catch (error) {
        console.error("Error accessing microphone:", error);
        addNewNotifcation("Could not access your microphone. Please check permissions.", "warning");
      }
    };
  
    const stopRecording = async () => {
      setRecording(false);
      if(recorder.current){
        recorder.current.stopRecording();
      }
      
      socket.emit("stop-voice", convoHistory)
    };

    const loadingWarning = () => {
      addNewNotifcation('Please wait untill the response comes.', 'warning');
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
          e.preventDefault();  
          handleSendMessage(); 
        }
    };

    

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes > 0 ? `${minutes}m ` : ""}${seconds}s`;
    };

return (
    <div className="p-3 bg-white border-t" data-no-drag>
        <div className="flex items-center space-x-2">
            <Textarea
            onChange={(e) => setUserMessage(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e)}
            value={userMessage}
            rows='1'
            type="text"
            placeholder="Type Something....."
            className="flex-grow bg-transparent min-h-1 text-gray-800 placeholder-gray-400 border-gray-300 resize-none"
            />
            <div className="flex items-center gap-2">
                <div className="cursor-pointer" onClick={handleVoiceRecording}>
                    {recording ? 
                        <BsRecordCircle size={21} className="text-[#b1092a] animate-pulse" /> : 
                        <Mic className="h-6 w-6 text-black hover:text-[#8c8c8c]"/>
                    }
                </div>
                {recording ? 
                    <div className="cursor-pointer mr-3" onClick={handleCancelRecording} data-no-drag>
                        <XIcon size={21} className="text-[#b1092a]" /> 
                    </div>: ''
                }
                <Send className="h-5 w-5 text-black cursor-pointer hover:text-[#8c8c8c]" onClick={handleSendMessage}/>
            </div>
        </div>
        {recording && (
        <div className="mt-3 w-full p-3 border rounded-lg shadow-md">
            <div className="flex items-center justify-between mb-2">
                <span className="text-xs font-semibold text-gray-500">Recording Time</span>
                <span className="text-sm font-semibold">{formatTime(recordingTime)}</span>
            </div>
            {/* <div className="border-t border-gray-300 pt-2">
                <span className="text-xs font-semibold text-gray-500">Transcript</span>
                <div className="mt-1 text-sm text-gray-800 break-words">{transcript || "..."}</div>
            </div> */}
        </div>
        )}
    </div>
  )
}

export default WebchatFooter