import { useContext } from "react"
import Collection from "./Collection"
import { SubOrgContext } from "context/SubOrganizationContext"
import { OrganizationContext } from "context/OrganizationContext"

export default function KnowledgeBuilder() {

    const { collections, actions } = useContext(SubOrgContext)
    const { selectedOrganization } = useContext(OrganizationContext)

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6">BeX Knowledge Builder</h1>
            <Collection collections={collections} isMilvusActivated={selectedOrganization.isMilvusActivated} actions={actions} />
        </div>
    )
}
