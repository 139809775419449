import { useState, useContext, useMemo } from "react";
import { Button } from "components/ui/button";
import { Label } from "components/ui/label";
import { Textarea } from "components/ui/textarea";
import { Context } from "context/GlobalState";
import { OpCodeBuilderContext } from "pages/PromptingAndRagTraining/context/OpCodeBuilderContext";
import OpCodeGrid from "./OpCodeGrid";
import { ScrollArea } from "components/ui/scroll-area";
import { opcodeWSService } from "api/services/PROJECT-O/WEBSOCKETS/OpCode.websocket.service";
import { Terminal } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Badge } from "components/ui/badge";
import Markdown from "react-markdown";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import "../aiLab.css";
import remarkGfm from "remark-gfm";
import CopyButton from "../CopyButton";

export default function OpCodeExecution({ organizationId, subOrganizationId, collections }) {
    const { 
        opCodes, setOpCodes,
        selectedOpCode, setSelectedOpCode,
        executeInput, setExecuteInput,
        executionResults, setExecutionResults,
        isExecuting, setIsExecuting,
        contextKey
    } = useContext(OpCodeBuilderContext)
    const { addNewNotifcation } = useContext(Context);


    const handleExecute = async () => {
        if (!selectedOpCode) {
            addNewNotifcation("Please select a workflow.", "warning");
            return;
        }
        if (!contextKey) {
            addNewNotifcation("Context Key missing.", "warning");
            return;
        }

        try {
            if (!selectedOpCode || executeInput.trim() === "") {
                addNewNotifcation("Please select an OpCode ID and give an input.", "warning");
                return;
            }
            setIsExecuting(true);
            setExecutionResults([]);
            const body = {
                "opcode_id": selectedOpCode,
                "org_id": organizationId,
                "sub_org_id": subOrganizationId,
                "context": {
                  [contextKey]: executeInput.trim()
                }
            }
            console.log("execute body:", body);

            const res = await opcodeWSService.executeOpCode3(body)
            // const res = await opCodeService.executeOpCode(body)
            console.log(res)
            console.log("Execution result", res)
            if (typeof res === "object" && res.execution_log) {
                setExecutionResults(res.execution_log);
                addNewNotifcation("OpCode executed successfully.", "success");
            } else {
                throw new Error(res)
            }

        } catch (e) {
            console.error("Error executing OpCode:", e);
            addNewNotifcation("Error when executing OpCode. Please try again.", "danger");
            setExecutionResults([]);
        } finally {
            setIsExecuting(false);
        }
    };

    // Memoized
    const RenderedResults = useMemo(() => {
        return executionResults.map((result, index) => (
            <Card className="w-full mx-auto mb-4 bg-slate-50">
                <CardHeader className="flex flex-row items-center gap-2 pb-2">
                    <CardTitle>Step #{index + 1}</CardTitle>
                </CardHeader>
                <CardContent className="space-y-4">
                    <div className="space-y-2">
                        <div className="flex items-center gap-2">
                        <Badge variant="secondary" className="h-6">{result.Action}</Badge>
                        {/* <span className="text-sm text-muted-foreground">Completed</span> */}
                        </div>
                        
                        <div className="">
                            <div className="space-y-1">
                                {Object.entries(result.Outputs).map(([key, value], index) => (
                                    <div className="">
                                        <strong className="text-gray-500">{key}:</strong>
                                        <div key={index} className="bg-white h-fit border select-text shadow rounded-[4px] text-xs">
                                            <ScrollArea className="max-h-[500px] p-2 break-words text-xs whitespace-pre-wrap">
                                            {result["Step Type"] === "Non-LLM" ?
                                                <p className="whitespace-pre-wrap text-xs">{value.trim()}</p>
                                                
                                                :

                                                <Markdown className="ai-lab-markdown" remarkPlugins={[remarkGfm]} 
                                                    components={{
                                                    code({ node, inline, className, children, ...props }) {
                                                        const match = /language-(\w+)/.exec(className || '');
                                                        const code = String(children).replace(/\n$/, '');
                                                        
                                                        inline = !className && !/\n/.test(code);
                                                        if (inline) {
                                                        // Inline Code Block
                                                        return (
                                                            <code
                                                            className="bg-gray-200 text-gray-800 rounded-[4px] px-1 text-xs font-mono"
                                                            style={{ whiteSpace: 'pre-wrap' }}
                                                            >
                                                            {children}
                                                            </code>
                                                        );
                                                        }
                                                
                                                        if (!inline && match) {
                                                        // Multi-Line Code Block with Language
                                                        return (
                                                            <div className="relative rounded-[12px]">
                                                            <span className="absolute top-2 left-2 bg-gray-600 text-white text-xs px-2 py-1 rounded select-none">
                                                                {match[1]}
                                                            </span>
                                                            <CopyButton code={code} className="text-gray-200" />
                                                            <SyntaxHighlighter
                                                                style={darcula}
                                                                language={match[1] !== "markdown" ? match[1] : ""}
                                                                PreTag="div"
                                                                {...props}
                                                                customStyle={{ paddingTop: '2.5rem' }}
                                                            >
                                                                {code}
                                                            </SyntaxHighlighter>
                                                            </div>
                                                        );
                                                        }
                                                
                                                        // Multi-Line Code Block Without Language
                                                        return (
                                                        <div
                                                            className="bg-[#2c2c2c] rounded-md p-2 pt-[1.5rem] text-xs text-gray-200 font-mono whitespace-pre-wrap relative"
                                                            style={{ overflowX: 'auto' }}
                                                        >
                                                            <CopyButton code={code} className="text-gray-200" />
                                                            {children}
                                                        </div>
                                                        );
                                                    },
                                                }}>
                                                    {result["Step Type"] === "Non-LLM - BeX Insights" 
                                                        ? `\`\`\`json\n${JSON.stringify(JSON.parse(value.trim()), null, 4)}\n\`\`\`` 
                                                        : value.trim()}
                                                    {/* {value.trim()} */}
                                                </Markdown>
                                            }

                                            </ScrollArea>
                                        </div>
                                    </div>
                                ))}
                                
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        ));
    }, [executionResults]);

    return (
        <div className="">
            <div className="space-y-2">

                <div className="">
                    <OpCodeGrid collections={collections} organizationId={organizationId} subOrganizationId={subOrganizationId} workflows={opCodes} setWorkflows={setOpCodes} setSelectedOpCode={setSelectedOpCode}/>
                </div>

                <div className="space-y-2 flex flex-col">
                    <Label>Selected Workflow: </Label>
                    <span className="select-text font-bold text-lg px-2 py-2 border rounded">{selectedOpCode ? selectedOpCode : "No workflow selected"}</span>
                </div>
                <div className="space-y-2">
                    <Label>Input</Label>
                    <Textarea value={executeInput} rows="6" onChange={(e) => setExecuteInput(e.target.value)} placeholder="Enter input for execution" />
                </div>
                <Button onClick={handleExecute} className="w-full" disabled={isExecuting}>{isExecuting ? "Executing OpCode..." : "Execute OpCode"}</Button>
            </div>

            <hr className="my-8"/>

            <div className="flex flex-row gap-2 items-center mx-2 my-4">
                <Terminal className="w-5 h-5 text-primary" />
                <Label className="text-xl">Execution Results</Label>
            </div>

            
            {RenderedResults}
        </div>
    );
}
