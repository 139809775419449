/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react';
import { httpRequest } from '../../../utils/httpsRequest';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '../../../components/ui/card';
import { Button } from '../../../components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogFooter,
    DialogTitle,
    DialogDescription,
} from '../../../components/ui/dialog';
import { Context } from '../../../context/GlobalState';
import { Label } from 'components/ui/label';
import { Input } from 'components/ui/input';
import { Textarea } from 'components/ui/textarea';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';
import { opCodeService } from 'api/services/PROJECT-O/OpCodeBuilder.service';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from 'components/ui/dropdown-menu';
import { MoreHorizontal } from 'lucide-react';
import MultiSelectComponent from 'components/ui/MultiSelect';
import { AssistantCombobox } from 'pages/Dashboard/EmailExtraction/OpcodeCard';

const Assistants = () => {
    const [assistants, setAssistants] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showAdd, setShowAdd] = useState(false);
    const [currentAssistant, setCurrentAssistant] = useState(null);

    const [opcodes, setOpcodes] = useState([]);

    const { isAdminAuth } = useContext(Context);


    const createAssistant = async (assistantId) => {
        const opcodeAssistantObj = {
            "org_id": 'demo',
            "sub_org_id": 'demo',
            "assistant_id": assistantId,
            "description": "..."
        }
        try {
            await opCodeService.createOpCodeAssistant2(opcodeAssistantObj)
        } catch (assistantError) {
            console.log('error creating assistant: ', assistantError);
        }
    }


    const getOpCodes = () => {

        opCodeService.getOpCodesByOrgSubOrg('demo', 'demo')
        .then((res) => {
            setOpcodes(res.data.OpCodes.map(item => ({value: item, label: item})))
        }).catch((error) => {
            console.log('error: ', error);
        })
    }

    useEffect(() => {
        httpRequest.get('/db/bex-assistants/get-assistants')
            .then((res) => {
                setAssistants(res.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log('error: ', error);
            })
            .finally(() => {
                setLoading(false);
            });

            // get opcodes
            getOpCodes()
    }, []);

    const handleAddAssistant = () => {
        httpRequest.post('/db/bex-assistants/add-assistant', {
            name: currentAssistant.name,
            title: currentAssistant.title,
            description: currentAssistant.description,
            channelType: currentAssistant.channelType,
            status: 'ACTIVE',
        }).then(async (res) => {
            console.log('resasd: ', res);
            setAssistants([...assistants, res.data]);
            // create assistant in project o redis
            await createAssistant(res.data._id)
            console.log('assiss: ', assistants);
            setShowAdd(false);
        }).catch((error) => {
            console.log('error: ', error);
        }).finally(() => {
            setLoading(false);
        }
        );
    };


    function truncateString(str, num) {
        if (!str) {
            return '';
        }
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + '...';
    }

    return (
        <div className="flex flex-col w-full">
            <div className="flex flex-row justify-between">
                <h1 className="text-2xl font-bold">Assistants</h1>
                {isAdminAuth && <Button onClick={() => { setCurrentAssistant(null); setShowAdd(true); }}>Add Assistant</Button>}
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 m-4 overflow-auto max-h-screen">
                {loading ? <h1>Loading...</h1> : assistants.map((assistant) => (
                    <Card key={assistant._id}>
                        <CardHeader>
                            <div className="flex w-full justify-between items-center gap-2">
                                <CardTitle>{assistant.name}</CardTitle>
                                <AssistantDropdownMenu opcodes={opcodes} assistant={assistant} assistants={assistants} setAssistants={setAssistants} />
                            </div>
                        </CardHeader>
                        <CardContent>
                            <CardDescription>{truncateString(assistant.description, 50)}</CardDescription>
                        </CardContent>
                        {/* <CardFooter className="flex justify-end gap-2">
                            <Button onClick={() => openEditDialog(assistant)}>Edit</Button>
                            <Button  onClick={() => openDeleteDialog(assistant)} variant="destructive">Delete</Button>
                        </CardFooter> */}
                    </Card>
                ))}
            </div>
    
            <Dialog open={showAdd} onOpenChange={setShowAdd}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Add Assistant</DialogTitle>
                        <DialogDescription>Fill in the details for the new assistant.</DialogDescription>
                    </DialogHeader>
                    <div className="space-y-4">
                        <div>
                            <Label htmlFor="assistant-name" className="block text-sm font-medium">Name</Label>
                            <Input
                                id="assistant-name"
                                type="text"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                onChange={(e) => setCurrentAssistant({ ...currentAssistant, name: e.target.value })}
                            />
                        </div>
                        <div>
                            <Label htmlFor="assistant-title" className="block text-sm font-medium">Title</Label>
                            <Input
                                id="assistant-title"
                                type="text"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                onChange={(e) => setCurrentAssistant({ ...currentAssistant, title: e.target.value })}
                            />
                        </div>
                        <div>
                            <Label htmlFor="channel-type" className="block text-sm font-medium">Channel Type</Label>
                            <Select onValueChange={(e) => setCurrentAssistant({ ...currentAssistant, channelType: e })} id="channel-type" defaultValue={'none'}>
                                <SelectTrigger className="w-full">
                                    <SelectValue placeholder="Select an assistant" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="none">None</SelectItem>
                                    <SelectItem value="email">Email</SelectItem>
                                    <SelectItem value="web-chat">Web Chat</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                        <div>
                            <Label htmlFor="assistant-description" className="block text-sm font-medium">Description</Label>
                            <Textarea
                                id="assistant-description"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                onChange={(e) => setCurrentAssistant({ ...currentAssistant, description: e.target.value })}
                            />
                        </div>
                    </div>
                    <DialogFooter>
                        <Button onClick={() => setShowAdd(false)} variant="outline">Cancel</Button>
                        <Button onClick={handleAddAssistant}>Add Assistant</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
    
 
        </div>
    );
    
}


const AssistantDropdownMenu = ({ assistant, assistants, setAssistants, opcodes }) => {

    const [showEdit, setShowEdit] = useState(false);
    const [showAddOpcode, setShowAddOpcode] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    const [currentAssistant, setCurrentAssistant] = useState(null);

    const openEditDialog = () => {
        setCurrentAssistant(assistant);
        setShowEdit(true);
    };

    const AddOpcode = () => {
        setCurrentAssistant(assistant);
        setShowAddOpcode(true);
    };

    const openDeleteDialog = () => {
        setCurrentAssistant(assistant);
        setShowDelete(true);
    };

    const handleEditAssistant = () => {
        httpRequest.put(`/db/bex-assistants/update-assistant/${currentAssistant._id}`, {
            name: currentAssistant.name,
            title: currentAssistant.title,
            description: currentAssistant.description,
            channelType: currentAssistant.channelType,
            status: currentAssistant.status,
        }).then((res) => {
            console.log('res: ', res);
            setAssistants(assistants.map((a) => a._id === currentAssistant._id ? currentAssistant : a));
            setShowEdit(false);
        }).catch((error) => {
            console.log('error: ', error);
        });
    };


    const handleDeleteAssistant = () => {
        console.log('currentAssistant: ', currentAssistant);
        httpRequest.delete(`/db/bex-assistants/delete-assistant/${currentAssistant._id}`)
            .then((res) => {
                setAssistants(assistants.filter(a => a._id !== currentAssistant._id));
                setShowDelete(false);
            }).catch((error) => {
                console.log('error: ', error);
            });
    };

    return (
        <div>
            <DropdownMenu modal={false}>
                <DropdownMenuTrigger asChild>
                    <Button aria-haspopup="true" size="icon" variant="ghost" className="border-none bg-transparent">
                        <MoreHorizontal className="h-4 w-4" />
                        <span className="sr-only">Toggle menu</span>
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                    <DropdownMenuItem onClick={openEditDialog}>Update</DropdownMenuItem>
                    <DropdownMenuItem onClick={AddOpcode}>Add opcode</DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem onClick={openDeleteDialog} className='text-red-500'> Delete </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>

            {/* update assistant dialog */}
            <Dialog open={showEdit} onOpenChange={setShowEdit}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Edit Assistant</DialogTitle>
                        <DialogDescription>Update the details of the assistant.</DialogDescription>
                    </DialogHeader>
                    <div className="space-y-4">
                        <div>
                            <Label htmlFor="assistant-name" className="block text-sm font-medium">Name</Label>
                            <Input
                                id="assistant-name"
                                type="text"
                                value={currentAssistant?.name || ''}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                onChange={(e) => setCurrentAssistant({ ...currentAssistant, name: e.target.value })}
                            />
                        </div>
                        <div>
                            <Label htmlFor="assistant-title" className="block text-sm font-medium">Title</Label>
                            <Input
                                id="assistant-title"
                                type="text"
                                value={currentAssistant?.title || ''}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                onChange={(e) => setCurrentAssistant({ ...currentAssistant, title: e.target.value })}
                            />
                        </div>
                        <div>
                            <Label htmlFor="channel-type" className="block text-sm font-medium">Channel Type</Label>
                            <Select onValueChange={(value) => setCurrentAssistant({ ...currentAssistant, status: value})} id="channel-type" value={currentAssistant?.channelType || 'none'}>
                                <SelectTrigger className="w-full">
                                    <SelectValue placeholder="Select an assistant" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="none">None</SelectItem>
                                    <SelectItem value="email">Email</SelectItem>
                                    <SelectItem value="web-chat">Web Chat</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                        <div>
                            <Label htmlFor="channel-type" className="block text-sm font-medium">Assistant status</Label>
                            <Select onValueChange={(value) => setCurrentAssistant({ ...currentAssistant, status: value })} id="channel-type" defaultValue={currentAssistant?.status || 'ACTIVE'}>
                                <SelectTrigger className="w-full">
                                    <SelectValue placeholder="Select assistant status" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="ACTIVE">Active</SelectItem>
                                    <SelectItem value="DISABLED">Disabled</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                        <div>
                            <Label htmlFor="assistant-description" className="block text-sm font-medium">Description</Label>
                            <Textarea
                                id="assistant-description"
                                value={currentAssistant?.description || ''}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                onChange={(e) => setCurrentAssistant({ ...currentAssistant, description: e.target.value })}
                            />
                        </div>
                    </div>
                    <DialogFooter>
                        <Button onClick={() => setShowEdit(false)} variant="outline">Cancel</Button>
                        <Button onClick={handleEditAssistant}>Update Assistant</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>

            {/* add assistant opcode dialog */}
            <OpcodeInstructionDialog oragID={'demo'} subOragID={'demo'} assistantId={assistant._id} opcodes={opcodes} isOpen={showAddOpcode} setIsOpen={setShowAddOpcode} />

            {/* delete assistant dialog */}
            <Dialog open={showDelete} onOpenChange={setShowDelete}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Delete Assistant</DialogTitle>
                        <DialogDescription>Are you sure you want to delete this assistant?</DialogDescription>
                    </DialogHeader>
                    <DialogFooter>
                        <Button onClick={() => setShowDelete(false)} variant="outline">Cancel</Button>
                        <Button onClick={handleDeleteAssistant} variant="destructive">Delete Assistant</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>

        </div>

    )
}

const OpcodeInstructionDialog = ({ isOpen, setIsOpen, opcodes, oragID, subOragID, assistantId }) => {

        
    const [selectedOpcodes, setSelectedOpcodes] = useState([]);
    const [selectDefaultOpcode, setSelectDefaultOpcode] = useState(null);

    const [btnLoading, setBtnLoading] = useState(false);

    const { addNewNotifcation } = useContext(Context)

    const opcodeSelect = (items) => {
        setSelectedOpcodes(items)
    }

    const handleSelectDefaultOpcode = (item) => {
        setSelectDefaultOpcode(item)
    }


    const handleSave = () => {
        
        setBtnLoading(true)
        const opCodeObj = {
            "org_id": oragID,
            "sub_org_id": subOragID,
            "assistant_name": assistantId,
            "opcodes_list": selectedOpcodes,
            "default_opcode": selectDefaultOpcode.value
        }
        console.log('opCodeObj: ', opCodeObj);
        

        opCodeService.addOpCodeToAssistant2(opCodeObj)
        .then(() => {
            addNewNotifcation('Opcode added successfully to this assistant', 'success')

            const savedOpcodesArr = selectedOpcodes.map(item => ({name: item, default: 'false'}))
            savedOpcodesArr.push({name: selectDefaultOpcode.value, default: 'true'})

            setIsOpen(false)
        }).catch((error) => {
            console.log('error: ', error);
            addNewNotifcation('Something went wrong', 'danger')
        }).finally(() => {
            setBtnLoading(false)
        })

    };


    const disableSave = !selectDefaultOpcode || !selectedOpcodes.length > 0


    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogContent className="sm:max-w-[825px]">
                <DialogHeader>
                    <DialogTitle>Manage Assistant OpCodes</DialogTitle>
                </DialogHeader>
                <div className="space-y-4">
                    <div className='flex flex-col gap-2'>
                        <Label> Opcodes </Label>
                        <MultiSelectComponent items={opcodes} onItemsSelect={opcodeSelect} selectedItems={selectedOpcodes} />
                    </div>

                    <div className='flex flex-col gap-2'>
                        <Label> Default opcode </Label>
                        <AssistantCombobox items={opcodes} setter={handleSelectDefaultOpcode} selectedValue={selectDefaultOpcode} />
                    </div>
                </div>
                <DialogFooter>
                    <Button variant="outline" onClick={() => setIsOpen(false)}>Cancel</Button>
                    <Button onClick={handleSave} disabled={btnLoading || disableSave} className="bg-black text-white hover:bg-gray-800">Save</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default Assistants;