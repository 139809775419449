/* eslint-disable max-lines */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, useContext } from "react";
import {
  DialogTitle,
  DialogDescription,
  DialogHeader,
  DialogFooter,
  DialogContent,
  Dialog,
} from "components/ui/dialog";
import { Label } from "components/ui/label";
import { Input } from "components/ui/input";
import { ScrollArea } from "components/ui/scroll-area";
import { LoaderSpinner } from "components/LoaderSpinner";
import { Button } from "components/ui/button";
import { Context } from "context/GlobalState";
import { useParams } from "react-router-dom";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "components/ui/tooltip";
import { redisCollectionService } from "api";

const UpdateCollectionDialog = ({ collections, actions, isMilvusActivated, show, setShow, collectionId }) => {

    const SEARCH_TYPES = ['jina', 'openAI']
    const VECTOR_STORE_TYPES = ['MongoDB', 'Milvus']
    const DIMENSIONS_TYPES = [1024, 128]

    const [btnLoading, setBtnLoading] = useState(false);
    const [embeddingModel, setEmbeddingModel] = useState("jina");

    const [name, setName] = useState("");
    const [dimensions, setDimensions] = useState(128);
    const [chunkingStrategy, setChunkingStrategy] = useState("");
    const [vectorStoreType, setVectorStoreType] = useState("MongoDB");

    const { addNewNotifcation } = useContext(Context)
    const { oragID, subOragID } = useParams()

    useEffect(() => {
        const collection = collections.find(item => item._id === collectionId)

        setName(collection.name)
        setEmbeddingModel(collection.embeddingModel || 'jina')
        setDimensions(collection.dimensions || 128)
        setChunkingStrategy(collection.chunkingStrategy)
        setVectorStoreType(collection.vectorStoreType || "MongoDB")
    }, [show]);

    const handleUpdateCollection = () => {
        setBtnLoading(true);

        const updatedCollectionObj = {
            organizationId: oragID,
            subOrganizationId: subOragID,
            collectionId: collectionId,
            dimensions: dimensions,
            name: name,
            chunkingStrategy: chunkingStrategy,
            vectorStoreType: vectorStoreType,
            embeddingModel: embeddingModel,
        }
        redisCollectionService.updateCollection(updatedCollectionObj)
        .then((res) => {
            setShow(false);
            actions({type: 'SET_COLLECTIONS', payload: (collections => {
                const updatedCollection = collections.map(item => {
                    if (item._id === collectionId) {
                        return {...item, ...res.data}
                    } else {
                        return item
                    }
                })

                return updatedCollection
            })})

            addNewNotifcation("Sub-Organization updated successfully", "success");
        })
        .catch((error) => {
            console.log("error: ", error);
            if (error?.response?.status === 404) {
                addNewNotifcation("Collection not found", "danger");
            } else {
                addNewNotifcation("Something went wrong", "danger");
            }
        })
        .finally(() => {
            setBtnLoading(false);
        });
    };

    const checkStoreTypeDisabled = (item) => {
        if (item === 'MongoDB') return false
        
        if (!isMilvusActivated) return true
    }

    return (
        <Dialog open={show} onOpenChange={setShow}>
            <DialogContent className="w-full max-w-lg p-0 bg-white rounded-lg shadow-lg">
                <ScrollArea className='max-h-[650px] p-4 overflow-y-scroll'>
                    <DialogHeader>
                        <DialogTitle className="text-2xl font-semibold">
                            Manage Collection
                        </DialogTitle>
                        <DialogDescription className="text-md text-muted-foreground">
                            Manage collection configuration
                        </DialogDescription>
                    </DialogHeader>
                
                    <div className="space-y-8 pt-4">
                        <div className="space-y-3">
                            <div>
                                <Label htmlFor='collection-name'> Name </Label>
                                <Input type='text' id='collection-name' value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div>
                                <Label htmlFor='dimensions'> Dimensions </Label>
                                <Select onValueChange={(value) => setDimensions(value)} id="dimensions" defaultValue={dimensions}>
                                    <SelectTrigger className="w-full capitalize">
                                        <SelectValue className="capitalize" placeholder="Select collection dimensions" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {DIMENSIONS_TYPES.map(item => (
                                            <SelectItem className='capitalize' key={item} value={item}>{item} </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>
                            <div>
                                <Label htmlFor='chunking-strategy'> Chunking Strategy </Label>
                                <Input id='chunking-strategy' value={chunkingStrategy} onChange={(e) => setChunkingStrategy(e.target.value)} />
                            </div>
                            <div>
                                <Label htmlFor='vector-store-type'> Vector store type </Label>
                                <Select onValueChange={(value) => setVectorStoreType(value)} id="vector-store-type" defaultValue={vectorStoreType}>
                                    <SelectTrigger className="w-full capitalize">
                                        <SelectValue className="capitalize" placeholder="Select vector store type" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {VECTOR_STORE_TYPES.map(item => (
                                            <TooltipProvider key={item}>
                                            <Tooltip className='z-[1000]'>
                                                <TooltipTrigger asChild>
                                                    <div>
                                                        <SelectItem disabled={checkStoreTypeDisabled(item)} className='capitalize'  value={item}>{item} </SelectItem>
                                                    </div>
                                                </TooltipTrigger>
                                                {checkStoreTypeDisabled(item) && (
                                                    <TooltipContent className="bg-gray-900 text-white w-fit z-[1000]">
                                                        <p>Milvus is not activated yet</p>
                                                    </TooltipContent>
                                                )}
                                            </Tooltip>
                                        </TooltipProvider>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>
                            <div>
                                <Label htmlFor='embedding-model'> Embedding Model </Label>
                                <Select onValueChange={(value) => setEmbeddingModel(value)} id="embedding-model" defaultValue={embeddingModel}>
                                    <SelectTrigger className="w-full capitalize">
                                        <SelectValue className="capitalize" placeholder="Select vector search type" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {SEARCH_TYPES.map(item => (
                                            <SelectItem className='capitalize' key={item} value={item}>{item} </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                    </div>

                    <DialogFooter className="flex justify-end space-x-5 mt-6">
                        <Button
                            variant="outline"
                            onClick={() => setShow(false)}
                            className="px-5 py-3 size-md border-solid shadow-none text-accent-foreground"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleUpdateCollection}
                            className="px-5 py-3 text-md"
                        >
                            {btnLoading ? <LoaderSpinner /> : "Update Collection"}
                        </Button>
                    </DialogFooter>
                </ScrollArea>
            </DialogContent>
        </Dialog>
    );
};

export default UpdateCollectionDialog;
