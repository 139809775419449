import React, { useEffect, useRef, useState } from 'react'
import WebchatScreen from './WebchatScreen';
import './web-chat.css'

const WebchatWidget = ({chatSettings, emailUpdateObject, modalRef}) => {
    const [position, setPosition] = useState({ right: 16 });
    const [modalWidth, setModalWidth] = useState(modalRef?.current?.getBoundingClientRect()?.width || 1100);
    const [isDragging, setisDragging] = useState(false)
    const [isOverLeft, setIsOverLeft] = useState(false); 
    const dragging = useRef(null);
    const draggingOver = useRef(null); 
    const {chatWidth} = chatSettings

    useEffect(() => {
        const handleResize = () => {
            const newWidth = modalRef?.current?.getBoundingClientRect()?.width || 1100;
            setModalWidth(newWidth);
            endDrag('', newWidth)
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);  

    const startDrag = (e) => {
        if (e.target.closest("[data-no-drag]")) return; 
        
        e.preventDefault();
        setisDragging(true)
        dragging.current = { startX: e.clientX, initialRight: position.right };
        document.addEventListener("mousemove", onDrag);
        document.addEventListener("mouseup", endDrag);
    };

    const onDrag = (e) => {
        if (!dragging.current) return;

        const deltaX = dragging.current.startX - e.clientX;
        const newRight = dragging.current.initialRight + deltaX;

        setPosition({
          right: Math.min(Math.max(0, newRight), modalWidth - Number(chatWidth)),
        });

        if (e.clientX < modalWidth  / 2) {
            draggingOver.current='left'
            setIsOverLeft(true); 
        } else {
            draggingOver.current= 'right'
            setIsOverLeft(false); 
        }
    };

    const endDrag = (e, newWidth) => {
        setisDragging(false)
        document.removeEventListener("mousemove", onDrag);
        document.removeEventListener("mouseup", endDrag);
        const newPostion = (newWidth || modalWidth) - (Number(chatWidth)) - 16 

        if (draggingOver.current === 'left') {
            setPosition({ right: newPostion });
        } else if (draggingOver.current === 'right') {
            setPosition({ right: 16 });
        } else {
            isOverLeft? setPosition({ right: newPostion }) 
            : setPosition({ right: 16 })
        }
    };

return (
    <WebchatScreen
    chatSettings={chatSettings}
    emailUpdateObject={emailUpdateObject}
    dragObj={{startDrag, isDragging, position, isOverLeft}}
    />
)
}

export default WebchatWidget