/* eslint-disable max-lines */
import React, { useEffect, useRef, useState } from "react";
import { Persona, Scene } from "@soulmachines/smwebsdk";
import { Minimize2, MessageCircle } from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import {
  Card,
  CardFooter,
  CardContent,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import { ScrollArea } from "components/ui/scroll-area";
import { Mic, MicOff, Loader2 } from "lucide-react";

function NoConnectionCard() {
  return (
      <Card className="w-full max-w-md mx-auto mt-8">
          <CardHeader>
              <CardTitle className="flex items-center justify-center">
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Connecting to Avatar
              </CardTitle>
          </CardHeader>
          <CardContent>
              <p className="text-center">
                  Please wait while we establish a connection to the avatar.
              </p>
          </CardContent>
      </Card>
  );
}

const AvatarConversation = ({ organizationId, subOrganizationId, assistantId, opCode }) => {
  const videoEl = useRef(null);
  const sceneRef = useRef(null);
  const personaRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isConnected, setIsConnected] = useState(false);
  const [isMicrophoneActive, setIsMicrophoneActive] = useState(false);
  const [isCardVisible, setIsCardVisible] = useState(false); 
  
  const apiKey = "eyJzb3VsSWQiOiJkZG5hLWNvb2xyaW90c2NhMTQtLWJleGZlbyIsImF1dGhTZXJ2ZXIiOiJodHRwczovL2RoLnNvdWxtYWNoaW5lcy5jbG91ZC9hcGkvand0IiwiYXV0aFRva2VuIjoiYXBpa2V5X3YxXzQyMjc3NWQyLWRhYTQtNGQ0Yi04Y2JlLTQ0YjZiMWZhMmFiNCJ9";

  useEffect(() => {
    const initializeScene = () => {
      if (!videoEl.current) return;
    
      const scene = new Scene({
        apiKey,
        videoElement: videoEl.current,
        requestedMediaDevices: { microphone: true },
        requiredMediaDevices: { microphone: true },
      });
    
      sceneRef.current = scene;
    
      scene.connect()
        .then(handleConnectionSuccess)
        .catch(handleConnectionError);
    
      scene.setMediaDeviceActive({ microphone: false })
        .then(() => {
          console.log("Microphone disabled on initialization.");
          setIsMicrophoneActive(false);
        })
        .catch(error => console.error("Error disabling microphone on initialization:", error));
    };
    
    
    const handleConnectionSuccess = (sessionId) => {
      console.info("Connection successful! Session ID:", sessionId);
      setIsConnected(true);
    
      sceneRef.current.startVideo()
        .then(videoState => console.info("Video started:", videoState))
        .catch(error => console.warn("Video start failed:", error));
    
      sceneRef.current.setMediaDeviceActive({ microphone: false })
        .then(() => {
          console.log("Microphone initialized as off after connection.");
          setIsMicrophoneActive(false);
        })
        .catch(error => console.error("Error setting initial microphone state:", error));
    
      sceneRef.current.onStateEvent.addListener(handlePersonaSpeech);
      sceneRef.current.onRecognizeResultsEvent.addListener(handleUserSpeech);
    };
    

    const handleConnectionError = (error) => {
      setIsConnected(false);
      const errorMessages = {
        noUserMedia: "User blocked device access or devices unavailable.",
        noScene: "Server connection failed.",
        serverConnectionFailed: "Server connection failed."
      };
      console.warn(errorMessages[error.name] || "Unhandled error:", error);
    };

    let userSpeechEndTime = null;
  
    const handleUserSpeech = (scene, status, errorMessage, results) => {
      const userSpeech = results[0]?.alternatives[0]?.transcript;
      if (results[0]?.final && userSpeech) {
        userSpeechEndTime = Date.now();
        addMessage("user", userSpeech);
      }
    };

    const handlePersonaSpeech = (scene, event) => {
      const personaSpeech = event.persona?.["1"]?.currentSpeech;
      if (event.persona?.["1"]?.speechState === "speaking" && personaSpeech) {
        if (messageSentTime) {
          const inferencingTimeInSeconds = ((Date.now() - messageSentTime) / 1000).toFixed(2); // Calculate time difference
          console.log(`Inferencing Time (Send Button to Avatar Response): ${inferencingTimeInSeconds} seconds`);
          messageSentTime = null;
        }
        addMessage("avatar", personaSpeech);
      }
    };

    initializeScene();

    return () => {
      if (sceneRef.current?.isConnected?.()) {
        sceneRef.current.disconnect?.()
          // .then(() => {
          //   console.log("Disconnected successfully.");
          //   setIsConnected(false);
          //   sceneRef.current = null;
          // })
          // .catch(error => console.error("Disconnect error:", error));
      }
    };
  }, []);

  const addMessage = (source, text) => {
    setMessages(prevMessages => [...prevMessages, { source, text }]);
  };

  let messageSentTime = null; 
  const handleSendMessage = () => {
    const trimmedMessage = inputMessage.trim();
    if (!trimmedMessage) return;
  
    if (!personaRef.current) {
      personaRef.current = new Persona(sceneRef.current, "1");
    }
  
    messageSentTime = Date.now(); 
    console.log("Send button clicked at:", new Date(messageSentTime).toISOString());
  
    personaRef.current?.conversationSend(trimmedMessage, {
      organizationId,
      subOrganizationId,
      assistantId,
      opCode,
    });
  
    addMessage("user", trimmedMessage);
    setInputMessage("");
  };
  

  const toggleMicrophone = () => {
    const scene = sceneRef.current;
    if (scene) {
      const newState = !isMicrophoneActive; 
      scene.setMediaDeviceActive({ microphone: newState })
        .then(() => {
          setIsMicrophoneActive(newState);
          console.log(`Microphone ${newState ? "activated" : "deactivated"}`);
        })
        .catch(error => console.error("Error toggling microphone:", error));
    }
  };
  
  return (
      <div className="relative h-full w-full flex overflow-hidden">
          {/* Avatar Section */}
          <div
            className={`relative ${isCardVisible ? "w-full md:w-[70%]" : "w-full"} h-full flex-shrink-0`}
          >
              <video
                ref={videoEl}
                id="sm-video"
                className="absolute top-0 left-0 w-full h-full object-cover"
              />
              {!isConnected && <NoConnectionCard />}
          </div>
  
          {/* Chat Section */}
          {isConnected && isCardVisible && (
          <div className="relative w-full md:w-[30%] bg-white shadow-lg flex-shrink-0 h-full">
              <Card className="h-full flex flex-col">
                  <CardHeader className="flex flex-row items-center justify-between">
                      <CardTitle>Conversation with Avatar</CardTitle>
                      <div className="flex space-x-2">
                          <Button
                              variant="outline"
                              size="icon"
                              onClick={toggleMicrophone}
                              aria-label={isMicrophoneActive ? "Mute microphone" : "Unmute microphone"}
                            >
                              {isMicrophoneActive ? <Mic className="h-4 w-4" /> : <MicOff className="h-4 w-4" />}
                          </Button>
  
                          <Button
                            variant="outline"
                            size="icon"
                            onClick={() => setIsCardVisible(false)}
                            aria-label="Hide card"
                          >
                              <Minimize2 className="h-4 w-4" />
                          </Button>
                      </div>
                  </CardHeader>
  
                  {/* Chat Content */}
                  <CardContent className="flex-grow h-[250px] overflow-y-auto px-4 py-2">
                      <ScrollArea className="h-full">
                          {messages.map((message, index) => (
                              <div
                                  key={index}
                                  className={`flex ${
                                      message.source === "user" ? "justify-end" : "justify-start"
                                  } mb-4`}
                              >
                                  <div
                                      className={`flex items-start ${
                                          message.source === "user" ? "flex-row-reverse" : ""
                                      }`}
                                  >
                                      <Avatar className="w-8 h-8">
                                          <AvatarImage
                                              src={
                                                  message.source === "user"
                                                      ? "/placeholder.svg?height=32&width=32"
                                                      : "/placeholder.svg?height=32&width=32"
                                              }
                                          />
                                          <AvatarFallback>
                                              {message.source === "user" ? "U" : "A"}
                                          </AvatarFallback>
                                      </Avatar>
                                      <div
                                          className={`mx-2 p-3 rounded-lg ${
                                              message.source === "user"
                                                  ? "bg-primary text-primary-foreground"
                                                  : "bg-muted"
                                          }`}
                                      >
                                          {message.text}
                                      </div>
                                  </div>
                              </div>
                          ))}
                      </ScrollArea>
                  </CardContent>

  
                  {/* Chat Input */}
                  <CardFooter className="p-4">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSendMessage();
                        }}
                        className="flex w-full items-center"
                      >
                          <div className="flex w-full items-center">
                              <Input
                                placeholder="Type your message..."
                                value={inputMessage}
                                onChange={(e) => setInputMessage(e.target.value)}
                                className="bg-white flex-grow rounded-l-md"
                              />
                              <Button type="submit" className="rounded-r-md">
                                  Send
                              </Button>
                          </div>
                      </form>
                  </CardFooter>
              </Card>
          </div>
      )}
  
          {/* Show Chat Button and Microphone Button */}
          {isConnected && !isCardVisible && (
          <div className="absolute top-0 right-0 flex items-center space-x-3 p-2">
              <Button
                variant="outline"
                size="icon"
                onClick={toggleMicrophone}
                aria-label={isMicrophoneActive ? "Mute microphone" : "Unmute microphone"}
              >
                  {isMicrophoneActive ? <Mic className="h-6 w-6" /> : <MicOff className="h-6 w-6" />}
              </Button>
  
              <Button
                className="bg-black text-white p-3 rounded-lg shadow-md hover:bg-gray-800 transition-colors"
                onClick={() => setIsCardVisible(true)}
                aria-label="Show Chat"
              >
                  <span>Show Chat Conversation</span>
                  <MessageCircle className="h-4 w-4 ml-2" />
              </Button>
          </div>
        )}
      </div>
  );
  
};


export default  AvatarConversation;