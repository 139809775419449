import { bexHttpClient } from '../../clients';


/**
 * Service class for performing project operations.
 */
class RedisCollectionService {

    /**
     * Fetches the sub organization projects.
     * @returns {Promise<Object>} The response from the server returns sub organization subscription.
     */
    async listCollections(organizationId, subOrganizationId) {
        const response = await bexHttpClient.get(`/redis/collections/list-collections?organizationId=${organizationId}&subOrganizationId=${subOrganizationId}`)
        return response;
    }

    /**
     * add new collection.
     * @param {Object} data - The new collection data including name, organizationId, subOrganizationId and projectId.
     * @returns {Promise<Object>} The response from the server returns new collection document from mongoDB.
     */
    async addCollection(data) {
        const response = await bexHttpClient.post(`/redis/collections/add-collection`, data);
        return response;
    }

    /**
     * update collection.
     * @param {Object} data - The updated collection data including organizationId, subOrganizationId, projectId, collectionId, name and assistants.
     * @returns {Promise<Object>} The response from the server returns updated collection document from mongoDB.
     */
    async updateCollection(data) {
        const response = await bexHttpClient.put(`/redis/collections/update-collection`, data);
        return response;
    }

    /**
     * delete collection.
     * @param {Object} data - Delete collection data including collectionId, organizationId, subOrganizationId and projectId.
     * @returns {Promise<Object>} The response from the server returns updated project document.
     */
    async deleteCollection(data) {
        const response = await bexHttpClient.delete(`/redis/collections/delete-collection`, {
            data: data
        });
        return response;
    }

}

const redisCollectionService = new RedisCollectionService()
export { redisCollectionService }
