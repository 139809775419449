/* eslint-disable max-lines */
import { useContext, useState, useEffect } from "react";
import { Button } from "components/ui/button";
import { Textarea } from "components/ui/textarea";
import InputGroup from "./InputGroup";
import { Plus } from "lucide-react";
import { Context } from "context/GlobalState";
import { Label } from "components/ui/label";
import InputValidation from "components/InputValidation";
import handler from "./OpCodeBuilderHandler";
import { OpCodeBuilderContext } from "pages/PromptingAndRagTraining/context/OpCodeBuilderContext";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';
import { OrganizationContext } from 'context/OrganizationContext';
import { SubOrgContext } from 'context/SubOrganizationContext';
import { opcodeWSService } from "api/services/PROJECT-O/WEBSOCKETS/OpCode.websocket.service";

const STEP_TYPES = [
    {id: 1, name: "LLM"},
    {id: 3, name: "Non-LLM - RAG API"},
    {id: 2, name: "Non-LLM - BeX Insights"}
]

function extractKeys(str) {
    const regex = /output\['(\w+)'\]/g;
    let match;
    const keys = [];

    while ((match = regex.exec(str)) !== null) {
        keys.push(match[1]);
    }

    return keys;
}

export default function OpCodeUpdateForm({ collections, workflowDetails = {}, inputGroups, setInputGroups, opCodeName, setOpCodeName, opCodeDescription, setOpCodeDescription, defaultOpCodeName='', defaultOpCodeDescription='', defaultInputGroups=[] }) {
    
    const [stepType, setStepType] = useState(STEP_TYPES[0].name);
    // const [opCodeName, setOpCodeName] = useState(defaultOpCodeName);
    // const [opCodeDescription, setOpCodeDescription] = useState(defaultOpCodeDescription);
    // const [inputGroups, setInputGroups] = useState(defaultInputGroups);
    const [isCreating, setIsCreating] = useState(false);
    
    // context
    const { addNewNotifcation } = useContext(Context);
    const { setOpCodes,validateSteps} = useContext(OpCodeBuilderContext);
    const { selectedOrganization } = useContext(OrganizationContext);
    const { selectedSubOrganization } = useContext(SubOrgContext);

    const handleAddGroup = () => {
        // const newStepId = `step_${inputGroups.length + 1}`;
        const newInputGroups = [...inputGroups];
    
        if (stepType === "LLM") {
            newInputGroups.push({
                step_id: "",
                step_type: "LLM",
                unique_name: "",
                model_type: "",
                dynamic_inputs: newInputGroups.length < 1 ? ["real_time_data"] : [],  // This will hold any dynamic inputs to be connected
                output_var: "",
                next_step: null, // Will be assigned later when connecting steps
                accumulate_output: true,
                is_first_step: false 
            });
        } else if (stepType === "Non-LLM - BeX Insights") {
            newInputGroups.push({
                step_id: "",
                step_type: "Non-LLM - BeX Insights",
                registry_type: "function",
                registry_key: "",
                fixed_inputs: {},
                dynamic_inputs: newInputGroups.length < 1 ? ["real_time_data"] : [],
                output_var: "",
                next_step: null, // Will be assigned later when connecting steps
                accumulate_output: true
            });
        } else if (stepType === "Non-LLM - RAG API") {
            newInputGroups.push({
                step_id: "",
                step_type: "Non-LLM",
                registry_type: "api",
                registry_key: "",
                fixed_inputs: {
                    "organization_id": selectedOrganization._id,
                    "sub_organization_id": selectedSubOrganization._id,
                    "collection_id": "",
                    "use_rerank": true,
                    "top_k": 10,
                    "top_n": 3,
                    "offset": 0,
                    "filters": {}
                },
                dynamic_inputs: {
                    "query_text": newInputGroups.length < 1 ? "real_time_data" : ""
                },
                output_var: "",
                next_step: null, // Will be assigned later when connecting steps
                accumulate_output: true
            });
        } 
    
        setInputGroups(newInputGroups);
    };
    
    

    const handleRemoveGroup = (index) => {
        const newInputGroups = inputGroups.filter((_, i) => i !== index);
        setInputGroups(newInputGroups);
    };

    useEffect(() => {
        // Ensure the first step has the correct input variable
        if (inputGroups.length > 0) {
            const firstStep = inputGroups[0];
            const updatedInputGroups = [...inputGroups];
    
            // Check for LLM and Non-LLM cases and set "real_time_data" if necessary
            if (firstStep.step_type === "LLM") {
                if (!firstStep.dynamic_inputs || firstStep.dynamic_inputs[0] !== "real_time_data") {
                    // console.log("Updating first step (LLM) query_text to real_time_data");
                    updatedInputGroups[0] = {
                        ...firstStep,
                        dynamic_inputs: ["real_time_data"],
                    };
                    setInputGroups(updatedInputGroups);
                }
            } else if (firstStep.step_type === "Non-LLM") {
                if (
                    !firstStep.dynamic_inputs?.query_text ||
                    firstStep.dynamic_inputs.query_text !== "real_time_data"
                ) {
                    // console.log("Updating first step (Non-LLM) query_text to real_time_data");
                    updatedInputGroups[0] = {
                        ...firstStep,
                        dynamic_inputs: {
                            ...firstStep.dynamic_inputs,
                            query_text: "real_time_data",
                        },
                    };
                    setInputGroups(updatedInputGroups);
                }
            } else if (firstStep.step_type === "Non-LLM - BeX Insights") {
                if (!firstStep.dynamic_inputs || firstStep.dynamic_inputs[0] !== "real_time_data") {
                    // console.log("Updating first step (LLM) query_text to real_time_data");
                    updatedInputGroups[0] = {
                        ...firstStep,
                        dynamic_inputs: ["real_time_data"],
                    };
                    setInputGroups(updatedInputGroups);
                }
            }
        }
    }, [inputGroups]);


    useEffect(() => {
        if (workflowDetails) {
            console.log(workflowDetails)
            setOpCodeName(workflowDetails.opcode_id);
            setOpCodeDescription(workflowDetails.description);
            const updatedInputGroups = workflowDetails.steps.map(step => {

                if (step.type === "LLM"){
                    return {
                        step_id: "",
                        step_type: step.type,
                        unique_name: step.params.unique_name,
                        model_type: step.params.model_type,
                        dynamic_inputs: extractKeys(step.params.query),  // This will hold any dynamic inputs to be connected
                        output_var: step.output.result,
                        next_step: null, // Will be assigned later when connecting steps
                        accumulate_output: true,
                        is_first_step: false 
                    }
                }

                if (step.type === "Non-LLM"){
                    return {
                        step_id: "",
                        step_type: step.type,
                        registry_type: "api",
                        registry_key: "RAG_API",
                        fixed_inputs: {
                            "organization_id": step.params.api_details.payload.organization_id,
                            "sub_organization_id": step.params.api_details.payload.sub_organization_id,
                            "collection_id": step.params.api_details.payload.collection_id,
                            "use_rerank": step.params.api_details.payload.use_rerank,
                            "top_k": step.params.api_details.payload.top_k,
                            "top_n": step.params.api_details.payload.top_n,
                            "offset": step.params.api_details.payload.offset || 0,
                            "filters": step.params.api_details.payload.filters
                        },
                        dynamic_inputs: {
                            "query_text": extractKeys(step.params.api_details.payload.query_text)[0]
                        },
                        output_var: step.output.results,
                        next_step: null, // Will be assigned later when connecting steps
                        accumulate_output: true
                    }
                }

                if (step.type === "Non-LLM - BeX Insights"){
                    console.log(step)
                    return {
                        step_id: "",
                        step_type: step.type,
                        registry_type: "function",
                        registry_key: "BeXInsights_Search",
                        fixed_inputs: {},
                        dynamic_inputs: extractKeys(step?.params?.api_details?.payload),
                        output_var: step.output.results,
                        next_step: null, // Will be assigned later when connecting steps
                        accumulate_output: true
                    }
                }
                
            });
            setInputGroups(updatedInputGroups);
        }
    }, [workflowDetails]);

    return (
        <div className="mt-4 space-y-4">
            <div>
                <Label>Workflow ID</Label>
                <InputValidation
                    disabled
                    value={opCodeName}
                    onChange={(e) => setOpCodeName(e.target.value)}
                    placeholder="Enter a unique name for this workflow"
                    validation={handler.validateOpCodeName}
                />
            </div>
            <div>
                <Label>Workflow Description</Label>
                <Textarea value={opCodeDescription} onChange={(e) => setOpCodeDescription(e.target.value)} placeholder="Enter a description for this workflow" />
            </div>
            <div>
                {/* <Button onClick={handleGetSavedModels} className="flex gap-2" type="button" disabled={isModelLoading}>
                    {isModelLoading ? "Loading Instructions..." : "Reload Instructions"}
                </Button> */}
                <hr/>
            </div>
            {inputGroups.map((group, index) => (
                <div className="flex flex-col gap-4" key={index}>
                    <InputGroup
                        collections={collections}
                        organizationId={selectedOrganization._id}
                        subOrganizationId={selectedSubOrganization._id}
                        group={group} 
                        index={index}
                        handleRemoveGroup={handleRemoveGroup}
                        handleAddGroup={handleAddGroup}
                        inputGroups={inputGroups}
                        setInputGroups={setInputGroups}
                    />
                    <hr/>
                </div>
            ))}
            <div className="flex gap-2">
                <Select onValueChange={(value) => setStepType(STEP_TYPES.find(item => item.name === value).name)} id="step_type" className="w-full" value={stepType}>
                    <SelectTrigger>
                        <SelectValue placeholder="Select step type" />
                    </SelectTrigger>
                    <SelectContent>
                        {STEP_TYPES.map((item) => (
                            <SelectItem key={item.id} value={item.name}>{item.name}</SelectItem>
                        ))}
                    </SelectContent>
                </Select>
                <Button variant="default" onClick={handleAddGroup} className="w-full flex gap-2" type="button"><Plus className="h-4 w-4" />Add Step</Button>
            </div>
        </div>
    );
}
