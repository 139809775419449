import React, { createContext, useState, useEffect } from 'react';
import { watsonxService } from 'api/services/PROJECT-O/WATSONX.service';
import { groqService } from 'api/services/PROJECT-O/GROQ.service';
import labHelper from '../classes/AILabHelper';
import { unifiedModelService } from 'api/services/PROJECT-O/UnifiedModel.service';
import { unifiedModelWSService } from 'api/services/PROJECT-O/WEBSOCKETS/UnifiedModel.websocket.service';
import { resInput } from 'data/dialogData';

export const GlobalContext = createContext();

export const LiveProvider = ({ children }) => {
    const [groqModels, setGroqModels] = useState([]);
    const [instruction, setInstruction] = useState("");
    const [uploadedFilesUser, setUploadedFilesUser] = useState([]);
    const [uploadedFilesOrg, setUploadedFilesOrg] = useState([]);
    const [fileProcessLoadingUser, setFileProcessLoadingUser] = useState(false);
    const [fileProcessLoadingOrg, setFileProcessLoadingOrg] = useState(false);
    const [context, setContext] = useState("");
    const [targetFormat, setTargetFormat] = useState("");
    const [sampleInput, setSampleInput] = useState("");
    const [sampleOutput, setSampleOutput] = useState("");
    const [inputData, setInputData] = useState("");
    const [output, setOutput] = useState("");
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [messages, setMessages] = useState([{role: "system", msg: "Hi. Please select a document to start querying.", _id: Date.now()}]);
    const [messageLoading, setMessageLoading] = useState(false);
    const [selectedModelType, setSelectedModelType] = useState("watsonx");
    const [selectedModel, setSelectedModel] = useState("");
    const [isGenerating, setIsGenerating] = useState(false);
    const [parametersWatsonX, setParametersWatsonX] = useState({
        decoding_method: "greedy",
        temperature: 0.7,
        min_new_tokens: 10,
        max_new_tokens: 200,
        top_p: 1, top_k: 50,
        stop_sequences: ["<|endoftext|>", "<|endofcode|>"],
        repetition_penalty: 1.2});
    const [parametersGroq, setParametersGroq] = useState({
        max_tokens: 200,
        n: 1,
        top_p: 1,
        stop: ["<|endoftext|>", "<|endofcode|>"],
        temperature: 0,
        frequency_penalty: 1.2,
        presence_penalty: 0,
        response_format: {"type": "text"},
        });

    const SendWatsonXPrompt = async () => {
        try {
            setOutput("")
            let prompt = labHelper.ConstructPromptUnified({
                "instruction": instruction.trim(),
                "context": context.trim(),
                "target_format": targetFormat.trim(),
                "sample_input": sampleInput.trim(),
                "sample_output": sampleOutput.trim(),
                // "input_data": inputData,
            });

            let params = parametersWatsonX;
            // ensure other params are not changed (from sampling)
            if (parametersWatsonX.decoding_method === "greedy") {
                params.temperature = 0.0;
                params.top_p = 1.0;
                params.top_k = 50;
            }

            const body = {
                "modelId": selectedModel,
                "parameters": params,
                "prompt": prompt,
                "query": inputData.trim()
            }
            console.log(body)

            console.log("Sent Prompt\n", prompt);
            const res = await unifiedModelWSService.experiment(body, "IBM");
            if (typeof res === "object" && res.model_type && res.result.prediction) {
                setOutput(res.result.prediction);
            } else {
                throw new Error(res);
            } 
        }catch (error) {
            throw new Error(error);
        }
    }

    const SendGroqPrompt = async () => {
        try {
            setOutput("")
            let prompt = labHelper.ConstructPromptUnified({
                "instruction": instruction.trim(),
                "context": context.trim(),
                "target_format": targetFormat.trim(),
                "sample_input": sampleInput.trim(),
                "sample_output": sampleOutput.trim(),
                // "input_data": inputData,
            });

            const body = {
                "modelId": selectedModel,
                "parameters": parametersGroq,
                "prompt": prompt,
                "query": inputData.trim()
            }
            console.log(body)
            
            console.log("Sent Prompt\n", prompt);
            const res = await unifiedModelWSService.experiment(body, "Groq");
            console.log(res);
            if (typeof res === "object" && res.model_type && res.result.prediction) {
                setOutput(res.result.prediction);
            } else {
                throw new Error(res);
            }
        }catch (error) {
            throw new Error(error);
        }
    }

    const LoadGroqModels = async () => {
        try {
            const res = await unifiedModelService.listGroqModels();
            console.log('Groq API Response:', res); 
            setGroqModels(res.data.models.data);
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        LoadGroqModels();
    }, []);

    
    const Clear = () => {
        setInstruction('');
        setContext('');
        setTargetFormat('');
        setSampleInput('');
        setSampleOutput('');
        setInputData('');
        setOutput('');
        setSelectedModel('');
    }

    const values = {
        groqModels,
        instruction, setInstruction,
        context, setContext,
        targetFormat, setTargetFormat,
        sampleInput, setSampleInput,
        sampleOutput, setSampleOutput,
        inputData, setInputData,
        output, setOutput,
        selectedDocuments, setSelectedDocuments,
        messages, setMessages,
        messageLoading, setMessageLoading,
        selectedModelType, setSelectedModelType,
        selectedModel, setSelectedModel,
        parametersWatsonX, setParametersWatsonX,
        parametersGroq, setParametersGroq,
        isGenerating, setIsGenerating,
        fileProcessLoadingUser, setFileProcessLoadingUser,
        fileProcessLoadingOrg, setFileProcessLoadingOrg,
        uploadedFilesUser, setUploadedFilesUser,
        uploadedFilesOrg, setUploadedFilesOrg,
        SendWatsonXPrompt,
        SendGroqPrompt,
        Clear
    }

    return (
        <GlobalContext.Provider value={values}>
            {children}
        </GlobalContext.Provider>
    );
};
