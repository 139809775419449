/* eslint-disable max-lines */

import React, {
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import "./WebDemo.css";
import * as ShadeDD from "../../../components/ui/dropdown-menu";
import { Context } from "../../../context/GlobalState";
// import { BsX } from "react-icons/bs";
import { RotateCcw } from 'lucide-react';
import WebChat from "./WebChat";
import { Combobox } from "../../../components/ui/combobox";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/ui/tabs";
import { Card, CardDescription, CardFooter, CardContent, CardHeader, CardTitle } from "../../../components/ui/card";
import { LoaderSpinner } from "../../../components/LoaderSpinner";
import { TrashIcon } from "@radix-ui/react-icons";
import { Label } from "@radix-ui/react-dropdown-menu";
import { useParams } from "react-router-dom";
import { ragService } from "api/services/PROJECT-O";
import { LoaderModal } from "components/LoaderModal";
import { Trash2 } from 'lucide-react';
import { SubOrgContext } from "context/SubOrganizationContext";
import { OrganizationContext } from "context/OrganizationContext";
import { unifiedModelService } from 'api/services/PROJECT-O/UnifiedModel.service';

export default function DemoModeWeb() {
  const [activeStep, setActiveStep] = useState("use-case");
  const [activeTab, setActiveTab] = useState("selectUseCase")
  const [docList, setDocList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState([]);
  const [fileArrStatus, setFileArrStatus] = useState([]);
  const [fileArr, setFileArr] = useState([]);
  const { user, addNewNotifcation } = useContext(Context);
  const [uploadedDocs, setUploadedDocs] = useState([])
  const FileInputRef = useRef(null);
  const [, setModelInfo] = useState(null);
  const [modelItems, setModelItems] = useState([]);
  const [modelLoading, setModelLoading] = useState(false);
  const [modelDetails, setModelDetails] = useState(null);
  
  const [, setPrompt] = useState('');
  const [, setDecodingMethod] = useState('');
  const [, setModelIdName] = useState('');
  const [, setMaxTokens] = useState('');
  const [selectedModel, setSelectedModel] = useState();
  const { selectedOrganization } = useContext(OrganizationContext);
  const { selectedSubOrganization } = useContext(SubOrgContext);

  useEffect(() => {
    fetchAllModels();
  }, []);

  const fetchAllModels = async () => {
    setModelLoading(true);
    try {
      const res = await unifiedModelService.GetModelsByOrgSubOrg(selectedOrganization._id, selectedSubOrganization._id, "IBM");
      const foundationModels = res.data?.FoundationModels || [];

      const body = {
        model_type: "IBM",
        org_id: selectedOrganization._id,
        sub_org_id: selectedSubOrganization._id,
        unique_names: foundationModels,
      };

      const resDetails = await unifiedModelService.getModelsByUniqueNames(body);
      const modelsObject = resDetails.data?.models || {};

      const modelsWithDetails = Object.keys(modelsObject).map((key, index) => ({
        id: index,
        name: modelsObject[key].unique_name,
        modelId: modelsObject[key].modelId,
        parameters: modelsObject[key].parameters,
        prompt: modelsObject[key].prompt,
        description: modelsObject[key].modelDescription,
      }));

      setModelItems(modelsWithDetails);
    } catch (error) {
      console.error("Error fetching models:", error);
      addNewNotifcation("Error fetching models. Please try again.", "danger");
    } finally {
      setModelLoading(false);
    }
  };

  const handleModelSelect = async (model) => {
    setSelectedModel(model);
    setModelDetails(model); // Display model details

    // Optionally log the details for debugging
    console.log("Selected Model Details:", model);
  };


  // State to track step enablement
  const [stepEnabled, setStepEnabled] = useState({
    useCase: true,
    selectDocument: false,
    chat: false,
  });

  const {subOragID, oragID} = useParams()
  
  const handleGetDocuments = async () => {
      try {
          setIsLoading(true);
          const preDefinedPromise = ragService.listDocuments(process.env.REACT_APP_COMMON_ORGANIZATION_ID, process.env.REACT_APP_COMMON_SUB_ORGANIZATION_ID, {'assistant':`web-engagement`})
          const userDefinedPromise = ragService.listDocuments(oragID, process.env.REACT_APP_DEMO_MODE, {'assistant':`web-engagement`, project:"webdemo_upload",uploader: user.email})
          
          const [preDefinedDocs, userDefinedDocs] = await Promise.all([preDefinedPromise, userDefinedPromise]);
        //   join user uploader with predefined
          const allDocs = [...preDefinedDocs.data, ...userDefinedDocs.data]
          
          setDocList(allDocs.map((file) => ({
            documentRecord: { ...file },
          })));

      } catch (err) {
          console.log('err: ', err);
      } finally {
          setIsLoading(false);
      }
  };

  useEffect(() => {
    console.log(fileArr)
    handleGetDocuments();
    handleListUploadedFiles()
    console.log(user);
  }, []);
  
  useEffect(() => {
    console.log(fileArrStatus);
  }, [fileArrStatus]);

  const handleProcessFiles = async () => {
    setIsLoading(true);
    try {
        for await (const fileObj of fileArr) {
            const formData = new FormData();
            const file = fileObj.file;

            formData.append('organization_id', oragID);
            formData.append('sub_organization_id', process.env.REACT_APP_DEMO_MODE);
            formData.append('files', file);
            formData.append('metadata', JSON.stringify({'assistant':`web-engagement`, project:"webdemo_upload", uploader: user.email, filename: file.name }));
            formData.append('chunk_size', 700);
            formData.append('chunk_overlap', 70);
            formData.append('bucket_name', `${oragID}-bex-demo-mode`);

            const res = await ragService.processFile(formData)

            console.log('API Response:', res.data);

            const { document_ids } = res.data;
            const _id = document_ids[0];

            // Update file status immediately
            setFileArr(prevFiles =>
                prevFiles.map(file => {
                    if (file.file.name === fileObj.file.name) {
                        return { ...file, _id, status: "Processed" };
                    }
                    return file;
                })
            );

            setUploadedDocs(prevDocs => [
                ...prevDocs,
                { documentRecord: { ...fileObj, _id, status: "Processed", file_name: fileObj.file.name } }
            ]);

            addNewNotifcation('Document added successfully', 'success');
            console.log("THIS IS ID", _id);
        }

    } catch (error) {
        console.error('Error uploading document:', error);
        addNewNotifcation('Document upload failed. Please try again.', 'danger');
    } finally {
        setIsLoading(false);
    }
};


  const handleClear = () => {
    setSelectedModel(null);
    setModelDetails(null); 
    setPrompt(''); 
    setDecodingMethod('');
    setModelIdName('');
    setMaxTokens('');
};

  
  const handleListUploadedFiles = async () => {
    try {
      const res = await ragService.listDocuments(oragID, process.env.REACT_APP_DEMO_MODE, {'assistant':`web-engagement`, project:"webdemo_upload", uploader: user.email})
        setUploadedDocs(res.data.map(doc=>({documentRecord:doc,name:doc.file_name,value:{documentRecord:doc}})))
     
    } catch (error) {
      console.error('Error showing document:', error);
      // addNewNotifcation('Document upload failed. Please try again.', 'danger');
    } finally {
      setIsLoading(false);
    }
  }

  const handleSetUploadFiles = (e) => {
    if (Array.from(e.target.files).length <= 5) {
        setMode("upload");
        const filesWithMetadata = Array.from(e.target.files).map(file => ({
            file,
            status: "Ready to upload",
            progress: 0,
            label: file.name,
        }));

        setFileArr(filesWithMetadata);
        setFileArrStatus(filesWithMetadata.map(file => ({
            progress: 0,
            status: "Ready to upload",
            label: file.label,
            resps: [],
        })));
    } else {
        e.preventDefault();
        addNewNotifcation("Cannot upload more than 5 files", "danger");
        e.target.value = null;
    }
};

  useEffect(() => {
    if (selectedDocument.length > 0) {
      setMode("dropdown");
    } else {
      setMode("upload")
    }
    
  }, [selectedDocument]);
  useEffect(()=>{console.log("MODE",mode)},[mode])

  useEffect(() => {
    console.log('Selected Model:', selectedModel);
  }, [selectedModel]);

  const listSelectedDocument = () => {
    return selectedDocument.map((value) => (
        <Card key={value.documentRecord._id} className="mt-2">
            <CardHeader>
                <CardTitle>{value.documentRecord.file_name}</CardTitle>
            </CardHeader>
        </Card>
    ));
};
  const handleIfSelected = (doc) => {
    if (
      selectedDocument.find(
        (document) => document.documentRecord._id === doc.documentRecord._id
      )
    )
      return true;
    else return false;
  };
  
  const handleDelete = (_id) => {
    ragService.deleteDocument(oragID, process.env.REACT_APP_DEMO_MODE, _id, `${oragID}-bex-demo-mode`)
      .then((res) => {
        setUploadedDocs(prev => prev.filter((obj) => obj.documentRecord._id !== _id));
        addNewNotifcation('Document deleted successfully', 'success');
        console.log('Document deleted successfully:', res);
      }).catch((err) => {
        console.error('Error deleting document:', err);
        console.log('ID num', _id)
      }).finally(() => {
        handleGetDocuments();
      });
  }
  

useEffect(() => {
  setStepEnabled((prevState) => ({
    ...prevState,
    selectDocument: !!selectedModel,
  }));
}, [selectedModel]);

useEffect(() => {
  setStepEnabled((prevState) => ({
    ...prevState,
    chat: !!selectedModel && (selectedDocument.length > 0 || uploadedDocs.length > 0),
  }));
}, [selectedDocument, uploadedDocs, selectedModel]);

useEffect(() => {
  console.log('Step Enabled:', stepEnabled);
}, [stepEnabled]);

const handleStepChange = (step) => {

  if (stepEnabled[step]) {
    setActiveStep(step);
    setUploadedDocs([]); 
  } else {
    console.log(`Step '${step}' is not enabled.`);

  if (step === "chat" && (!uploadedDocs.length || !selectedDocument.length)) {
    addNewNotifcation('Please upload a document or select a document to proceed.', 'warning');
    return;
  }
  
    
  }
};

const handleTabChange = (tab) => {
  setActiveTab(tab);
  if (tab === "selectDocument") {
    handleGetDocuments(); 
  }
  setUploadedDocs([]);  
};

useEffect(() => {
  if (activeTab !== "selectDocument" && activeStep === "selectDocument") {
    setUploadedDocs([]);
  }
}, [activeTab, activeStep]);

useEffect(() => {
  if (activeTab === 'select-document') {
    handleGetDocuments(); 
  }
}, [activeTab]);


return (
    <div className="flex gap-2 flex-wrap flex-grow">
        <Card className={`min-w-[70%] relative flex flex-grow flex-col p-1 ${activeStep === 'use-case' ? (selectedModel ? 'h-[590px]' : 'h-[320px]') : ''} ${activeStep === 'selectDocument' ? 'h-[500px]' : ''} ${activeStep === 'chat' ? 'h-[680px]' : ''}`}>
            <CardHeader>
                <CardTitle>Web Assistant Demo</CardTitle>
                <CardDescription>
                    The BeX Assistant for Web Engagement is an advanced conversational AI platform designed to address the limitations and simplifies support and enhances user experiences. See how easily we can create and showcase a chatbot tailored to your enterprise's needs.
                </CardDescription>
            </CardHeader>
            <CardContent className="flex flex-grow h-1 flex-col box-border">
                <div className="flex items-center justify-center space-x-4">
                    <div
                      className={`flex items-center space-x-2 ${activeStep === "use-case" ? "text-primary" : "text-muted-foreground"} ${!stepEnabled["useCase"] ? "cursor-not-allowed opacity-50" : "cursor-pointer"}`}
                      onClick={() => setActiveStep("use-case")}
                    >
                        <div className={`flex h-8 w-8 items-center justify-center rounded-full ${activeStep === "use-case" ? "bg-primary text-primary-foreground" : "border border-muted-foreground text-muted-foreground"}`}>
                            1
                        </div>
                        <span>Select Use Case</span>
                    </div>
                    <div
                      className={`flex items-center space-x-2 ${activeStep === "selectDocument" ? "text-primary" : "text-muted-foreground"} ${!stepEnabled["selectDocument"] ? "cursor-not-allowed opacity-50" : "cursor-pointer"}`}
                      onClick={() => handleStepChange("selectDocument")}
                    >
                        <div className={`flex h-8 w-8 items-center justify-center rounded-full ${activeStep === "selectDocument" ? "bg-primary text-primary-foreground" : "border border-muted-foreground text-muted-foreground"}`}>
                            2
                        </div>
                        <span>Select Document</span>
                    </div>
                    <div
                      className={`flex items-center space-x-2 ${activeStep === "chat" ? "text-primary" : "text-muted-foreground"} ${!stepEnabled["chat"] ? "cursor-not-allowed opacity-50" : "cursor-pointer"}`}
                      onClick={() => handleStepChange("chat")}
                    >
                        <div className={`flex h-8 w-8 items-center justify-center rounded-full ${activeStep === "chat" ? "bg-primary text-primary-foreground" : "border border-muted-foreground text-muted-foreground"}`}>
                            3
                        </div>
                        <span>Chat with your Generated Bot</span>
                    </div>
                </div>

                {activeStep === "use-case" && stepEnabled["useCase"] && (
                <div className="mt-3 min-h-[80%] overflow-auto">
                    <Label className="mb-3">Please select a use case  you're interested in from the options provided to begin the chatbot generation process. Your selection will determine the focus and functionality of the chatbot.</Label>
                    <div className="flex">
                        <div className="relative flex max-w-[200px]">

                            {modelLoading && <LoaderModal />}
                            <Combobox
                                items={modelItems}
                                defaultDisplayed="Select Model"
                                className="w-[350px]"
                                selectedValue={selectedModel}
                                setter={handleModelSelect}
                              />
                        </div>

                        <Button variant="secondary" size="icon" className="ml-2" onClick={handleClear}>
                            <RotateCcw className="w-5 h-5" />
                        </Button>
                    </div>
                    {modelDetails && (
                    <div className="mt-3 h-[90%]">
                        {selectedModel && (
                            <Card>
                                <CardHeader>
                                    <CardTitle>Prompt Used</CardTitle>
                                    <CardDescription>{selectedModel?.prompt || 'No prompt available.'}</CardDescription>
                                </CardHeader>
                            </Card>
                        )}

                        <Card>
                            <CardHeader>
                                <CardTitle>Model Details</CardTitle>
                                <CardDescription>
                                    {selectedModel.modelId || 'No model available.'}
                                </CardDescription>
                                <CardTitle>Model Parameters</CardTitle>
                                <CardDescription>
                                    Decoding Method: {selectedModel.parameters.decoding_method} <br />
                                    Max Tokens: {selectedModel.parameters.max_new_tokens}
                                </CardDescription>
                            </CardHeader>
                        </Card>
                    </div>
                  )}
                </div>
                )}

                {activeStep === "selectDocument" && stepEnabled["selectDocument"] && (
                <Tabs defaultValue="select-document" className="mt-6 overflow-auto" onValueChange={handleTabChange}>
                    <TabsList>
                        <TabsTrigger value="select-document" >Select Document</TabsTrigger>
                        <TabsTrigger value="upload-document" disabled={selectedDocument.length >= 1}>Upload Document</TabsTrigger>
                    </TabsList>
                    <TabsContent value="select-document">
                        <div className="space-y-2">
                            <Label htmlFor="document">Select up to five documents to build your chatbot’s knowledge base. These will be the foundation for crafting a chatbot that truly understands and addresses your specific needs.</Label>
                            <Card>
                                <CardHeader>
                                    <CardTitle>Select Sample Documents</CardTitle>
                                    <CardDescription>These are all preloaded documents you can try to generate your chatbot. Select up to five files from the selection.</CardDescription>
                                </CardHeader>
                                <CardContent>
                                    <label>
                                        <ShadeDD.DropdownMenu>
                                            <ShadeDD.DropdownMenuTrigger asChild>
                                                <Button className="w-fit block mt-1" >Select files</Button>
                                            </ShadeDD.DropdownMenuTrigger>
                                            <ShadeDD.DropdownMenuContent side="bottom">
                                                <ShadeDD.DropdownMenuLabel>Documents</ShadeDD.DropdownMenuLabel>
                                                <ShadeDD.DropdownMenuSeparator />
                                                {docList.length === 0 ? (
                                                    <ShadeDD.DropdownMenuItem>No available documents found.</ShadeDD.DropdownMenuItem>
                                                  ) : (
                                                    docList.map((doc) => (
                                                        <div className="flex gap-1 justify-between">
                                                            <ShadeDD.DropdownMenuCheckboxItem
                                                            className="w-full"
                                                            key={doc.documentRecord._id}
                                                            checked={handleIfSelected(doc)}
                                                            onSelect={(event) => {
                                                              const result = selectedDocument.find(
                                                                (document) =>
                                                                  document.documentRecord._id === doc.documentRecord._id
                                                              );
                                                              event.preventDefault();
                                                              if (result) {
                                                                setSelectedDocument((prev) =>
                                                                  prev.filter(
                                                                    (docToFilter) =>
                                                                      docToFilter.documentRecord._id !== result.documentRecord._id
                                                                  )
                                                                );
                                                              } else {
                                                                if (selectedDocument.length < 5) {
                                                                  setSelectedDocument((prev) => [
                                                                    ...prev,
                                                                    {
                                                                      name: doc.documentRecord?.filename,
                                                                      value: { doc },
                                                                      documentRecord: doc.documentRecord,
                                                                    },
                                                                  ]);
                                                                }
                                                              }
                                                            }}
                                                          >
                                                                {doc.documentRecord?.file_name}

                                                                
                                                            </ShadeDD.DropdownMenuCheckboxItem>
                                                            {doc.documentRecord.organization_id !== "demo" &&
                                                                <Button variant="ghost" size="icon" onClick={() => handleDelete(doc.documentRecord._id)}>
                                                                    
                                                                    <Trash2 className="w-5 h-5"/>
                                                                </Button>}
                                                        </div>
                                                        
                                          ))
                                        )}
                                            </ShadeDD.DropdownMenuContent>

                                        </ShadeDD.DropdownMenu>
                                    </label>
                                    {listSelectedDocument()}
                                </CardContent>
                            </Card>
                        </div>
                    </TabsContent>
                    <TabsContent value="upload-document">
                        <div className="space-y-2 w-[100%]">
                            <Label htmlFor="upload">Try uploading your own documents to create a customized knowledge base for your chatbot. </Label>
                            <div className="flex items-center space-x-2">
                                {/* Insert here !!! */}
                                <Card className="w-[100%]">
                                    <CardHeader>
                                        <CardTitle>Upload Documents</CardTitle>
                                        <CardDescription>Upload a maximum of 5 files by clicking the "Choose files". Supported files are .txt, .docx, .xlsx.</CardDescription>
                                    </CardHeader>
                                    <CardContent>
                                        <label>
                                            <Input
                                          ref={FileInputRef}
                                          multiple
                                          type="file"
                                          className="bg-background block mt-1"
                                          onChange={handleSetUploadFiles}
                                        />
                                        </label>
                                        <div className="flex-grow overflow-auto">
                                            {uploadedDocs.map((doc) => (
                                                <Card key={doc.documentRecord._id} className="flex-grow flex justify-between items-center mt-4">
                                                    <CardHeader>
                                                        <CardTitle>{doc.documentRecord.file_name}</CardTitle>
                                                    </CardHeader>
                                                    <Button className="mr-4"onClick={() => handleDelete(doc.documentRecord._id)}>
                                                        <TrashIcon />
                                                    </Button>
                                                </Card>
                                          ))}
                                        </div>
                                    </CardContent>
                                    <CardFooter className="pt-1 flex gap-2 flex-row-reverse">
                                        <Button
                                        //disabled={uploadedDocs.length < 1 || isLoading}
                                        onClick={handleProcessFiles}
                                        className="w-fit hover:cursor-pointer right-0"
                                      >
                                            {isLoading ? <LoaderSpinner /> : "Process"}
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </div>
                        </div>
                    </TabsContent>
                </Tabs>
                )}

            
                {activeStep === "chat" && (
                <div className="flex flex-col h-full">
                    {selectedModel ? (
                        <WebChat
                                selectedDocument={selectedDocument}
                                uploadFilter={{ project: "webdemo_upload", uploader: user.email }}
                                modelPrompt={selectedModel.prompt}
                                decodingMethod={selectedModel.parameters.decoding_method}
                                modelIdName={selectedModel.modelId}
                                maxTokens={selectedModel.parameters.max_new_tokens}
                              />
                        ) : (
                            <div>Select a model to start using the chat assistant.</div>
                        )}
                </div>
                )}

            </CardContent>
            {/* <CardFooter className="mt-6 pt-2">
                <Button variant="outline" onClick={() => setActiveStep("use-case")}>Back</Button>
            </CardFooter> */}
        </Card>
    </div>
);
}