import React from 'react';
import AIChatBox from './AIChatBox';
import DocumentDisplay from './DocumentDisplay';

const AITeacher = () => {
    return (
        <div className="flex flex-col md:flex-row h-fit gap-4">
            <div className="w-full md:w-[480px] min-w-[480px] h-fit">
                {/* chat box */}
                <AIChatBox />
            </div>
            <div className="w-full h-fit">
                {/* doc display */}
                <DocumentDisplay/>
            </div>
        </div>
    );
};

export default AITeacher;