import {useState} from 'react';
import { Check, Copy } from 'lucide-react';
import { cn } from 'lib/utils';

const CopyButton = ({ code, className }) => {
    const [copied, setCopied] = useState(false);
  
    const handleCopy = () => {
      navigator.clipboard.writeText(code);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    };
  
    return (
      <button onClick={handleCopy} aria-label="Copy code" className='absolute top-2 right-2 select-none'>
        {copied ?
        <span className={cn('flex flex-row gap-2 items-center justify-center text-center', className)}>
            <Check className='w-3 h-3'/>
            <span>
            Copied!
            </span>
        </span> :
        <span className={cn('flex flex-row gap-2 items-center justify-center text-center', className)}>
            <Copy className='w-3 h-3'/>
            <span>
            Copy code
            </span>
        </span>}
      </button>
    );
};

export default CopyButton;