/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react'
import './organizations-styles.css'
import { AppLoader, LoaderContainerDiv, LoaderSpinner } from '../../../components/LoaderSpinner'
import StyledTable from '../../../components/StyledTable'
import { httpRequest } from '../../../utils/httpsRequest'
import { MdAccountCircle, MdPeopleAlt } from 'react-icons/md'
import { RedButton } from '../../../components/Buttons'
import { PopUpModal } from '../../Manager/EntityIntent/components/PopUpModal'
import NewStyledTable from '../../../components/ui/StyledTable'
import { Link } from 'react-router-dom'
import ConfirmDeleteAlert from 'components/ConfirmDeleteAlert'
import { organizationAdminAccessService } from 'api'
import { Context } from 'context/GlobalState'
import { Badge } from 'components/ui/badge'

export default function Organizations() {

    const [organization, setOrganization] = useState([])
    const [loading, setLoading] = useState(true)
    const [membersModal, setMembersModal] = useState(false)
    const [subOrgsModal, setSubOrgsModal] = useState(false)
    const [selectedOrg, setSelectedOrg] = useState({})
    
    // delete org state
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [selectedDelOrg, setSelectedDelOrg] = useState(null)
    const [btnDeleteLoading, setBtnDeleteLoading] = useState(false)

    // context
    const { addNewNotifcation } = useContext(Context)

    useEffect(() => {
        organizationAdminAccessService.getOrganizations()
        .then((res) => {
            console.log('res: ', res);
            setOrganization(res.data?.map((item) => (
                {...item,
                id: item._id, 
                created: item.created?.split('T')[0],
                adminName: item?.superAdmin?.name || 'None'
            })))
        }).catch((error) => {
            console.log('error: ', error);
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    const handleView = (id) => {
        console.log("");
        setMembersModal(true)
        setSelectedOrg(organization.find((item) => item.id === id))
    }
    
    const handleViewSub = (id) => {
        console.log("");
        setSubOrgsModal(true)
        setSelectedOrg(organization.find((item) => item.id === id))
    }

    const handleOpenDelDialog = (id) => {
        setIsDialogOpen(true)
        setSelectedDelOrg(organization.find((item) => item.id === id))
    }

    const handleDeleteOrganization = () => {

        if (selectedDelOrg.clusterStatus !== 'DELETED') {
            return addNewNotifcation('Please delete organization cluster first')
        }

        setBtnDeleteLoading(true)
        organizationAdminAccessService.deleteOrganization(selectedDelOrg._id)
        .then((res) => {
            console.log('res: ', res);
            addNewNotifcation('Organization delete successfully', 'success')
            setTimeout(() => {
                window.location = '/secure/organizations'
            }, 1000)
        }).catch((error) => {
            console.log('error: ', error);
            if (error.response?.status === 403) {
                addNewNotifcation('You don not have the permission to perform this action', 'warning')
            } else {
                addNewNotifcation('Something went wrong. Please try again', 'warning')
            }
        }).finally(() => {
            setBtnDeleteLoading(false)
        })

    }


    const subOrgRowActions = [
        {'name': 'Members', 'icon': <MdAccountCircle size={18} color='var(--primary-color)' />, 'onclick': handleView},
        {'name': 'SubOrgs', 'icon': <MdPeopleAlt size={18} />, 'onclick': handleViewSub},
        {'name': 'Delete', 'icon': <MdPeopleAlt size={18} />, 'onclick': handleOpenDelDialog, isDelete: true},
    ]

    if (loading) {
        return <LoaderContainerDiv> <AppLoader size={50} /> </LoaderContainerDiv>;
    }

    const getClusterBadge = (row) => {
        if (row.clusterStatus === 'DEPLOYED') {
            return (
                <Badge variant="default" className="text-sm bg-green-500">
                    {row.clusterStatus}
                </Badge>
            )
        } else if (row.clusterStatus === 'DELETED') {
            return (
                <Badge variant="destructive" className="text-sm">
                    {row.clusterStatus}
                </Badge>
            )
        } else {
            return (
                <Badge variant="secondary" className="text-sm">
                    {row.clusterStatus}
                </Badge>
            )
        }
    }

    return (
        <div className='admin-organization-container'>
            <div className='admin-organization-header'>
                Organizations
            </div>

            <div>
                <NewStyledTable
                    columns={[{ field: 'name', headerName: 'Name', renderCell: (row) => <Link className='text-blue-600' to={row._id}> {row.name} </Link>}, 
                        { field: 'created', headerName: 'Created'}, { field: 'clusterStatus', headerName: 'Cluster Status', renderCell: (row) => getClusterBadge(row)}, { field: 'adminName', headerName: 'Super Admin'}]}
                    data={organization}
                    rowActions={subOrgRowActions}
                    multiSelect={false}
                />
                <OrganizationMembersView
                membersModal={membersModal}
                setMembersModal={setMembersModal}
                selectedOrg={selectedOrg}
                setSelectedOrg={setSelectedOrg} />
                <SubOrganizationsView
                subOrgsModal={subOrgsModal}
                setSubOrgsModal={setSubOrgsModal}
                selectedOrg={selectedOrg} />

                {/* delete org alert */}
                <ConfirmDeleteAlert 
                isDialogOpen={isDialogOpen}
                setIsDialogOpen={setIsDialogOpen}
                name={selectedDelOrg?.name || ''}
                deleteLoading={btnDeleteLoading}
                handleDeleteOrganization={handleDeleteOrganization} />
            </div>
        </div>

    )
}

const OrganizationMembersView = ({ membersModal, setMembersModal, selectedOrg }) => {

    const [btnLoading] = useState(false)
    const [selectedOrgMembers, setSelectedOrgMembers] = useState([]);

    const handleOnUpdate = () => {
        setMembersModal(false)
    }

    useEffect(() => {
        if (membersModal) {
            httpRequest.get(`admin/get-organization-members/${selectedOrg._id}`)
            .then((res) => {
                console.log(res);
                const tabledata = res.data.map((item) => {
                    return {id: item._id, name: item.name, email: item.email};
                })
                setSelectedOrgMembers(tabledata);
            }).catch((error) => {
                console.log(error);
            })
        } else {
            setSelectedOrgMembers([]);
        }
    }
    , [membersModal]);


    if (!Object.keys(selectedOrg).length > 0) return

    return (
        <div> 
            <PopUpModal setOpenModal={setMembersModal} openModal={membersModal} text={selectedOrg.name}>
                <div className='members-organization-container'>
                    <div className='members-organization-container'>
                        <div style={{display: "flex", gap: "16px", alignItems: "center"}}>
                            <h3>Members</h3>
                            <p>{selectedOrgMembers.length} Total</p>
                        </div>
                        <div className='members-organization'>
                            <NewStyledTable
                                columns={[{ field: 'name', headerName: 'Name' }, { field: 'email', headerName: 'Email' }]}
                                data={selectedOrgMembers}
                                options={[]}
                            />
                        </div>
                    </div>
                </div>
                <RedButton disabled={btnLoading} className='secure-admins-permissions-create-btn' onClick={handleOnUpdate}>
                    {btnLoading ? <LoaderSpinner /> : 'Done'}
                </RedButton>
            </PopUpModal>
        </div>
    )
}

const SubOrganizationsView = ({ subOrgsModal, setSubOrgsModal, selectedOrg }) => {

    const [btnLoading] = useState(false)
    const [subOrgs, setSubOrgs] = useState([])

    const handleOnUpdate = () => {
        
    }

    useEffect(() => {
        if (subOrgsModal) {
            httpRequest.get(`admin/get-sub-organizations/${selectedOrg._id}`)
            .then((res) => {
                console.log(res);
                const tabledata = res.data.map((item) => {
                    return {id: item._id, name: item.name, members: item.members.length};
                })
                setSubOrgs(tabledata);
            }).catch((error) => {
                console.log(error);
            })
        } else {
            setSubOrgs([]);
        }
    }
    , [subOrgsModal]);


    if (!Object.keys(selectedOrg).length > 0) return

    return (
        <div> 
            <PopUpModal setOpenModal={setSubOrgsModal} openModal={subOrgsModal} text={selectedOrg.name}>
                <div className='members-organization-container'>
                    <div className='members-organization-container'>
                        <div style={{display: "flex", gap: "16px", alignItems: "center"}}>
                            <h3>Sub Organization</h3>
                            <p>{subOrgs.length} Total</p>
                        </div>
                        <div className='members-organization'>
                            <StyledTable
                                columns={[{ field: 'name', headerName: 'Name' }, { field: 'members', headerName: 'Members'}]}
                                data={subOrgs}
                                options={[]}
                            />
                        </div>
                    </div>
                </div>
                <RedButton disabled={btnLoading} className='secure-admins-permissions-create-btn' onClick={handleOnUpdate}>
                    {btnLoading ? <LoaderSpinner /> : 'Done'}
                </RedButton>
            </PopUpModal>
        </div>
    )
}
