import React, { useState, useEffect, useContext } from 'react';
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "components/ui/dialog";
import { Trash2, Search, Bot, Clock, Settings2, User } from 'lucide-react';
import { ScrollArea, ScrollBar } from "components/ui/scroll-area";
import { unifiedModelService } from "api/services/PROJECT-O/UnifiedModel.service";
import { Context } from 'context/GlobalState';
import DeleteDialog from 'components/DeleteDialog';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "components/ui/card"
import { Label } from "components/ui/label"
import { OrganizationContext } from 'context/OrganizationContext';

function formatToTitleCase(str) {
    return str
        .replace(/_/g, ' ')           // Replace underscores with spaces
        .replace(/\w\S*/g, function(word) {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });                           // Convert to Title Case
}

export default function InstructionSelectionModal({ organizationId, subOrganizationId, group, index, inputGroups, setInputGroups, model_type }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedInstructions, setSelectedInstructions] = useState([]);
    const [modelDetails, setModelDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [isDeleting, setIsDeleting] = useState(false);
    const [isFetchingModels, setIsFetchingModels] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [instructions, setInstructions] = useState([]); // Local state for instructions

    const { addNewNotifcation } = useContext(Context);
    const { selectedOrganization } = useContext(OrganizationContext);

    const filteredInstructions = instructions.filter((instruction) =>
        instruction.name && instruction.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const fetchInstructions = async () => {
        try {
            setInstructions([]); // Reset instructions
            setSelectedInstructions([]);
            setIsFetchingModels(true);
            setModelDetails(null)
            const res = await unifiedModelService.GetModelsByOrgSubOrg(
                organizationId,
                subOrganizationId,
                model_type
            );

            const instructionNames = res.data?.FoundationModels || [];
            if (instructionNames.length === 0) {
                console.log("No instructions saved.");
                return;
            }
            console.log("fetched models: ", instructionNames)

            const body = {
                model_type: model_type,
                org_id: organizationId,
                sub_org_id: subOrganizationId,
                unique_names: instructionNames,
            };

            const resDetails = await unifiedModelService.getModelsByUniqueNames(body);
            const modelsObject = resDetails.data?.models || {};

            let fetchedInstructions = Object.keys(modelsObject).map((key) => ({
                _id: modelsObject[key].unique_name,
                name: modelsObject[key].unique_name,
                value: modelsObject[key].unique_name,
                ...modelsObject[key],
            }));

            console.log("fetched model details: ", fetchedInstructions)

            fetchedInstructions = fetchedInstructions.map((instruction) => {
                const modifiedByMember = selectedOrganization.members.find(member => member.id._id === instruction.modified_by);
                const userIdMember = selectedOrganization.members.find(member => member.id._id === instruction.user_id);
        
                return {
                    ...instruction,
                    modified_by: modifiedByMember ? modifiedByMember.id.name : instruction.modified_by,
                    user_id: userIdMember ? userIdMember.id.name : instruction.user_id
                };
            });

            setInstructions(fetchedInstructions);
        } catch (error) {
            console.error("Error fetching instructions:", error);
        } finally {
            setIsFetchingModels(false);
        }
    };

    const fetchModelDetails = async (instructionName) => {
        setLoading(true);
        try {
            const body = {
                model_type: model_type,
                org_id: organizationId,
                sub_org_id: subOrganizationId,
                unique_names: [instructionName],
            };
            const res = await unifiedModelService.getModelsByUniqueNames(body);
            setModelDetails(res.data.models[instructionName]);
        } catch (error) {
            console.error("Failed to fetch model details", error);
            setModelDetails(null);
        } finally {
            setLoading(false);
        }
    };

    const initializeSelection = () => {
        if (group.unique_name) {
            setSelectedInstructions([group.unique_name]);
            fetchModelDetails(group.unique_name);
        } else {
            setSelectedInstructions([]);
            setModelDetails(null);
        }
    };

    const handleInstructionSelect = (instruction) => {
        if (selectedInstructions.includes(instruction.name)) {
            // If the instruction is already selected, remove it
            setSelectedInstructions(selectedInstructions.filter(name => name !== instruction.name));
            setModelDetails(null);
        } else {
            if (isDeleting) {
                // In delete mode, allow multi-selection
                setSelectedInstructions([...selectedInstructions, instruction.name]);
                setModelDetails(instruction);
            } else {
                // Not in delete mode, only allow one selection at a time
                setSelectedInstructions([instruction.name]);
                setModelDetails(instruction);
            }
        }
    };

    const handleSelectInstruction = () => {
        if (selectedInstructions.length === 0) {
            addNewNotifcation("Please select an instruction first.", "warning");
            return;
        }

        setInputGroups((prev) =>
            prev.map((group, i) => (i === index ? { ...group, unique_name: selectedInstructions[0] } : group))
        );

        addNewNotifcation("Instruction selected.", "success");
        setIsOpen(false);
    };

    const handleDelete = async () => {
        try {
            setIsDeleteLoading(true);
            const body = {
                org_id: organizationId,
                sub_org_id: subOrganizationId,
                model_type: model_type,
                unique_names: selectedInstructions,
            };
            const res = await unifiedModelService.deleteModels(body);
            if (res.data?.deleted) {
                addNewNotifcation("Successfully deleted the instruction(s).", "success");
                setInstructions(instructions.filter(
                    (instruction) => !selectedInstructions.includes(instruction.name)
                ));
                if (selectedInstructions.includes(group.unique_name)) {
                    setInputGroups((prevGroups) => 
                        prevGroups.map((g, i) => 
                            i === index ? { ...g, unique_name: "" } : g
                        )
                    );
                }
                setIsDeleting(false);
            }
            
        } catch (error) {
            console.error("Error deleting instructions:", error);
            addNewNotifcation("Error deleting instructions. Please try again.", "danger");
        } finally {
            setIsDeleteLoading(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            initializeSelection();
            fetchInstructions(); // Fetch instructions when modal opens
        }
    }, [isOpen]);

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild className="w-full">
                <Button variant="outline" className="text-left" disabled={!model_type}>
                    {!model_type ? "Select Instruction Type" : group?.unique_name || `View ${model_type} Instructions`}
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[90vw] sm:max-h-[90vh]">
                <DialogHeader>
                    <DialogTitle>{!isDeleting ? "Select an Instruction" : "Delete Instructions"} ({model_type})</DialogTitle>
                    <DialogDescription>
                        {!isDeleting ? "Choose an instruction and view its details." : "Choose instructions to delete."}
                    </DialogDescription>
                </DialogHeader>
                <div className="space-y-4">
                    <div className="flex gap-4 items-center">
                        <div className="relative w-full">
                            <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                            <Input
                                placeholder="Search instructions..."
                                className="pl-8 w-full"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <Button onClick={fetchInstructions} className="flex gap-2" type="button" disabled={isFetchingModels}>
                            {isFetchingModels ? "Loading Instructions..." : "Reload Instructions"}
                        </Button>
                        <Button variant="outline" onClick={() => {setIsDeleting(!isDeleting); setSelectedInstructions([]);}} className="flex gap-2">
                            {isDeleting ? (
                                <span className="flex flex-nowrap gap-2 items-center">
                                    <Trash2 className="w-4 h-4" />
                                    Cancel Delete
                                </span>
                            ) : (
                                <span className="flex flex-nowrap gap-2 items-center">
                                    <Trash2 className="w-4 h-4" />
                                    Delete Multiple
                                </span>
                            )}
                        </Button>
                    </div>

                    <div className="flex flex-col md:flex-row gap-4">
                        {instructions.length === 0 && (
                            <div className="flex items-center justify-center w-full text-gray-500">
                                <span>No {model_type} instruction(s) saved.</span>
                            </div>
                        )}
                        <ScrollArea className="h-[400px] flex-grow">
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-4 p-4">
                                {filteredInstructions.map((instruction) => (
                                    <Button
                                        key={instruction.name}
                                        variant={selectedInstructions.includes(instruction.name) ? (isDeleting ? "destructive" : "default") : "outline"}
                                        onClick={() => handleInstructionSelect(instruction)}
                                        className="justify-start h-auto text-left"
                                    >
                                        <div className="w-full">
                                            <div className="font-semibold w-full truncate">{instruction.name}</div>
                                            <div className="text-xs text-muted-foreground">{model_type}</div>
                                        </div>
                                    </Button>
                                ))}
                            </div>
                        </ScrollArea>

                        {loading ? (
                            <div className="md:w-1/3 p-2 bg-muted rounded-md">
                                <h4 className="mb-2 font-semibold">Loading Details...</h4>
                            </div>
                        ) : isDeleting ? (
                            <ScrollArea className="max-h-[400px] min-w-[480px] max-w-[480px] pr-4">
                                <Card className="w-full h-fit my-2">
                                    <CardHeader>
                                        <div className="flex items-start justify-between">
                                            <div className="space-y-1">
                                                <CardTitle className="text-xl whitespace-pre-wrap break-all">Selected Instructions to Delete</CardTitle>
                                                <CardDescription className="text-xs">This action cannot be undone.</CardDescription>
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <CardContent className="space-y-2.5">
                                        <ul className="list-disc text-xs space-y-2 pl-4">
                                            {selectedInstructions.length > 0 ? (
                                                selectedInstructions.map((name) => (
                                                    <li key={name} className="text-gray-700">
                                                        {name}
                                                    </li>
                                                ))
                                            ) : (
                                                <li className="text-gray-500">No instructions selected for deletion.</li>
                                            )}
                                        </ul>
                                    </CardContent>
                                </Card>
                            </ScrollArea>
                        ) : modelDetails ? (
                            <ScrollArea className="max-h-[400px] min-w-[480px] max-w-[480px] pr-4">
                                <Card className="w-full my-2">
                                    <CardHeader>
                                        <div className="flex items-start justify-between">
                                            <div className="space-y-1">
                                                <CardTitle className="text-xl whitespace-pre-wrap break-all">{modelDetails.unique_name}</CardTitle>
                                                <CardDescription className="text-xs">Instruction</CardDescription>
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <CardContent className="space-y-2.5">
                                        <div className="space-y-2">
                                        <div className="flex items-center gap-2 text-xs">
                                            <User className="h-4 w-4" />
                                            <Label className="font-medium text-xs">Created By:</Label>
                                            <span className="text-muted-foreground">{modelDetails.user_id || "-"}</span>
                                        </div>
                                        <div className="flex items-center gap-2 text-xs">
                                            <Clock className="h-4 w-4" />
                                            <Label className="font-medium text-xs">Modified By:</Label>
                                            <span className="text-muted-foreground">{modelDetails.modified_by || "-"}</span>
                                        </div>
                                        </div>
                                        <hr />
                                        <div className="space-y-2">
                                        <div className="">
                                            <Label className="text-xs font-medium">Description</Label>
                                            <p className="text-xs text-muted-foreground">{modelDetails.modelDescription || "-"}</p>
                                        </div>
                                        <div className="space-y">
                                            <Label className="text-xs font-medium">Prompt</Label>
                                            <p className="text-xs text-muted-foreground whitespace-pre-wrap break-all">{modelDetails.prompt || "-"}</p>
                                        </div>
                                        </div>
                                        <hr />
                                        <div className="space-y-2">
                                        <div className="flex items-center gap-2">
                                            <Bot className="h-4 w-4" />
                                            <Label className="text-xs font-medium">Model ID</Label>
                                        </div>
                                        <code className="rounded bg-muted px-2 py-1 text-xs whitespace-pre-wrap break-all">{modelDetails.modelId}</code>
                                        </div>
                                        <hr />
                                        <div className="space-y-2">
                                        <div className="flex items-center gap-2">
                                            <Settings2 className="h-4 w-4" />
                                            <Label className="text-xs font-medium">Parameters</Label>
                                        </div>
                                        <div className="grid gap-4 sm:grid-cols-2">
                                            {Object.entries(modelDetails.parameters)
                                                .filter(([key]) => key !== "repetition_penalty" && key !== "stop_sequence" && key !== "n" && key !== "stop" && key !== "stream" && key !== "seed")
                                                .map(([key, value]) => (
                                                    <div className="" key={key}>
                                                        <Label className="text-xs font-medium">{formatToTitleCase(key)}</Label>
                                                        <p className="text-sm text-muted-foreground">{value}</p>
                                                    </div>
                                                ))
                                            }
                                            
                                        </div>
                                        </div>
                                    </CardContent>
                                </Card>
                                <ScrollBar orientation="horizontal" />
                            </ScrollArea>
                        ) : (
                            <ScrollArea className="max-h-[400px] min-w-[480px] max-w-[480px] pr-4">
                                <Card className="w-full my-2">
                                    <CardHeader>
                                        <div className="flex items-start justify-between">
                                            <div className="space-y-1">
                                                <CardTitle className="text-xl whitespace-pre-wrap break-all">Select Instruction</CardTitle>
                                                <CardDescription className="text-xs">Please select an instruction to see details</CardDescription>
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <CardContent className="space-y-2.5">
                                        <div className="space-y-2">
                                        <div className="flex items-center gap-2 text-xs">
                                            <User className="h-4 w-4" />
                                            <Label className="font-medium text-xs">Created By:</Label>
                                            <span className="text-muted-foreground"></span>
                                        </div>
                                        <div className="flex items-center gap-2 text-xs">
                                            <Clock className="h-4 w-4" />
                                            <Label className="font-medium text-xs">Modified By:</Label>
                                            <span className="text-muted-foreground"></span>
                                        </div>
                                        </div>
                                        <hr />
                                        <div className="space-y-2">
                                        <div className="">
                                            <Label className="text-xs font-medium">Description</Label>
                                            <p className="text-xs text-muted-foreground"></p>
                                        </div>
                                        <div className="space-y">
                                            <Label className="text-xs font-medium">Prompt</Label>
                                            <p className="text-xs text-muted-foreground whitespace-pre-wrap break-words"></p>
                                        </div>
                                        </div>
                                        <hr />
                                        <div className="space-y-2">
                                        <div className="flex items-center gap-2">
                                            <Bot className="h-4 w-4" />
                                            <Label className="text-xs font-medium">Model ID</Label>
                                        </div>
                                        </div>
                                        <hr />
                                        <div className="space-y-2">
                                        <div className="flex items-center gap-2">
                                            <Settings2 className="h-4 w-4" />
                                            <Label className="text-xs font-medium">Parameters</Label>
                                        </div>

                                        </div>
                                    </CardContent>
                                </Card>
                                <ScrollBar orientation="horizontal" />
                            </ScrollArea>
                        )}

                    </div>
                </div>
                <DialogFooter>
                    <DialogClose asChild>
                        <Button type="button" variant="secondary">
                            Close
                        </Button>
                    </DialogClose>

                    {!isDeleting ? (
                        <Button variant="default" onClick={handleSelectInstruction} disabled={selectedInstructions.length === 0}>
                            Select Instruction
                        </Button>
                    ) : (
                        <DeleteDialog label="Delete Instructions" itemNames={selectedInstructions} loading={isDeleteLoading} onDelete={handleDelete} />
                    )}
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
