/* eslint-disable max-lines */
import Collection from "../../../pages/Dashboard/SubOrganization/Projects/Collection/Collection"
import { useEffect, useState } from "react"
import { redisCollectionService } from "api";

export default function DemoKnowledgeBuilder() {

    const [loading, setLoading] = useState(true)
    const [collections, setCollections] = useState(null)


    useEffect(() => {
        redisCollectionService.listCollections('demo', 'demo')
        .then((projectsResponse) => {
            console.log('projectsResponse: ', projectsResponse);
            setCollections(projectsResponse.data)
        }).catch((error) => {
            console.log('error:R', error);
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    // Actions
    const actions = (action) => {
        const { type, payload } = action;

        switch (type) {
            case "SET_COLLECTIONS":
                return setCollections(payload);
            default:
                return loading;
        }
    };

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6">BeX Knowledge Builder</h1>
            {loading ? 'loading' : <Collection isMilvusActivated={true} collections={collections} actions={actions} />}
        </div>
    )
}
