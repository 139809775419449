import React from 'react'
import { RotateCcw } from 'lucide-react'
import { IoChatbubbleEllipsesOutline } from 'react-icons/io5';
import { AiOutlineHome } from 'react-icons/ai';
import { FaAngleDown } from 'react-icons/fa6';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip';

const WebchatHeader = ({toggleChat, handleClearChat, setIsHome, assistantName}) => {

return (
    <div className="p-3 flex justify-between items-center border-b cursor-pointer border-[#ccc]">
        <div className="flex items-center gap-2">
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild> 
                        <button onClick={()=> setIsHome(true)} data-no-drag>
                            <AiOutlineHome className='hover:text-[#8c8c8c] cursor-pointer h-6 w-6'/>
                        </button>
                    </TooltipTrigger>
                    <TooltipContent className="bg-gray-900 text-white w-fit">
                        <p>Return to Home screen</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
            <h3 className="font-bold text-lg">{assistantName}</h3>
        </div>
        <div className="flex items-center space-x-3">
            
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild> 
                        <button onClick={()=> setIsHome(false)} data-no-drag>
                            <IoChatbubbleEllipsesOutline className='hover:text-[#8c8c8c] cursor-pointer h-4 w-5'/>
                        </button>
                    </TooltipTrigger>
                    <TooltipContent className="bg-gray-900 text-white w-fit">
                        <p>Chat screen</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
            
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild> 
                        <button onClick={handleClearChat} data-no-drag>
                            <RotateCcw className="h-4 w-4 hover:text-[#8c8c8c]"/>
                        </button>
                    </TooltipTrigger>
                    <TooltipContent className="bg-gray-900 text-white w-fit">
                        <p>Clear chat</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>

            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild> 
                        <button onClick={toggleChat} data-no-drag>
                            <FaAngleDown className="h-5 w-4 hover:text-[#8c8c8c]" />
                        </button>
                    </TooltipTrigger>
                    <TooltipContent className="bg-gray-900 text-white w-fit">
                        <p>Minimze webchat</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </div>
    </div>
  )
}

export default WebchatHeader