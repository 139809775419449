/* eslint-disable max-lines */
import { useContext, useEffect, useState } from "react"
import { Button } from "components/ui/button"
import { Input } from "components/ui/input"
import { Label } from "components/ui/label"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "components/ui/card"
import { Eye, EyeOff } from 'lucide-react'
import { secretKeysService } from "api"
import { useParams } from "react-router-dom"
import { AppLoader } from "components/LoaderSpinner"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select"
import { Context } from "context/GlobalState"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "components/ui/tooltip"
import { OrganizationContext } from "context/OrganizationContext"

export default function RedisKeys() {
    const DB_TYPES = ['common', 'dedicated', 'custom']

    const [redisKeys, setRedisKeys] = useState({
        databaseType: '',
        host: "",
        port: "",
        password: "",
    })
    const [dedicatedRedisKeys, setDedicatedRedisKeys] = useState({
        host: "",
        port: "",
        password: "",
    })
    const [hasDedicated, setHasDedicated] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [loading, setLoading] = useState(true)

    const { oragID } = useParams()
    const { addNewNotifcation } = useContext(Context)
    const { selectedOrganization } = useContext(OrganizationContext)

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setRedisKeys((prev) => ({ ...prev, [name]: value }))
    }

    const handleSelectChange = (name, value) => {
        if (value === 'custom') {
            setRedisKeys({
                [name]: value,
                host: "",
                port: "",
                password: "",
            })
        } else if (value === 'dedicated') {
            if (hasDedicated) {
                setRedisKeys({
                    [name]: value,
                    host: dedicatedRedisKeys.host,
                    port: dedicatedRedisKeys.port,
                    password: dedicatedRedisKeys.password,
                })
            } else {
                setRedisKeys({
                    [name]: value,
                    host: "",
                    port: "",
                    password: "",
                })
            }

        } else {
            setRedisKeys({ [name]: value, host: "******", port: "******", password: "******" })
        }
    }

    const handleUpgrade = async () => {
        if (selectedOrganization.userRole !== 'superAdmin') {
            return addNewNotifcation('Only super admin can update redis keys', 'warning')
        } 

        if (redisKeys.databaseType === 'custom' && (!redisKeys.host || !redisKeys.password || !redisKeys.port)) {
            return addNewNotifcation('Please fill in all keys', 'warning')
        }

        setIsLoading(true)

        const secretObj = {
            organizationId: oragID,
            secretKeys: {
                redisKeys: redisKeys
            }
        }

        secretKeysService.updateRedisSecretKeys(secretObj)
        .then((res) => {
            setHasDedicated(res.data.hasDedicated)
            setDedicatedRedisKeys(res.data.dedicatedRedisKeys)
            setRedisKeys(res.data.redisKeys)

            addNewNotifcation('Redis keys updated successfully', 'success')
        }).catch((error) => {
            console.log('error: ' ,error);
            if (error?.response?.status === 403) {
                addNewNotifcation('This organization does not have any valid subscription', 'danger')
            } else if (error?.response?.status === 400) {
                addNewNotifcation(error?.response.data.error || 'Something went wrong', 'danger')
            } else {
                addNewNotifcation('Something went wrong. Please try again', 'danger')
            }
        }).finally(() => {
            setIsLoading(false)
        })
    }


    useEffect(() => {
        secretKeysService.listRedisSecretKeys(oragID)
        .then((res) => {
            setHasDedicated(res.data.hasDedicated)
            setDedicatedRedisKeys(res.data.dedicatedRedisKeys)
            setRedisKeys(res.data.redisKeys)
        }).catch(error => {
            console.log('error: ', error);
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    if (loading) {
        return <div className='flex flex-grow flex-col justify-center items-center'> <AppLoader size={50} /> </div>;
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle>Redis Keys</CardTitle>
                <CardDescription>
                    Mange your Redis connection common and dedicated database.
                </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
                <div className="flex flex-col space-y-3">
                    <div className="space-y-3">
                        <div className="space-y-2">

                            <Label htmlFor={'db-type'}> Redis Store Type </Label>

                            <Select disabled={selectedOrganization.userRole !== 'superAdmin'} onValueChange={(value) => handleSelectChange('databaseType', value)} id="db-type" defaultValue={redisKeys.databaseType}>
                                <SelectTrigger className="w-full capitalize">
                                    <SelectValue className="capitalize" placeholder="Select redis database type" />
                                </SelectTrigger>
                                <SelectContent>
                                    {DB_TYPES.map(item => (
                                        <SelectItem className='capitalize' key={item} value={item}>{item} {item === 'dedicated' && <small className="text-muted-foreground">subscription required</small>} </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>


                        {redisKeys.databaseType === "dedicated" && !hasDedicated && (
                            <div className="w-full mt-2 p-2 rounded border flex flex-col gap-1">
                                <h2 className="font-bold text-[16px]">Automatic Key Generation</h2>
                                <span className="text-muted-foreground text-sm">
                                    We'll create a new dedicated Redis database for you and provide the connection details automatically.
                                </span>
                            </div>
                        )}
                    </div>
                    {/* {redisKeys.databaseType.} */}
                    <div className="space-y-2">
                        <Label htmlFor="host">Host</Label>
                        <SecretInputRender disabled={redisKeys.databaseType !== 'custom'} onChange={handleInputChange} label='host' customValue={redisKeys.host} />
                    </div>
                    <div className="space-y-2">
                        <Label htmlFor="port">Port</Label>
                        <SecretInputRender disabled={redisKeys.databaseType !== 'custom'} onChange={handleInputChange} label='port' customValue={redisKeys.port} />
                    </div>
                    <div className="space-y-2">
                        <Label htmlFor="password">Password</Label>
                        <SecretInputRender disabled={redisKeys.databaseType !== 'custom'} onChange={handleInputChange} label='password' customValue={redisKeys.password} />
                    </div>
                </div>
            </CardContent>
            <CardFooter className="flex flex-col items-start space-y-4">
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <Button
                                onClick={handleUpgrade}
                                disabled={isLoading}
                                className="w-full"
                            >
                                {redisKeys.databaseType === "dedicated" ? 'Generate Keys' : 'Save Configuration'}
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent className="bg-gray-900 text-white">
                            {selectedOrganization.userRole !== 'superAdmin' && (
                                'Only super admin can update redis keys'
                            )}
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            </CardFooter>
        </Card>
    )
}

export const SecretInputRender = ({ disabled, label, secretKey, customValue, onChange, isPassword = true }) => {

    const [showPasswords, setShowPasswords] = useState(false)
    
    return (
        <div className="space-y-2">
            <Label htmlFor={label}>{secretKey}</Label>
            <div className="relative">
                <Input
                name={label}
                type={isPassword && !showPasswords ? "password" : "text"}
                id={label}
                value={customValue}
                onChange={onChange}
                className="w-full pr-10"
                disabled={disabled}
                />
                {isPassword && (
                    <button
                        type="button"
                        onClick={() => setShowPasswords(!showPasswords)}
                        className="absolute inset-y-0 right-0 flex items-center pr-3"
                    >
                        {showPasswords ? <EyeOff className="h-4 w-4 text-gray-400" /> : <Eye className="h-4 w-4 text-gray-400" />}
                    </button>
                )}
            </div>
        </div>
    )
}