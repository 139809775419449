import React, { useEffect, useRef, useState } from 'react'
import { io } from "socket.io-client";
import WebchatHeader from './WebchatHeader';
import WebchatBody from './WebchatBody';
import WebchatFooter from './WebchatFooter';
import { MessageSquare } from 'lucide-react';

const WebchatScreen = ({chatSettings, emailUpdateObject, dragObj}) => {
    const [showChat, setShowChat] = useState(true);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [conversationHistory, setConversationHistory] = useState('History:\n')
    const [isHome, setIsHome] = useState(true)
    const socket = useRef(null);
    const userId = useRef(null);
    const conversationId = useRef(null);    
    const base_url = window.location.origin;
    const {primaryColor, textPrimaryColor, assistantName, chatWidth} = chatSettings
    const {startDrag, isDragging, position, isOverLeft} = dragObj
    const {address: widgetId, includeConvoHistory,} = emailUpdateObject
    const iconStyle = { accentColor:emailUpdateObject?.webChatConfig?.accentColor || 'black',
        textAccentColor:emailUpdateObject?.webChatConfig?.textAccentColor || 'white'}
      
    useEffect(() => {
       if (!userId.current) {
            userId.current = localStorage.getItem("user_id") || `user-${Math.random().toString(36).substr(2, 9)}`;
            localStorage.setItem("user_id", userId.current);
        }

        if (!conversationId.current) {
            conversationId.current =
                sessionStorage.getItem("conversation_id") ||
                `tab-${userId.current}-${Date.now().toString(36)}-${Math.random().toString(36).substr(2)}`;
            sessionStorage.setItem("conversation_id", conversationId.current);
        }

        socket.current = io("https://feo.coolriots.ai/webchatv2", {
            path: "/feo-v3/api/socketio",
            transports: ["websocket"],
        });


        socket.current.on("bot-response", (data) => {
            // console.log("bot-response: ", data)
            if (data.conversation_id !== conversationId.current) return;
            handleStoreConvoHistory("bot_response", data.bot_response)
            setBotConvoMessage(data.bot_response)
        });

        socket.current.on("transcribed-text", (data) => {
            // console.log(data)
            if (data.conversation_id !== conversationId.current) return;
            handleStoreConvoHistory("user", data.text)
            setUserConvoMessage(data.text)
        });

        socket.current.on("error", (data) => {
            // console.log(data)
            setBotConvoMessage(data.error)
        });

        return () => {
            socket.current.disconnect();
        };
    }, []);


    const toggleChat = (e) => {
        e.stopPropagation()
        setShowChat((prev) => !prev);
    };

    console.log(conversationHistory)
    const emitMessage = (message = null, isAudio = false) => {
        if(message) {
            if(isAudio) {
                const convoHistory = includeConvoHistory? conversationHistory : ''
                // console.log(convoHistory)
                socket.current.emit('voice', message, widgetId, convoHistory, conversationId.current)
            } else {            
                setUserConvoMessage(message)
                const newUserMessage = includeConvoHistory? `${conversationHistory}user: ${message}` : message
                handleStoreConvoHistory("User", message)
                // console.log(newUserMessage)

                socket.current.emit("message", {
                    widgetId,
                    message:newUserMessage,
                    user_id: userId.current,
                    conversation_id: conversationId.current,
                    base_url: base_url,
                });
            }
        }
    }

    const setUserConvoMessage = (message) => {
        const newMessage = { text: message, type: "user"};
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setIsHome(false)
        setLoading(true);
    }

    const setBotConvoMessage = (message) => {
        setMessages((prevMessages) => {
            if (prevMessages.length > 0) {
                return [...prevMessages, { text: message, type:'bot' }];
            }
            return prevMessages;
        });
        setLoading(false);
    }

    const handleStoreConvoHistory = (sender, message) => {
        if(includeConvoHistory){
            const convoMessage = `${sender}: ${message}\n`;
            setConversationHistory((prevHistory) => prevHistory + convoMessage);
        }
    }

    const handleClearChat = () => {
        setLoading(false)
        setMessages([])
        if(includeConvoHistory) setConversationHistory('History:\n')

        conversationId.current = `tab-${userId.current}-${Date.now().toString(36)}-${Math.random().toString(36).substr(2)}`;
        sessionStorage.setItem("conversation_id", conversationId.current);
    } 

return (
    <>
        <button 
        className={`fixed bottom-4 ${isOverLeft? 'left-4' : 'right-4'} rounded-full 
        shadow-lg size-16 flex justify-center items-center ${ showChat ? "hidden" : "flex"}`}
        onClick={toggleChat}
        style={{ 
            backgroundColor: iconStyle.accentColor, 
            color:iconStyle.textAccentColor,  
        }}
        >
            <MessageSquare className="h-6 w-6" />
        </button>

        <div 
        className={`fixed bottom-4 shadow-xl rounded-lg z-[10000] border border-[#ccc]
            transform duration-300 ease-in-out
            ${isDragging ? "transition-none" : "transition-[transform,right,opacity]"}
            ${showChat ? "translate-y-0 opacity-100" : "translate-y-full opacity-0"}
        `}
        onMouseDown={startDrag}
        style={{ 
            right: `${position.right}px` ,
            width: `${chatWidth}px`,
            backgroundColor: isHome? primaryColor : "white",
            color: isHome? textPrimaryColor : "black",
        }}
        >
            <WebchatHeader
            assistantName={assistantName}
            handleClearChat={handleClearChat}
            setIsHome={setIsHome}
            toggleChat={toggleChat}
            />
            <WebchatBody
            emitMessage={emitMessage}
            messages={messages}
            isHome={isHome}
            loading={loading}
            chatSettings={chatSettings}
            />
            <WebchatFooter
            loading={loading}
            emitMessage={emitMessage}
            socket={socket.current}
            convoHistory = {includeConvoHistory? conversationHistory : ''}
            />        
        </div>
    </>
    )
}

export default WebchatScreen