import React, { useContext, useEffect, useRef } from 'react'
import { User, Bot } from 'lucide-react'
import { TypingLoader } from 'components/TypingLoader';
import { Context } from 'context/GlobalState';
import MessageParser from './MessageParser';

const WebchatBody = ({messages, loading, emitMessage, chatSettings, isHome}) => {
    const chatBody = useRef(null)
    const {addNewNotifcation} = useContext(Context)
    const {chatGreetingMessage, secondaryColor, textSecondaryColor, chatHeight, conversationStarters} = chatSettings
    const messageStyle = 'max-w-[75%] px-3 py-2 rounded-lg text-sm leading-[1.6]';
    const iconsStyle = 'inline-flex items-center justify-center w-[35px] h-[35px] rounded-full text-lg';
    const userStyle = 'bg-black text-white'
    const chatbotStyle = 'bg-[whitesmoke] text-black'

    useEffect(() => {
        chatBody?.current?.scrollTo({top:chatBody?.current?.scrollHeight, behavior:"smooth"})
    }, [messages, isHome])

    const handleStarterClick = (starter) => {
        if(loading) {
            addNewNotifcation('Please wait untill the response comes.', 'warning');
            return ;
        }

        emitMessage(starter)
    }

return (
    <div className="overflow-y-auto p-4" style={{height: `${chatHeight}px`}} ref={chatBody} data-no-drag>
        {isHome ? (
            <div>
                <p className="text-2xl font-light mb-6">{chatGreetingMessage}</p>
                <div className="space-y-3">
                    {conversationStarters.map((starter, index) => (
                        <button 
                        key={index}
                        className="w-full text-left p-2 rounded-full transition-colors"
                        style={{ 
                            backgroundColor: secondaryColor,
                            color: textSecondaryColor,
                        }}
                        onClick={() => handleStarterClick(starter)}
                        >
                            {starter}
                        </button>
                    ))}
                </div>
            </div>
        ): (
            <>
                {messages.map((msg, index) => (
                    <div
                    key={index}
                    className={`flex my-3 gap-2 ${
                        msg.type === "user" ? "justify-end" : "justify-start"
                    }`}
                    >
                        <span
                            className={`${iconsStyle} ${msg.type === "user"? userStyle : chatbotStyle}`}
                        >
                            {msg.type === "user" ? <User strokeWidth={1.5} /> : <Bot strokeWidth={1.5} />}
                        </span>
                        {/* {msg.isAudio? (
                            <div 
                            className={`${messageStyle} w-full ${msg.type === "user"? userStyle : chatbotStyle}`} 
                            >
                                <audio controls src={msg.text} className="w-full p-1 bg-black">
                                    Your browser does not support the audio element.
                                </audio>
                            </div>
                        ) : ( */}
                        <div
                        className={`${messageStyle} overflow-hidden 
                        ${msg.type === "user"? userStyle : chatbotStyle}`}
                        >
                            <MessageParser text={msg.text}/>
                        </div>
                        {/* )} */}
                    </div>
                ))}
                {loading && (
                <div className="flex my-2 justify-start gap-2">
                    <span className={`${iconsStyle} ${chatbotStyle}`} >
                        <Bot strokeWidth={1.5} />
                    </span>
                    <TypingLoader>
                        <div></div>
                        <div></div>
                        <div></div>
                    </TypingLoader>
                </div>
                )}
            </>
        )}
    </div>
  )
}

export default WebchatBody