import React, { useEffect, useContext, useState, useRef} from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardFooter, CardDescription } from "components/ui/card";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import * as ShadeDD from "components/ui/dropdown-menu";
import { SendHorizontal, RotateCcw, Copy } from 'lucide-react';
import { Checkbox } from "components/ui/checkbox";
import { DocumentList } from './DocumentList';
import { GlobalContext } from 'pages/PromptingAndRagTraining/context/LivePromptContext';
import { Context } from 'context/GlobalState';
import { ragService } from 'api/services/PROJECT-O';
import labHelper from 'pages/PromptingAndRagTraining/classes/AILabHelper';
import { OrganizationContext } from 'context/OrganizationContext';

export function RAG() {
    const [input, setInput] = useState('');
    const [selectedMessages, setSelectedMessages] = useState([]);
    const { addNewNotifcation } = useContext(Context);
    const {
        setMessages,
        setMessageLoading,
        selectedDocuments
    } = useContext(GlobalContext);
    const { selectedOrganization } = useContext(OrganizationContext);

    const handleSend = async () => {
        if (input.trim() === '') return;  // Prevent sending empty messages
        

        if (selectedDocuments.length === 0){
            addNewNotifcation("Please select a document to query.", "danger");
            return;
        }

        const newMessage = { role: 'user', msg: input, _id: Date.now() };
        setMessages(prevMessages => [...prevMessages, newMessage]);
        setInput('');

        
        try {
            setMessageLoading(true);
            let rag = "";
            const totalK = 15;
            const numberOfDocs = selectedDocuments.length;
            const baseK = Math.floor(totalK / numberOfDocs);
            const remainderK = totalK % numberOfDocs;
            for (let i = 0; i < numberOfDocs; i++) {
                const k = baseK + (i < remainderK ? 1 : 0);
                const formData = new FormData();
                formData.append('document_id', selectedDocuments[i]._id);
                formData.append('query', input);
                formData.append('organization_id',  selectedOrganization._id);
                formData.append('sub_organization_id', process.env.REACT_APP_DEMO_MODE);
                formData.append('k', k);
             
                const response = await ragService.queryData(formData);
                // // console.log(response);
                rag += labHelper.constructRetrievedResults(response.data.data.documents);
            }
            console.log(rag);
            const ragMessage = rag ? { role: 'rag', msg: rag.trim(), _id: Date.now() } : { role: 'system', msg: "I'm sorry that information is not found in the document.", _id: Date.now() };
            setMessages(prevMessages => [...prevMessages, ragMessage]);
 
        } catch (error) {
            console.error(error);
            setMessages(prevMessages => [
                ...prevMessages,
                { role: 'rag', msg: "An error occurred while fetching the information.", _id: Date.now() }
            ]);
        } finally {
            setMessageLoading(false);
        }
            

    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          handleSend();
        }
    };

    const handleResetRAG = () => {
        setMessages([{role: "system", msg: "Hi. Please select a document to start querying.", _id: Date.now()}]);
        setSelectedMessages([]);
    };

    return (
        <Card className="w-full flex flex-col justify-between h-fit min-h-[436px] max-h-[436px]">
            
            <CardHeader className="max-h-[76px] border-b-[1px] border-gray-200 px-6 py-3.5 grid grid-cols-2 gap-4 select-none">
                <div className="grid grid-cols-1 gap-1.5">
                    <CardTitle className="text-sm">RAG</CardTitle>
                    <CardDescription className="text-xs">Retrieve information from your documents.</CardDescription>
                </div>
                <div className="flex flex-row items-center gap-2 justify-end">
                    <DocumentList/>
                </div>
            </CardHeader>

            <CardContent className="flex flex-col justify-end gap-4 p-0 flex-grow">
                <Messages selectedMessages={selectedMessages} setSelectedMessages={setSelectedMessages}/>
            </CardContent>

            <CardFooter className=" h-16 max-h-16 border-t-[1px] border-gray-200 px-6 py-4 flex flex-row flex-nowrap gap-2">
                <Input
                    value={input}
                    onChange={(e)=>{setInput(e.target.value)}}
                    onKeyDown={handleKeyDown}
                    type="text"
                    placeholder="Send a query..."
                    className="col-span-1 h-10"
                />
                <Button variant="ghost" size="icon" className="px-2" onClick={handleSend}>
                    <SendHorizontal className="w-5 h-5" />
                </Button>
                <CopyDemo selectedMessages={selectedMessages}/>
                <Button variant="ghost" size="icon" className="px-2" onClick={handleResetRAG}>
                    <RotateCcw className="w-5 h-5" />
                </Button>
            </CardFooter>
        </Card>
    )
}

const Messages = ({selectedMessages, setSelectedMessages}) => {
    // const [selectedMessages, setSelectedMessages] = useState([]);
    const endOfMessagesRef = useRef(null);
    const containerRef = useRef(null);
    const {
        messages,
        messageLoading,
    } = useContext(GlobalContext);

    const handleCheckboxChange = (e, msg) => {
        setSelectedMessages(prevSelectedMessages => {
            if (prevSelectedMessages.includes(msg)) {
                return prevSelectedMessages.filter(m => m !== msg);
            } else {
                return [...prevSelectedMessages, msg];
            }
        });
    };


    useEffect(() => {
        if (endOfMessagesRef.current) {
            // endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <div className="max-h-[296px] px-6 overflow-auto" ref={containerRef}>
            {
                messages.map((chat) => (
                    <div key={chat._id} className={`flex mb-4 mt-4 gap-2 items-start ${chat.role === "user" ? 'justify-end' : ''}`}>
                        {chat.role === "rag" && <Checkbox className="mt-2" onCheckedChange={(e) => handleCheckboxChange(e, chat.msg)} checked={selectedMessages.includes(chat.msg)} />}
                        <div className={`${chat.role === "user" ? "bg-black text-white" : "bg-gray-100 text-gray-900"} rounded-lg p-3 max-w-[75%]`}>
                            <p className="text-xs break-words whitespace-pre-line">
                                {chat.msg}
                            </p>
                        </div>
                    </div>
                ))
            }
            {messageLoading && 
                <div className="italic text-gray-400 text-sm mb-4">
                    <span>Retrieving information...</span>
                </div>
            }
            <div ref={endOfMessagesRef} />
        </div>
    );
};

export function CopyDemo({selectedMessages}) {
    const {
        inputData, setInputData,
        context, setContext,
    } = useContext(GlobalContext);

    const { addNewNotifcation } = useContext(Context);

    const dataSelected = () => {
        console.log(selectedMessages)
        if (selectedMessages.length > 0)
            return true;
        else
            return false;
    };

    const handleCopyToContext = () => {
        if (!dataSelected()){
            addNewNotifcation('Please select a queried information first.', 'danger');
            return;
        }

        const newMessages = selectedMessages.filter(msg => !context.includes(msg));
        if (newMessages.length > 0) {
            setContext(context ? context + '\n\n' + newMessages.join('\n\n') : newMessages.join('\n\n'));
        }
        addNewNotifcation('Copied to context successfully', 'success');
    }
    
    const handleCopyToInput = () => {
        if (!dataSelected()){
            addNewNotifcation('Please select a queried information first.', 'danger');
            return;
        }

        const newMessages = selectedMessages.filter(msg => !inputData.includes(msg));
        if (newMessages.length > 0) {
            setInputData(inputData ? inputData + '\n\n' + newMessages.join('\n\n') : newMessages.join('\n\n'));
        }
        addNewNotifcation('Copied to input data successfully', 'success');
    }
    
    return (
        <ShadeDD.DropdownMenu>
            <ShadeDD.DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon" className="">
                    <Copy className="w-5 h-5" />
                </Button>
            </ShadeDD.DropdownMenuTrigger>
            <ShadeDD.DropdownMenuContent className="w-56" align="end">
                <ShadeDD.DropdownMenuLabel>Copy Selected Data To</ShadeDD.DropdownMenuLabel>
                <ShadeDD.DropdownMenuSeparator />
                <ShadeDD.DropdownMenuGroup>
                    <ShadeDD.DropdownMenuItem onClick={handleCopyToContext}>
                        <span>Context</span>
                    </ShadeDD.DropdownMenuItem>
                    <ShadeDD.DropdownMenuItem onClick={handleCopyToInput}>
                        <span>Input Data</span>
                    </ShadeDD.DropdownMenuItem>
                </ShadeDD.DropdownMenuGroup>
            </ShadeDD.DropdownMenuContent>
        </ShadeDD.DropdownMenu>
    )
}