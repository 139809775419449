import React, { useContext, useRef, useState } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "components/ui/dialog"
import { Context } from "context/GlobalState"
import { channelService } from "api"
import { NewChannelInput } from './WebChatSetup'
import { ScrollArea } from 'components/ui/scroll-area'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'
import AppearanceAndLauncher from './WebchatConfig/AppearanceAndLauncher'
import HomeScreen from './WebchatConfig/HomeScreen'


export default function UpdateWebChannelWrapper({ isOpen, setOpenEmailModal, emailUpdateObject, setEmailChannels, setEmailUpdateObject }) {
    const [loading, setLoading] = useState(false)
    const { addNewNotifcation } = useContext(Context)
    const modalRef = useRef(null)

    const handleSave = async (webChatConfig, closeModal) => {
        const channelObj1 = {
            organizationId: emailUpdateObject.organizationId,
            subOrganizationId: emailUpdateObject.subOrganizationId,
            channelId: emailUpdateObject._id,
            topic: emailUpdateObject.topic,
            address: emailUpdateObject.address,
            origins: emailUpdateObject.origins,
            assistantId: emailUpdateObject?.assistantId === 'none' ? '' : emailUpdateObject?.assistantId,
            webChatConfig,
        }

        try {
            setLoading(true)
            const res = await channelService.updateChannel(channelObj1)
            console.log('res: ', res);
            setEmailChannels(prev => {
                return prev.map((item) => {
                    if (item._id === emailUpdateObject._id){
                        return {...res.data, ...channelObj1}
                    } else {
                        return {...item}
                    }
                })
            })
            setEmailUpdateObject({...emailUpdateObject, ...channelObj1})
            addNewNotifcation('Channel Updated successfully', 'success')
            closeModal? setOpenEmailModal(false) : ""
        } catch(error) {
            console.log('error: ', error);
            if (error.response?.status === 404) {  
                addNewNotifcation('Channel not found', 'danger')
            } else {
                addNewNotifcation('Something went wrong', 'danger')
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <Dialog open={isOpen} onOpenChange={setOpenEmailModal}>
            <DialogContent className="p-0 sm:max-w-[1200px] h-[90vh]" ref={modalRef}>
                <ScrollArea className='p-4 relative h-full'>
                    <DialogHeader>
                        <DialogTitle>Update Web Channel</DialogTitle>
                    </DialogHeader>
                    <Tabs defaultValue="general" className="space-y-4 mt-4">
                        <TabsList className='w-full'>
                            <TabsTrigger className='w-full' value="general">General</TabsTrigger>
                            <TabsTrigger className='w-full' value="Appearance & Launcher">Appearance & Launcher</TabsTrigger>
                            <TabsTrigger className='w-full' value="Home Screen">Home Screen</TabsTrigger>
                        </TabsList>
                        <TabsContent value="general">
                            <WebChannelUpdateDialog setOpenEmailModal={setOpenEmailModal} emailUpdateObject={emailUpdateObject} setEmailChannels={setEmailChannels} setEmailUpdateObject={setEmailUpdateObject} />
                        </TabsContent>
                        <TabsContent value="Appearance & Launcher">
                            <AppearanceAndLauncher handleSave={handleSave} loading={loading} emailUpdateObject={emailUpdateObject}/>
                        </TabsContent>
                        <TabsContent value="Home Screen">
                            <HomeScreen handleSave={handleSave} loading={loading} emailUpdateObject={emailUpdateObject} modalRef={modalRef}/>
                        </TabsContent>
                    </Tabs>
                </ScrollArea>
            </DialogContent>
        </Dialog>

    )
}

const WebChannelUpdateDialog = ({ setOpenEmailModal, emailUpdateObject, setEmailChannels, setEmailUpdateObject }) => {

    const { addNewNotifcation } = useContext(Context)

    const handleOnSubmit = async (channelObj) => {

        const channelObj1 = {
            organizationId: channelObj.organizationId,
            subOrganizationId: channelObj.subOrganizationId,
            channelId: channelObj._id,
            welcomeMessage: '',
            homePageMeaage: '',
            topic: channelObj.topic,
            address: channelObj.address,
            origins: channelObj.origins,
            assistantId: channelObj?.assistantId === 'none' ? '' : channelObj?.assistantId,
            includeConvoHistory:channelObj.includeConvoHistory
        }

        try {
            const res = await channelService.updateChannel(channelObj1)
            console.log('res: ', res);
            setEmailChannels(prev => {
                return prev.map((item) => {
                    if (item._id === emailUpdateObject._id){
                        return {...channelObj}
                    } else {
                        return {...item}
                    }
                })
            })
            setEmailUpdateObject({...emailUpdateObject, ...channelObj})
            addNewNotifcation('Channel Updated successfully', 'success')
        } catch(error) {
            console.log('error: ', error);
            if (error.response?.status === 404) {  
                addNewNotifcation('Channel not found', 'danger')
            } else {
                addNewNotifcation('Something went wrong', 'danger')
            }
        }
    }

    if (!emailUpdateObject) return 

    return (
        <NewChannelInput
        newChannelObj={emailUpdateObject}
        isUpdate={true}
        channelType={'web-chat'} setOpenEmailModal={setOpenEmailModal} 
        onSubmit={handleOnSubmit} />
    )
}
