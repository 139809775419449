/* eslint-disable max-lines */
import React, { useContext, useState } from 'react'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "components/ui/card"
import { Cog, MoreVertical, Trash2 } from "lucide-react"
import { Button } from "components/ui/button"
import { useNavigate, useParams } from 'react-router-dom'
import UpdateCollectionDialog from './UpdateCollection'
import { Context } from 'context/GlobalState'
import { Label } from 'components/ui/label'
import { Input } from 'components/ui/input'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from 'components/ui/dialog'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from 'components/ui/dropdown-menu'
import AlertModal from 'components/Alertmodal'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "components/ui/tooltip";
import { redisCollectionService } from 'api'


export default function Collection({ collections, actions, isMilvusActivated }) {

    const [openModal, setOpenModal] = useState(false)

    return (
        <div className='w-full'>
            <div className='h-auto py-8'>
                <Card>
                    <CardHeader>
                        <div className='flex justify-between'>
                            <div>
                                <CardTitle>Document Collections</CardTitle>
                                <CardDescription>Manage your document collections and permissions</CardDescription>
                            </div>
                            <dir>
                                <Button onClick={() => setOpenModal(true)}> Create New Collection </Button>
                            </dir>
                        </div>

                    </CardHeader>
                    <CardContent>
                        <div className="space-y-4">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                {collections.map((item, index) => (
                                    <CollectionCard isMilvusActivated={isMilvusActivated} actions={actions} collections={collections} {...item} index={index} key={index} />
                                ))}
                            </div>
                        </div>
                    </CardContent>

                    {/* create collection modal */}
                    <CreateNewCollectionDialog isMilvusActivated={isMilvusActivated} actions={actions} openModal={openModal} setOpenModal={setOpenModal} />
                </Card>
            </div>
        </div>
    )
}

function CollectionCard({ actions, collections, isMilvusActivated, name, _id }) {

    const [show, setShow] = useState(false)

    const [delModal, setDelModal] = useState(false)
    const [delBtn, setDelBtn] = useState(false)

    const navigate = useNavigate()
    const { addNewNotifcation } = useContext(Context)
    const { oragID, subOragID } = useParams()

    const handleDeleteCollection = () => {
        setDelBtn(true)

        const delObj = {
            // projectId: project._id,
            organizationId: oragID,
            subOrganizationId: subOragID,
            collectionId: _id,
        }

        redisCollectionService.deleteCollection(delObj)
        .then((res) => {
            console.log('res: ', res);
            addNewNotifcation('Collection deleted successfully', 'success')
            actions({type: 'SET_COLLECTIONS', payload: (collections.filter(collection => collection._id !== _id))})
        }).catch((error) => {
            console.log('error: ', error);
            addNewNotifcation('Something went wrong', 'danger')
        }).finally(() => {
            setDelBtn(false)
            setDelModal(false)
        })

    }

    return (
        <Card>
            <CardHeader>
                <div className='flex w-full justify-between items-center'>
                    <CardTitle onClick={() => navigate(`${_id}/documents`)} className='cursor-pointer'>{name}</CardTitle>
                    <DropdownMenu modal={false}>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                {/* <span className="sr-only">Open menu</span> */}
                                <MoreVertical className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel>Actions</DropdownMenuLabel>
                            <DropdownMenuItem onClick={() => setShow(true)}>
                                <Cog className="mr-2 h-4 w-4" />
                                <span>Manage</span>
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem onClick={() => setDelModal(true)} className="text-red-600">
                                <Trash2 className="mr-2 h-4 w-4" />
                                <span>Delete</span>
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </CardHeader>
            <CardContent></CardContent>

            {/* update collection dialog */}
            <UpdateCollectionDialog isMilvusActivated={isMilvusActivated} collections={collections} actions={actions} collectionId={_id} show={show} setShow={setShow} />
            
            {/* delete collection modal */}
            <AlertModal loading={delBtn} openModal={delModal} setopenModal={setDelModal} onDelete={handleDeleteCollection} />
        </Card>
    )
}

const CreateNewCollectionDialog = ({ actions, openModal, isMilvusActivated, setOpenModal }) => {


    const SEARCH_TYPES = ['jina', 'openAI']
    const VECTOR_STORE_TYPES = ['MongoDB', 'Milvus']
    const DIMENSIONS_TYPES = [1024, 128]

    const [btnLoading, setBtnLoading] = useState(false)
    const [collectionName, setCollectionName] = useState('')
    
    const [embeddingModel, setEmbeddingModel] = useState("jina");
    const [dimensions, setDimensions] = useState(128);
    const [chunkingStrategy, setChunkingStrategy] = useState("");
    const [vectorStoreType, setVectorStoreType] = useState("MongoDB");

    const { oragID, subOragID  } = useParams()
    const { addNewNotifcation } = useContext(Context)

    const handleCreateCollection = () => {
        // if (!collections) {
        //     return addNewNotifcation('To create collections, please subscribe to a plan.')
        // }

        setBtnLoading(true)
        const collectionObj = {
            name: collectionName,
            organizationId: oragID,
            subOrganizationId: subOragID,
            dimensions: dimensions,
            vectorStoreType: vectorStoreType,
            chunkingStrategy: chunkingStrategy,
            embeddingModel: embeddingModel,
        }

        redisCollectionService.addCollection(collectionObj)
        .then((res) => {
            console.log('res: ', res);
            addNewNotifcation('Collection created successfully', 'success')
            actions({type: 'SET_COLLECTIONS', payload: (collections => [...collections, res.data])})

            // reset values
            setCollectionName('')
            setEmbeddingModel('jina')
            setDimensions(128)
            setChunkingStrategy("")
            setVectorStoreType('MongoDB')

            setOpenModal(false)
        }).catch((error) => {
            console.log('error: ', error);
            if (error.response?.status === 403) {
                addNewNotifcation('Resource limit reached. You cannot create more collections under your current plan', 'danger')
            } else if (error.response?.status === 409) { 
                addNewNotifcation(`Collection with ${collectionName} already created`, 'danger')
            } else {
                addNewNotifcation(`Something went wrong. Please try again`, 'danger')
            }
        }).finally(() => {
            setBtnLoading(false)
        })
    }

    const checkStoreTypeDisabled = (item) => {
        if (item === 'MongoDB') return false
        
        if (!isMilvusActivated) return true
    }

    
    return (
        <Dialog open={openModal} onOpenChange={setOpenModal}>
            <DialogContent className="w-full max-w-lg p-8 bg-white rounded-lg shadow-lg">
                <DialogHeader>
                    <DialogTitle className="text-2xl font-semibold">Create New Collection</DialogTitle>
                    <DialogDescription className="text-md text-muted-foreground">Set up a new collection.</DialogDescription>
                </DialogHeader>

                <div className="space-y-8 pt-4">
                    <div className="space-y-3">
                        <div>
                            <Label htmlFor='collection-name'> Name </Label>
                            <Input type='text' id='collection-name' value={collectionName} onChange={(e) => setCollectionName(e.target.value)} />
                        </div>
                        <div>
                            <Label htmlFor='dimensions'> Dimensions </Label>
                            <Select onValueChange={(value) => setDimensions(value)} id="dimensions" defaultValue={dimensions}>
                                <SelectTrigger className="w-full capitalize">
                                    <SelectValue className="capitalize" placeholder="Select collection dimensions" />
                                </SelectTrigger>
                                <SelectContent>
                                    {DIMENSIONS_TYPES.map(item => (
                                        <SelectItem className='capitalize' key={item} value={item}>{item} </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                        <div>
                            <Label htmlFor='chunking-strategy'> Chunking Strategy </Label>
                            <Input id='chunking-strategy' value={chunkingStrategy} onChange={(e) => setChunkingStrategy(e.target.value)} />
                        </div>
                        <div>
                            <Label htmlFor='vector-store-type'> Vector store type </Label>
                            <Select onValueChange={(value) => setVectorStoreType(value)} id="vector-store-type" defaultValue={vectorStoreType}>
                                <SelectTrigger className="w-full capitalize">
                                    <SelectValue className="capitalize" placeholder="Select vector store type" />
                                </SelectTrigger>
                                <SelectContent>
                                    {VECTOR_STORE_TYPES.map(item => (
                                        <TooltipProvider key={item}>
                                            <Tooltip className='z-[1000]'>
                                                <TooltipTrigger asChild>
                                                    <div>
                                                        <SelectItem disabled={checkStoreTypeDisabled(item)} className='capitalize'  value={item}>{item} </SelectItem>
                                                    </div>
                                                </TooltipTrigger>
                                                {checkStoreTypeDisabled(item) && (
                                                    <TooltipContent className="bg-gray-900 text-white w-fit z-[1000]">
                                                        <p>Milvus is not activated yet</p>
                                                    </TooltipContent>
                                                )}
                                            </Tooltip>
                                        </TooltipProvider>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                        <div>
                            <Label htmlFor='embedding-model'> Embedding Model </Label>
                            <Select onValueChange={(value) => setEmbeddingModel(value)} id="embedding-model" defaultValue={embeddingModel}>
                                <SelectTrigger className="w-full capitalize">
                                    <SelectValue className="capitalize" placeholder="Select vector search type" />
                                </SelectTrigger>
                                <SelectContent>
                                    {SEARCH_TYPES.map(item => (
                                        <SelectItem className='capitalize' key={item} value={item}>{item} </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                </div>

                {/* <div className='flex w-full my-4'>
                    <div className='flex flex-col w-full gap-2'>
                        <Label htmlFor='collection-name'> Collection Name </Label>
                        <Input id='collection-name' value={collectionName} onChange={(e) => setCollectionName(e.target.value)} />
                    </div>
                </div> */}

                <DialogFooter className="flex justify-end space-x-5 mt-6">
                    <Button variant="outline" onClick={() => setOpenModal(false)} className="px-5 py-3 size-md border-solid shadow-none text-accent-foreground">Cancel</Button>
                    <Button disabled={btnLoading} onClick={handleCreateCollection} className="px-5 py-3 text-md">
                        Create Collection
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}