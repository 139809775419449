import { knowledgeBuilderHttpClient } from "api/clients/knowledgeBuilder.httpClient";

/**
 * Service class for performing RAG operations.
 */
class MilvusService {

    /**
     * Query embeddings from a collection.
     * @param {Object} body - The body of the request.
     * @returns {Promise<Object>}
     */
    async queryEmbeddings(body) {
        const res = await knowledgeBuilderHttpClient.post(`/milvus/query_jina_embeddings`, body);
        return res;
    }
    /**
     * Query embeddings from a collection.
     * @param {Object} body - The body of the request.
     * @returns {Promise<Object>}
     */
    async hybridSearch(body) {
        // /projecto/api/v1/milvus/hybrid-search/
        const res = await knowledgeBuilderHttpClient.post(`/milvus/hybrid-search/`, body);
        return res;
    }

    // async QueryAndRerank(body) {
    //     const res = await bexKbHttpClientStg.post(`/milvus/query_and_rerank/`, body);
    //     return res;
    // }
}

const milvusService = new MilvusService();
export { milvusService };
