import React from 'react'
import OpCodeBuilder from "pages/PromptingAndRagTraining/components/OpcodeBuilder/OpCodeCreator.jsx";
import { OpCodeBuilderProvider } from 'pages/PromptingAndRagTraining/context/OpCodeBuilderContext';

export default function OpCodes() {

    return (
        <div className='w-full'>
            <div className='h-auto py-8'>
                <div className='flex justify-center items-center'>
                    <OpCodeBuilderProvider>
                        <OpCodeBuilder />
                    </OpCodeBuilderProvider>
                </div>
            </div>
        </div>
    )
}
