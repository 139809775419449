/* eslint-disable max-lines */
import { useContext, useEffect, useState } from "react";
import { Card, CardHeader, CardTitle, CardFooter } from "components/ui/card";
import { Button, buttonVariants } from "components/ui/button";
import { Input } from "components/ui/input";
import { Pagination, PaginationContent, PaginationItem, PaginationLink, PaginationNext, PaginationPrevious } from "components/ui/pagination";
import { MoreHorizontal, Search } from "lucide-react";
import { OpCodeBuilderContext } from "pages/PromptingAndRagTraining/context/OpCodeBuilderContext";
import { opCodeService } from "api/services/PROJECT-O/OpCodeBuilder.service";
import { Context } from "context/GlobalState";
import ViewOpCodeModal from "./ViewOpCodeModal";
import { OrganizationContext } from 'context/OrganizationContext';
import { SubOrgContext } from 'context/SubOrganizationContext';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from "components/ui/dropdown-menu";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "components/ui/dialog";
import { ScrollArea } from "components/ui/scroll-area";
import OpCodeUpdateForm from "./OpCodeUpdateForm";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "components/ui/alert-dialog";
import handler from "./OpCodeBuilderHandler";
import { opcodeWSService } from "api/services/PROJECT-O/WEBSOCKETS/OpCode.websocket.service";
import { LoaderSpinner } from "components/LoaderSpinner";

export default function OpCodeGrid({ collections, organizationId, subOrganizationId, workflows = [], setWorkflows }) {
    const {
        selectedOpCode,
        setSelectedOpCode,
        contextKey,
        setContextKey,
        handleGetSavedOpcodes,
        isOpcodeLoading,
    } = useContext(OpCodeBuilderContext);
    const { addNewNotifcation } = useContext(Context);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [loadingWorkflow, setLoadingWorkflow] = useState(null); // For individual loading
    const [deletingWorkflow, setDeletingWorkflow] = useState(null); // Track deletion of each workflow

    const cardsPerPage = 8;
    // const filteredWorkflows = workflows;
    const filteredWorkflows = searchTerm
        ? workflows.filter(workflow =>
            workflow.toLowerCase().includes(searchTerm.toLowerCase())
        )
        : workflows;
    const totalPages = Math.ceil(filteredWorkflows.length / cardsPerPage);
    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const currentCards = filteredWorkflows.slice(indexOfFirstCard, indexOfLastCard);

    


    const handleUse = async (workflow) => {
        if (selectedOpCode === workflow) {
            setSelectedOpCode(""); // Unselect if already selected
            setContextKey("");
            return;
        }

        setLoadingWorkflow(workflow);
        try {
            setSelectedOpCode("");
            setContextKey("");
            // const body = {
            //     org_id: selectedOrganization._id,
            //     sub_org_id: selectedSubOrganization._id,
            //     unique_names: [workflow],
            // };
            // const res = await opCodeService.getOpCodesByUniqueNames(body);
            // const details = res.data.OpCodes[workflow];
            // console.log(res)
            // const firstStep = details.steps[0];
            // let contextKey = "";

            // if (firstStep.type === "Non-LLM" && firstStep.params.api_details?.payload?.query_text) {
            //     const queryText = firstStep.params.api_details.payload.query_text;
            //     const match = queryText.match(/{{\s*(output|context)\[['"](\w+)['"]\]\s*}}/);
            //     if (match) {
            //         contextKey = match[2];
            //     }
            // } else if (firstStep.type === "LLM" && firstStep.params.query) {
            //     const query = firstStep.params.query;
            //     const match = query.match(/{{\s*(output|context)\[['"](\w+)['"]\]\s*}}/);
            //     if (match) {
            //         contextKey = match[2];
            //     }
            // }
            let contextKey = "real_time_data";
            setContextKey(contextKey);
            setSelectedOpCode(workflow);
        } catch (error) {
            console.error("Error getting OpCode by unique names:", error);
            addNewNotifcation("Error getting OpCode details. Please try again.", "danger");
        } finally {
            setLoadingWorkflow(null);
        }
    };

    // const handleDeleteWorkflow = async (workflow) => {
    //     setDeletingWorkflow(workflow); // Set loading for the specific workflow
    //     try {
    //         const body = {
    //             org_id: selectedOrganization._id,
    //             sub_org_id: selectedSubOrganization._id,
    //             opcode_ids: [workflow],
    //         };
    //         const res = await opCodeService.deleteOpCodes(body);

    //         if (res.data?.deleted) {
    //             addNewNotifcation(`Successfully deleted workflow: ${workflow}`, "success");

    //             // Remove the deleted workflow from the list and update workflows state
    //             const updatedWorkflows = workflows.filter((w) => w !== workflow);
    //             setWorkflows(updatedWorkflows);

    //             // If the deleted workflow was selected, reset selection
    //             if (workflow === selectedOpCode) {
    //                 setSelectedOpCode("");
    //                 setContextKey("");
    //             }
    //         }
    //     } catch (error) {
    //         console.error("Error deleting workflow:", error);
    //         addNewNotifcation("Error deleting workflow. Please try again.", "danger");
    //     } finally {
    //         setDeletingWorkflow(null); // Clear loading after deletion
    //     }
    // };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className="w-full space-y-4 h-fit">
            <div className="flex gap-2 items-center">
                <div className="flex gap-2 items-center w-full">
                    <Search className="w-5 h-5" />
                    <Input
                        type="text"
                        placeholder="Search workflows..."
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            setCurrentPage(1); // Reset to first page on search
                        }}
                        className="w-full mx-auto"
                    />
                </div>
                <div className="">
                    <Button
                        onClick={handleGetSavedOpcodes}
                        className="w-full flex gap-2"
                        type="button"
                        disabled={isOpcodeLoading}
                    >
                        {isOpcodeLoading ? "Loading Workflows..." : "Reload Workflows"}
                    </Button>
                </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 h-fit min-h-[224px]">
                {currentCards.length === 0 && (
                    <div className='flex items-center justify-center w-full text-gray-500 col-span-full'>
                        <span>No workflows saved.</span>
                    </div>
                )}
                {currentCards.map((workflow, index) => (
                    <Card
                        key={index}
                        className={`flex flex-col h-fit ${
                            workflow === selectedOpCode ? "bg-black text-white" : ""
                        }`}
                    >
                        <CardHeader className="p-3 min-h-16">
                            <div className="flex gap-2">
                                <CardTitle className="text-sm font-medium w-full overflow-hidden whitespace-normal break-words line-clamp-2 truncate">
                                    {workflow}
                                </CardTitle>
                                <OpCodeDropdownMenu collections={collections} handleUse={handleUse} workflow={workflow} workflows={workflows} setWorkflows={setWorkflows}  />
                            </div>
                        </CardHeader>
                        <CardFooter className="p-2 pt-0 gap-2">
                            <Button
                                variant="outline"
                                size="sm"
                                className="w-1/2 text-xs text-black bg-white"
                                onClick={() => handleUse(workflow)}
                                disabled={loadingWorkflow === workflow}
                            >
                                {loadingWorkflow === workflow
                                    ? "Fetching..."
                                    : workflow === selectedOpCode
                                    ? "Deselect"
                                    : "Select"}
                            </Button>
                            <ViewOpCodeModal organizationId={organizationId} subOrganizationId={subOrganizationId} workflow={workflow}/>
                            {/* <DeleteDialog loading={deletingWorkflow === workflow} itemNames={[workflow]} onDelete={() => handleDeleteWorkflow(workflow)} iconOnly={true} size="sm"/> */}
                        </CardFooter>
                    </Card>
                ))}
                {isOpcodeLoading && (
                    <p className="text-gray-500 text-center align-middle sm:col-span-2 md:col-span-3 lg:col-span-4">
                        Loading the workflows...
                    </p>
                )}
            </div>
            <Pagination>
                <PaginationContent className="flex flex-wrap justify-center gap-1 sm:gap-2">
                    <PaginationItem>
                        <PaginationPrevious
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                if (currentPage > 1) handlePageChange(currentPage - 1);
                            }}
                            className={currentPage === 1 ? "pointer-events-none opacity-50" : ""}
                        />
                    </PaginationItem>
                    {Array.from({ length: totalPages }).map((_, i) => {
                        const pageNumber = i + 1;
                        const isEllipsisBefore = pageNumber === 2 && currentPage > 4;
                        const isEllipsisAfter = pageNumber === totalPages - 1 && currentPage < totalPages - 3;

                        if (
                            pageNumber === 1 ||
                            pageNumber === totalPages ||
                            (pageNumber >= currentPage - 1 && pageNumber <= currentPage + 1)
                        ) {
                            return (
                                <PaginationItem key={i}>
                                    <PaginationLink
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handlePageChange(pageNumber);
                                        }}
                                        isActive={currentPage === pageNumber}
                                    >
                                        {pageNumber}
                                    </PaginationLink>
                                </PaginationItem>
                            );
                        } else if (isEllipsisBefore || isEllipsisAfter) {
                            return <span key={i} className="px-2">…</span>;
                        }
                        return null;
                    })}
                    <PaginationItem>
                        <PaginationNext
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                if (currentPage < totalPages) handlePageChange(currentPage + 1);
                            }}
                            className={currentPage === totalPages ? "pointer-events-none opacity-50" : ""}
                        />
                    </PaginationItem>
                </PaginationContent>
            </Pagination>
        </div>
    );
}


const OpCodeDropdownMenu = ({ collections, handleUse, workflows, setWorkflows, workflow }) => {

    const [updateDialog, setUpdateDialog] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(null);

    return (
        <div>
            <DropdownMenu modal={false}>
                <DropdownMenuTrigger asChild>
                    <Button aria-haspopup="true" size="icon" variant="ghost" className="border-none bg-transparent">
                        <MoreHorizontal className="h-4 w-4" />
                        <span className="sr-only">Toggle menu</span>
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                    <DropdownMenuItem onClick={() => setUpdateDialog(true)}>Update</DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem onClick={() => setDeleteDialog(true)} className='text-red-500'> Delete </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>

            {/* update workflow dialog */}
            <OpCodeUpdateDialog collections={collections} handleUse={handleUse} show={updateDialog} setShow={setUpdateDialog} workflow={workflow} />

            {/* delete workflow dialog */}
            <OpCodeDeleteDialog show={deleteDialog} workflows={workflows} setWorkflows={setWorkflows} setShow={setDeleteDialog} workflow={workflow} />
        </div>

    )
}


const OpCodeUpdateDialog = ({ show, setShow, workflow, collections}) => {

    const [btnLoading, setBtnLoading] = useState(false)
    const [fetchDetailsLoading, setFetchDetailsLoading] = useState(false)
    const [workflowDetails, setWorkflowDetails] = useState(null)
    const [error, setError] = useState(null)
    
    const { addNewNotifcation } = useContext(Context)
    const { selectedOrganization } = useContext(OrganizationContext);
    const { selectedSubOrganization } = useContext(SubOrgContext);


    const [opCodeName, setOpCodeName] = useState("");
    const [opCodeDescription, setOpCodeDescription] = useState("");
    const [inputGroups, setInputGroups] = useState([]);
    const { validateSteps } = useContext(OpCodeBuilderContext);

    const handleUpdateWorkflow = async () => {
        
        // Validate the opCodeName first
        let { isValid: isOpCodeValid, message: opCodeMessage } = handler.validateOpCodeName(opCodeName.trim());
        if (!isOpCodeValid) {
            addNewNotifcation(opCodeMessage, "warning");
            return;
        }

        // Validate the inputGroups (steps)
        if (inputGroups.length < 1){
            addNewNotifcation("Please add at least one step to the workflow.", "warning");
            return;
        }

        let { isValid: areStepsValid, message: stepsMessage } = validateSteps(inputGroups);
        if (!areStepsValid) {
            addNewNotifcation(stepsMessage, "warning");
            return;
        }

        const opCodeData = {
            org_id: selectedOrganization._id,
            sub_org_id: selectedSubOrganization._id,
            opcode_id: opCodeName,
            description: opCodeDescription,
            steps: inputGroups.map((group, index) => {
                const baseStep = {
                    step_id: `step_${index + 1}`,
                    step_type: group.step_type,
                    output_var: group.output_var,
                    next_step: index < inputGroups.length - 1 ? [`step_${index + 2}`] : null,
                    accumulate_output: group.accumulate_output,
                    is_first_step: index === 0, // Set is_first_step true for the first step, false otherwise
                };
    
                if (group.step_type === "LLM") {
                    return {
                        ...baseStep,
                        unique_name: group.unique_name,
                        model_type: group.model_type,
                        dynamic_inputs: group.dynamic_inputs,
                    };
                } else if (group.step_type === "Non-LLM") {
                    return {
                        ...baseStep,
                        registry_type: group.registry_type,
                        registry_key: group.registry_key,
                        fixed_inputs: group.fixed_inputs,
                        dynamic_inputs: group.dynamic_inputs,
                    };
                } else if (group.step_type === "Non-LLM - BeX Insights") {
                    return {
                        ...baseStep,
                        registry_type: group.registry_type,
                        registry_key: group.registry_key,
                        fixed_inputs: group.fixed_inputs,
                        dynamic_inputs: group.dynamic_inputs,
                    };
                } 
    
                return baseStep;
            }),
        };
    
        console.log("OpcodeData:", opCodeData);
    
        try {
            setBtnLoading(true)
            const response = await opcodeWSService.updateOpCode3(opCodeData, opCodeName);
            console.log(response);
            if (typeof response === "object" && response.message && response.message.includes("modified successfully")) {
                addNewNotifcation("Workflow saved successfully.", "success");
            } else {
                throw new Error(response)
            }
    
        } catch (error) {
            console.error("Error updating Workflow:", error);
            // if (error.message.includes("already exist")) {
            //     addNewNotifcation("Workflow already exists. Please choose another unique name.", "warning");
            // } else {
            addNewNotifcation("Workflow cannot be updated. Please try again.", "danger");
            // }
        } finally {
            setBtnLoading(false)
        }

    }

    const handleFetchDetails = async () => {

        setFetchDetailsLoading(true);
        try {
            const body = {
                org_id: selectedOrganization._id,
                sub_org_id: selectedSubOrganization._id,
                unique_names: [workflow],
            };
            const res = await opCodeService.getOpCodesByUniqueNames(body);
            const details = res.data.OpCodes[workflow];
            if (details) {
                setWorkflowDetails(details);
            } else {
                setError('error getting opcode')
            }
        } catch (error) {
            console.error("Error getting OpCode by unique names:", error);
            addNewNotifcation("Error getting OpCode details. Please try again.", "danger");
            setError(error)
        } finally {
            setFetchDetailsLoading(false);
        }
    };

    useEffect(() => {
        if (show) {
            handleFetchDetails()
        }
    }, [show]) 

    // useEffect(() => {
    //     console.log('workflowDetails: ', workflowDetails);
    // }, [workflowDetails])

    return (
        <Dialog open={show} onOpenChange={setShow}>
            <DialogContent className="w-full max-w-5xl p-0 bg-white rounded-lg shadow-lg">
                <ScrollArea className='max-h-[650px] p-4'>
                    <DialogHeader>
                        <DialogTitle className="text-2xl font-semibold">Update Workflow</DialogTitle>
                    </DialogHeader>
                    {fetchDetailsLoading ? (<h1>Loading...</h1>) : error ? (<h1>error getting workflow details</h1>) : (
                        <div className="space-y-6 mt-4">
                            <OpCodeUpdateForm
                                collections={collections} 
                                workflowDetails={workflowDetails}
                                inputGroups={inputGroups}
                                setInputGroups={setInputGroups}
                                opCodeName={opCodeName}
                                setOpCodeName={setOpCodeName}
                                opCodeDescription={opCodeDescription}
                                setOpCodeDescription={setOpCodeDescription}
                            />
                        </div>
                    )}
                    <DialogFooter className="flex justify-end space-x-5 mt-6">
                        <Button variant="outline" onClick={() => setShow(false)} className="px-5 py-3 size-md border-solid shadow-none text-accent-foreground">Cancel</Button>
                        <Button disabled={btnLoading || error} onClick={handleUpdateWorkflow} className="px-5 py-3 text-md">
                            {btnLoading ? <LoaderSpinner/> : "Update"}
                        </Button>
                    </DialogFooter>
                </ScrollArea>
            </DialogContent>
        </Dialog>
    )
}

const OpCodeDeleteDialog = ({ show, setShow, workflows, setWorkflows, workflow }) => {

    const [btnLoading, setBtnLoading] = useState(false)

    const {
        selectedOpCode,
        setSelectedOpCode,
        setContextKey,
    } = useContext(OpCodeBuilderContext);
    const { addNewNotifcation } = useContext(Context);
    const { selectedOrganization } = useContext(OrganizationContext);
    const { selectedSubOrganization } = useContext(SubOrgContext);


    const handleDeleteWorkflow = async () => {
        setBtnLoading(true)
        try {
            const body = {
                org_id: selectedOrganization._id,
                sub_org_id: selectedSubOrganization._id,
                opcode_ids: [workflow],
            };
            const res = await opCodeService.deleteOpCodes(body);

            if (res.data?.deleted) {
                addNewNotifcation(`Successfully deleted workflow: ${workflow}`, "success");

                // Remove the deleted workflow from the list and update workflows state
                const updatedWorkflows = workflows.filter((w) => w !== workflow);
                setWorkflows(updatedWorkflows);

                // If the deleted workflow was selected, reset selection
                if (workflow === selectedOpCode) {
                    setSelectedOpCode("");
                    setContextKey("");
                }
            }
        } catch (error) {
            console.error("Error deleting workflow:", error);
            addNewNotifcation("Error deleting workflow. Please try again.", "danger");
        } finally {
            setBtnLoading(false)
        }
    };
    

    return (
        <AlertDialog open={show} onOpenChange={setShow}>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>
                        Are you sure?
                    </AlertDialogTitle>
                    <AlertDialogDescription className="whitespace-pre-wrap">
                        This action cannot be undone. This will permanently delete the selected item
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>
                        Cancel
                    </AlertDialogCancel>
                    <AlertDialogAction disabled={btnLoading} onClick={handleDeleteWorkflow} className={buttonVariants({ variant: "destructive" })}>
                        Delete
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}