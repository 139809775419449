import { projectOHttpClient } from "api/clients/project-o.httpClient";

/**
 * Service class for performing RAG operations.
 */
class GroqService {

    /**
     * Send a prompt.
     * @returns {Promise<Object>}
     */
    async Experiment(body){
        const response = await projectOHttpClient.post(`/groq_models/groqModel/experiment`, body, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        return response;
    }

    async streamingExperiment(body, onChunk) {
        try {
            // Used Fetch to allow for handling streaming response
            // const response = await fetch('http://localhost:8000/projecto/api/v1/stream_models/groq/stream/experiment_v2', {
            const response = await fetch('https://app.coolriots.ai/projecto/api/v1/stream_models/groq/stream/experiment_v2', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'text/event-stream', // Ensure server recognizes this as a streaming request
                },
                body: JSON.stringify(body),
            });
    
            // Check if the response is OK
            if (!response.ok) {
                throw new Error(`Streaming request failed: ${response.statusText}`);
            }
    
            // Access the ReadableStream from the response body
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
    
            // Read chunks
            while (true) {
                const { done, value } = await reader.read();
                if (done) {
                    console.log('Stream complete.');
                    break;
                }
    
                // Decode the chunk
                const chunk = decoder.decode(value, { stream: true });
                // console.log('Received chunk:', chunk);
    
                // Pass the chunk to the callback
                if (onChunk) {
                    onChunk(chunk);
                }
            }
    
        } catch (error) {
            console.error('Error during streaming:', error);
            throw error; // Rethrow the error to handle it in the calling function
        }
    }

    /**
     * Get List of Groq Models.
     * @returns {Promise<Object>}
     */
    async ListModels(){
        const response = await projectOHttpClient.get(`groq_models/groqModel/list_models`);
        return response;
    }
    
}

const groqService = new GroqService()
export { groqService }
