/* eslint-disable max-lines */
import { useContext, useEffect, useState } from "react";
import { Button } from "components/ui/button";
import { Textarea } from "components/ui/textarea";
import InputGroup from "./InputGroup";
import { Plus } from "lucide-react";
import { Context } from "context/GlobalState";
import { Label } from "components/ui/label";
import InputValidation from "components/InputValidation";
import handler from "./OpCodeBuilderHandler";
import { OpCodeBuilderContext } from "pages/PromptingAndRagTraining/context/OpCodeBuilderContext";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';
import { opcodeWSService } from "api/services/PROJECT-O/WEBSOCKETS/OpCode.websocket.service";
import { SubOrgContext } from "context/SubOrganizationContext";

function extractKeys(str) {
    const regex = /{{\s*output\['(\w+)'\]\s*}}/g;
    let match;
    const keys = [];

    while ((match = regex.exec(str)) !== null) {
        keys.push(match[1]);
    }

    return keys;
}

const STEP_TYPES = [
    {id: 1, name: "LLM"},
    {id: 3, name: "Non-LLM - RAG API"},
    {id: 2, name: "Non-LLM - BeX Insights"}
]


export default function OpCodeForm({ organizationId, subOrganizationId, collections }) {
    const [stepType, setStepType] = useState(STEP_TYPES[0].name);
    const { addNewNotifcation } = useContext(Context);
    const { 
        savedModels,
        opCodes, setOpCodes,
        isModelLoading, handleGetSavedModels,
        opCodeName, setOpCodeName,
        opCodeDescription, setOpCodeDescription,
        inputGroups, setInputGroups,
        isCreating, setIsCreating,
        validateSteps
    } = useContext(OpCodeBuilderContext);

    const handleAddGroup = () => {
        // const newStepId = `step_${inputGroups.length + 1}`;
        const newInputGroups = [...inputGroups];
    
        if (stepType === "LLM") {
            newInputGroups.push({
                step_id: "",
                step_type: "LLM",
                unique_name: "",
                model_type: "",
                dynamic_inputs: newInputGroups.length < 1 ? ["real_time_data"] : [],  // This will hold any dynamic inputs to be connected
                output_var: "",
                next_step: null, // Will be assigned later when connecting steps
                accumulate_output: true,
                is_first_step: false 
            });
        } else if (stepType === "Non-LLM - BeX Insights") {
            newInputGroups.push({
                step_id: "",
                step_type: "Non-LLM - BeX Insights",
                registry_type: "function",
                registry_key: "",
                fixed_inputs: {},
                dynamic_inputs: newInputGroups.length < 1 ? ["real_time_data"] : [],
                output_var: "",
                next_step: null, // Will be assigned later when connecting steps
                accumulate_output: true
            });
        } else if (stepType === "Non-LLM - RAG API") {
            newInputGroups.push({
                step_id: "",
                step_type: "Non-LLM",
                registry_type: "api",
                registry_key: "",
                fixed_inputs: {
                    "organization_id": organizationId,
                    "sub_organization_id": subOrganizationId,
                    "collection_id": "",
                    "use_rerank": true,
                    "top_k": 10,
                    "top_n": 3,
                    "offset": 0,
                    "filters": {}
                },
                dynamic_inputs: {
                    "query_text": newInputGroups.length < 1 ? "real_time_data" : ""
                },
                output_var: "",
                next_step: null, // Will be assigned later when connecting steps
                accumulate_output: true
            });
        } 
    
        setInputGroups(newInputGroups);
    };
    
    

    const handleRemoveGroup = (index) => {
        const newInputGroups = inputGroups.filter((_, i) => i !== index);
        setInputGroups(newInputGroups);
    };

    const createOpCode = async () => {
        // Validate the opCodeName first
        let { isValid: isOpCodeValid, message: opCodeMessage } = handler.validateOpCodeName(opCodeName.trim());
        if (!isOpCodeValid) {
            addNewNotifcation(opCodeMessage, "warning");
            return;
        }

        // Validate the inputGroups (steps)
        if (inputGroups.length < 1){
            addNewNotifcation("Please add at least one step to the workflow.", "warning");
            return;
        }

        let { isValid: areStepsValid, message: stepsMessage } = validateSteps(inputGroups);
        if (!areStepsValid) {
            addNewNotifcation(stepsMessage, "warning");
            return;
        }

        const opCodeData = {
            org_id: organizationId,
            sub_org_id: subOrganizationId,
            opcode_id: opCodeName,
            description: opCodeDescription,
            steps: inputGroups.map((group, index) => {
                const baseStep = {
                    step_id: `step_${index + 1}`,
                    step_type: group.step_type,
                    output_var: group.output_var,
                    next_step: index < inputGroups.length - 1 ? [`step_${index + 2}`] : null,
                    accumulate_output: group.accumulate_output,
                    is_first_step: index === 0, // Set is_first_step true for the first step, false otherwise
                };
    
                if (group.step_type === "LLM") {
                    return {
                        ...baseStep,
                        unique_name: group.unique_name,
                        model_type: group.model_type,
                        dynamic_inputs: group.dynamic_inputs,
                    };
                } else if (group.step_type === "Non-LLM") {
                    return {
                        ...baseStep,
                        registry_type: group.registry_type,
                        registry_key: group.registry_key,
                        fixed_inputs: group.fixed_inputs,
                        dynamic_inputs: group.dynamic_inputs,
                    };
                } else if (group.step_type === "Non-LLM - BeX Insights") {
                    return {
                        ...baseStep,
                        registry_type: group.registry_type,
                        registry_key: group.registry_key,
                        fixed_inputs: group.fixed_inputs,
                        dynamic_inputs: group.dynamic_inputs,
                    };
                } 
    
                return baseStep;
            }),
        };
    
        console.log("OpcodeData:", opCodeData);
    
        try {
            setIsCreating(true);
            const response = await opcodeWSService.createOpCode3(opCodeData);
            console.log(response);
            if (typeof response === "object" && response.message && response.message.includes("created successfully")) {
                addNewNotifcation("Workflow saved successfully.", "success");
            } else {
                throw new Error(response)
            }
    
        } catch (error) {
            console.error("Error creating Workflow:", error);
            if (error.message.includes("already exist")) {
                addNewNotifcation("Workflow already exists. Please choose another unique name.", "warning");
            } else {
                addNewNotifcation("Workflow cannot be created. Please try again.", "danger");
            }
        } finally {
            setIsCreating(false);
        }
    };
    
    

    const handleSubmit = async () => {
        const result = await createOpCode();
        if (result) {
            setOpCodes([...opCodes, { name: opCodeName, ...result }]);
            setOpCodeName("");
            setOpCodeDescription("");
            setInputGroups([]);
        }
    };

    useEffect(() => {
        // Ensure the first step has the correct input variable
        if (inputGroups.length > 0) {
            const firstStep = inputGroups[0];
            const updatedInputGroups = [...inputGroups];
    
            // Check for LLM and Non-LLM cases and set "real_time_data" if necessary
            if (firstStep.step_type === "LLM") {
                if (!firstStep.dynamic_inputs || firstStep.dynamic_inputs[0] !== "real_time_data") {
                    // console.log("Updating first step (LLM) query_text to real_time_data");
                    updatedInputGroups[0] = {
                        ...firstStep,
                        dynamic_inputs: ["real_time_data"],
                    };
                    setInputGroups(updatedInputGroups);
                }
            } else if (firstStep.step_type === "Non-LLM") {
                if (
                    !firstStep.dynamic_inputs?.query_text ||
                    firstStep.dynamic_inputs.query_text !== "real_time_data"
                ) {
                    // console.log("Updating first step (Non-LLM) query_text to real_time_data");
                    updatedInputGroups[0] = {
                        ...firstStep,
                        dynamic_inputs: {
                            ...firstStep.dynamic_inputs,
                            query_text: "real_time_data",
                        },
                    };
                    setInputGroups(updatedInputGroups);
                }
            } else if (firstStep.step_type === "Non-LLM - BeX Insights") {
                if (!firstStep.dynamic_inputs || firstStep.dynamic_inputs[0] !== "real_time_data") {
                    // console.log("Updating first step (LLM) query_text to real_time_data");
                    updatedInputGroups[0] = {
                        ...firstStep,
                        dynamic_inputs: ["real_time_data"],
                    };
                    setInputGroups(updatedInputGroups);
                }
            }
        }
    }, [inputGroups]); // Run whenever inputGroups changes

    return (
        <div className="mt-4 space-y-4">
            <div>
                <Label>Workflow ID</Label>
                <InputValidation
                    value={opCodeName}
                    onChange={(e) => setOpCodeName(e.target.value)}
                    placeholder="Enter a unique name for this workflow"
                    validation={handler.validateOpCodeName}
                />
            </div>
            <div>
                <Label>Workflow Description</Label>
                <Textarea value={opCodeDescription} onChange={(e) => setOpCodeDescription(e.target.value)} placeholder="Enter a description for this workflow" />
            </div>
            <div>
                {/* <Button onClick={handleGetSavedModels} className="flex gap-2" type="button" disabled={isModelLoading}>
                    {isModelLoading ? "Loading Instructions..." : "Reload Instructions"}
                </Button> */}
                <hr/>
            </div>
            {inputGroups.map((group, index) => (
                <div className="flex flex-col gap-4" key={index}>
                    <InputGroup 
                        collections={collections}
                        organizationId={organizationId}
                        subOrganizationId={subOrganizationId}
                        group={group} 
                        index={index}
                        handleRemoveGroup={handleRemoveGroup}
                        handleAddGroup={handleAddGroup}
                        inputGroups={inputGroups}
                        setInputGroups={setInputGroups}
                    />
                    <hr/>
                </div>
            ))}
            <div className="flex gap-2">
                <Select onValueChange={(value) => setStepType(STEP_TYPES.find(item => item.name === value).name)} id="step_type" className="w-full" value={stepType}>
                    <SelectTrigger>
                        <SelectValue placeholder="Select step type" />
                    </SelectTrigger>
                    <SelectContent>
                        {STEP_TYPES.map((item) => (
                            <SelectItem key={item.id} value={item.name}>{item.name}</SelectItem>
                        ))}
                    </SelectContent>
                </Select>
                <Button variant="default" onClick={handleAddGroup} className="w-full flex gap-2" type="button"><Plus className="h-4 w-4" />Add Step</Button>
            </div>
            <div className="flex">
                <Button onClick={handleSubmit} className="w-full" type="button" disabled={isCreating}>
                    {isCreating ? "Creating Workflow..." : "Create Workflow"}
                </Button>
            </div>
        </div>
    );
}
