import FileManager from 'components/FileManager/FileManager'
import React, { useContext, useEffect, useState } from 'react'
import Breadcrumb from 'components/Breadcrumb';
import { Layers } from 'lucide-react';
import { Context } from 'context/GlobalState';
import ProcessModal from 'pages/Dashboard/SubOrganization/Projects/Collection/Documents/ProcessModal';
import L2ProcessingResult, { L2ProcessingDialog } from 'pages/Dashboard/SubOrganization/Projects/Collection/Documents/L2ProcessingModal';
import { redisCollectionService } from 'api';

export default function DemoFileManagerDocument() {
    const [isProcessing, setIsProcessing] = useState(false);
    const [isProcessing2, setIsProcessing2] = useState(false);
    const [isProcessing2Result, setIsProcessing2Result] = useState(false);
    const [error, setError] = useState(null);
    
    const [processModal, setProcessModal] = useState(false)
    
    const [collections, setCollections] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        redisCollectionService.listCollections('demo', 'demo')
        .then((projectsResponse) => {
            console.log('projectsResponse: ', projectsResponse);
            setCollections(projectsResponse.data)
        }).catch((error) => {
            console.log('error:R', error);
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    const { addNewNotifcation } = useContext(Context)

    const handleOpenProcessModal = () => {
        setProcessModal(true)
    };

    const handleOpenL2ProcessResult = () => {
        if (error) { 
            addNewNotifcation('Error retrieving the result', 'warning')
        } else {
            setIsProcessing2Result(true)
        }
    };

    const ACTION_COMPONENTS = [
        {id: 1, name: 'L1 Processing', icon: <Layers className="mr-2 h-4 w-4" />, onclick: handleOpenProcessModal},
        {id: 2, name: 'L2 Processing', icon: <Layers className="mr-2 h-4 w-4" />, onclick: () => setIsProcessing2(true)},
        // {id: 2, name: 'L2 Processing Result', icon: <Layers className="mr-2 h-4 w-4" />, onclick: handleOpenL2ProcessResult}
    ]

    if (loading) {
        return <h1>loading...</h1>
    }

    return (
        <div className='w-full'>
            <div className='h-auto py-8'>
                <div className='my-3 px-2'>
                    <Breadcrumb maxPaths={3} />
                </div>

                <FileManager
                    collections={collections}
                    actionComponents={ACTION_COMPONENTS}/>

                <ProcessModal organizationId='demo' subOrganizationId='demo' isOpen={processModal} setIsOpen={setProcessModal} loading={isProcessing} setLoading={setIsProcessing}/>
                
                {/* l2 processing  */}
                <L2ProcessingDialog isOpen={isProcessing2} setIsOpen={setIsProcessing2} />

                {/* l2 processing result  */}
                {/* <L2ProcessingResult isOpen={isProcessing2Result} setIsOpen={setIsProcessing2Result} setError={setError} /> */}
            </div>
        </div>
    )
}
