// import { httpRequestO } from "utils/httpsRequest";
import { ragService } from "api/services/PROJECT-O";

class AILabHelper {

    // Process Multiplee AI Lab Docs 
    // This will put all the files in promise first, and assign the correct name metadata, hence a helper function
    async ProcessFiles(files, filter, org, suborg, bucket, chunk_size = 200, chunk_overlap = 20) {

        try {
            const results = await Promise.all(files.map(async (file, index) => {
                const form = new FormData();

                form.append('organization_id', org);
                form.append('sub_organization_id', suborg);
                form.append('bucket_name', bucket);
                form.append('chunk_size', chunk_size);
                form.append('chunk_overlap', chunk_overlap);

                // Make sure the extension is lowercased
                const newFile = this.toLowerCaseExtension(file);
                form.append('files', newFile);

                let filter2 = { ...filter };
                filter2.name = newFile.name;

                form.append('metadata', JSON.stringify(filter2));

                try {
                    const res = await ragService.processFile(form);

                    if (!res.data || res.data.document_ids.length === 0) {
                        console.error(`No document IDs received for file at index ${index}.`);
                        return null;
                    }

                    return res.data;
                } catch (error) {
                    console.error(`Error processing file at index ${index}:`, error);
                    return null;
                }
            }));
            
            return results.filter(result => result !== null && result !== undefined);
        } catch (error) {
            console.log("FILE PROCESSING ERROR", error);
            return [];
        }
    }

    constructRetrievedResults(docs) {
        console.log(docs);
        let str = "";
        docs.forEach((doc) => {
            let ext = this.getFileExtension(doc.metadata.name);
            let ppt = ext === "ppt" || ext === "pptx" || ext === "PPT" || ext === "PPTX";
            let pdf = ext === "pdf" || ext === "PDF";
            let docx = ext === "docx" || ext === "DOCX";


            str += "[Document]\n";
            str += ppt ? `[Page]${doc.metadata.slide_number}[/Page]\n` : "";
            str += (pdf || docx) ? `[Page]${doc.metadata.start_page} to ${doc.metadata.end_page}[/Page]\n` : "";
            str += `[Title]${doc.metadata.name}[/Title]\n`;
            str += `[Content]${doc.page_content}[/Content]\n`;
            // str += ppt ? `[Page ${doc.metadata.slide_number}]\n` : "";
            str += "[/Document]\n\n";
        })
        return str;
    }

    getFileExtension(url) {
        // eslint-disable-next-line no-useless-escape
        return url.split('.').pop().split(/\#|\?/)[0];
    }


    toLowerCaseExtension(file) {
        const { name, type } = file;
        const lastDotIndex = name.lastIndexOf('.');
        if (lastDotIndex === -1) return file;
        const newFileName = `${name.slice(0, lastDotIndex)}.${name.slice(lastDotIndex + 1).toLowerCase()}`;
    
        // eslint-disable-next-line no-undef
        return new File([file], newFileName, { type });
    }



    // WatsonX
    ConstructPromptWatsonX(prompt) {
        let constructedPrompt = "";
        constructedPrompt =
        "Input:\n" + 
        ((prompt.instruction) ? `${prompt.instruction}\n\n` : '') +
        ((prompt.context) ? `${prompt.context}\n\n` : '') +
        ((prompt.target_format) ? `${prompt.target_format}\n\n` : '') +
        ((prompt.sample_input) ? `Sample Input:\n${prompt.sample_input}\n\n` : '') +
        ((prompt.sample_output) ? `Sample Output:\n${prompt.sample_output}\n\n` : '') +
        ((prompt.input_data) ? `Actual Input:\n${prompt.input_data}\n\n` : '') 

        + "Output:\n";
        return constructedPrompt;
    }
    ConstructPromptUnified(prompt) {
        let constructedPrompt = "";
        constructedPrompt =
        ((prompt.instruction) ? `${prompt.instruction}` : '') +
        ((prompt.context) ? `\n\n${prompt.context}` : '') +
        ((prompt.target_format) ? `\n\n${prompt.target_format}` : '') +
        ((prompt.sample_input) ? `\n\nSample Input:\n${prompt.sample_input}` : '') +
        ((prompt.sample_output) ? `\n\nSample Output:\n${prompt.sample_output}` : '') +
        ((prompt.input_data) ? `\n\nActual Input:\n${prompt.input_data}` : '') 
        return constructedPrompt;
    }

    // Groq
    ConstructPromptGroq(prompt) {
        let constructedPrompt = "";
        constructedPrompt =
        "Input:\n" + 
        ((prompt.instruction) ? `${prompt.instruction}\n\n` : '') +
        ((prompt.context) ? `${prompt.context}\n\n` : '') +
        ((prompt.target_format) ? `${prompt.target_format}\n\n` : '') +
        ((prompt.sample_input) ? `Sample Input:\n${prompt.sample_input}\n\n` : '') +
        ((prompt.sample_output) ? `Sample Output:\n${prompt.sample_output}\n\n` : '') +
        ((prompt.input_data) ? `Actual Input:\n${prompt.input_data}\n\n` : '') 

        + "Output:\n";
        return constructedPrompt;
    }

}
const labHelper = new AILabHelper()
export default labHelper;