import React, { useState, useContext } from "react";
import { Button } from "components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "components/ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select";
import { useParams } from 'react-router-dom';
import { Context } from "context/GlobalState";
import { LoaderSpinner } from "components/LoaderSpinner";
import { kbWSService } from "api/services/PROJECT-O/WEBSOCKETS/KnowledgeBuilder.websocket.service";
import { FileManagerContext } from "context/FileManagerContext";

export default function ProcessModal({ organizationId, subOrganizationId, isOpen, setIsOpen, loading, setLoading, title="Confirm Processing", subTitle="Select which file status to process."}) {
    const [selectedTag, setSelectedTag] = useState("unprocessed");

    const { collectionId } = useParams()
    const { addNewNotifcation } = useContext(Context)
    const { socket, l1Status } = useContext(FileManagerContext)

    const handleStartProcess = async () => {
        // handle StartProcess
        try {
            setLoading(true);
            const status_filter = selectedTag === "all" ? "" : selectedTag;
            const body = {
                "organization_id": organizationId,
                "sub_organization_id": subOrganizationId,
                "collection_id": collectionId,
                "prefix": "", // All files
                "status_filter": status_filter,
                "contextual_embedding_strategy": "SUMROLE"
            }
            const res = await kbWSService.get_l1_task_id(body);
            
            if (socket.readyState === WebSocket.OPEN) {
                socket.send(JSON.stringify({
                    "task_id": res.data.task_id
                }));
            } else {
                addNewNotifcation("Request not sent. Please reload the page and try again.");
                console.error("WebSocket is not open. Unable to send message.");
            }

            setIsOpen(false);
        } catch (error) {
            console.error("Error processing collection:", error);
            addNewNotifcation(`Processing failed. Please try again.`, "danger")
        } finally {
            setLoading(false);
        }
    };

    subTitle = selectedTag === "unprocessed" ? `Process all unprocessed files with "New" status.` :
            selectedTag === "all"? `Process all files regardless of status.` :
            `Process all files that failed the processing and has a status "failed"`;

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild>
                <Button variant="outline" className="hidden">Process Files</Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>{title || "Confirm File Processing"}</DialogTitle>
                    <DialogDescription>
                        {subTitle || "Select which file tag you want to process."}
                    </DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                    <Select value={selectedTag} onValueChange={setSelectedTag}>
                        <SelectTrigger className="w-full">
                            <SelectValue placeholder="Select a file tag" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem key="unprocessed" value="unprocessed">New Files</SelectItem>
                            <SelectItem key="all" value="all">All Files</SelectItem>
                            <SelectItem key="failed" value="failed">Failed Files</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
                <DialogFooter>
                    <Button variant="outline" onClick={() => setIsOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleStartProcess} disabled={loading || l1Status === "processing" || l1Status === "checking"}>{loading ? <LoaderSpinner/> :"Confirm"}</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
