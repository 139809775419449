import { useContext, useState } from 'react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs"
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card"
import { Badge } from "components/ui/badge"
import { Button } from "components/ui/button"
import { ScrollArea } from "components/ui/scroll-area"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "components/ui/table"
import { ArrowLeftIcon } from "lucide-react"
import { useNavigate, useParams } from 'react-router-dom'
import { OrgInfoContext } from './OrganizationContext'
import { AppLoader } from 'components/LoaderSpinner'
import SubOrgResourceDetail from './SubOrganizationResource'

export default function SubOrganizationDetails() {
    const [activeTab, setActiveTab] = useState("general")

    const { subOrgId } = useParams()
    const navigate = useNavigate()

    const { organizationData, loading, error } = useContext(OrgInfoContext)
    
    if (loading) {
        return <div className='flex flex-grow flex-col justify-center items-center'> <AppLoader size={50} /> </div>;
    }
    if (error) {
        return <h1>error: {error}</h1>
    }

    const subOrg = organizationData.subOrganizations.find(item => item._id === subOrgId)

    return (
        <div className="container mx-auto p-4">
            <Button variant="ghost" onClick={() => navigate(-1)} className="mb-4">
                <ArrowLeftIcon className="mr-2 h-4 w-4" /> Back to Organizations
            </Button>
        
            <Card className="mb-6">
                <CardHeader>
                    <CardTitle className="flex justify-between items-center">
                        <span>{subOrg.name}</span>
                        <Badge variant={subOrg.isMain ? "default" : "secondary"}>
                            {subOrg.isMain ? "Main" : "Sub"}
                        </Badge>
                    </CardTitle>
                </CardHeader>
            </Card>

            <Tabs value={activeTab} onValueChange={setActiveTab}>
                <TabsList className="grid w-full grid-cols-6">
                    <TabsTrigger value="general">General</TabsTrigger>
                    <TabsTrigger value="members">Members</TabsTrigger>
                    <TabsTrigger value="workflow">Workflow</TabsTrigger>
                    {/* <TabsTrigger value="projects">Projects</TabsTrigger> */}
                    <TabsTrigger value="subscription">Subscription</TabsTrigger>
                    <TabsTrigger value="resources">Resources</TabsTrigger>
                </TabsList>

                <TabsContent value="general">
                    <Card>
                        <CardHeader>
                            <CardTitle>General Information</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="grid grid-cols-2 gap-4">
                                <div>ID: {subOrg._id}</div>
                                <div>Organization ID: {subOrg.organizationId}</div>
                                <div>Created: {new Date(subOrg.created).toLocaleString()}</div>
                                <div>Updated: {new Date(subOrg.updated).toLocaleString()}</div>
                                <div>Is Deleted: {subOrg.isDeleted ? "Yes" : "No"}</div>
                                <div>Is Trial Used: {subOrg.isTrialUsed ? "Yes" : "No"}</div>
                            </div>
                        </CardContent>
                    </Card>
                </TabsContent>

                <TabsContent value="members">
                    <Card>
                        <CardHeader>
                            <CardTitle>Members ({subOrg.members.length})</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <ScrollArea className="h-[400px]">
                                <Table>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHead>Name</TableHead>
                                            <TableHead>Email</TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {subOrg.members.map((member) => (
                                            <TableRow key={member.id}>
                                                <TableCell>{member.name}</TableCell>
                                                <TableCell>{member.email}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </ScrollArea>
                        </CardContent>
                    </Card>
                </TabsContent>

                <TabsContent value="workflow">
                    <Card>
                        <CardHeader>
                            <CardTitle>Workflow: {subOrg?.workflow?.name || ''}</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-4">
                                <div>
                                    <h3 className="font-semibold">Workflow Assistants</h3>
                                    <ul className="list-disc pl-5">
                                        {subOrg.workflow?.workflowAssistants?.map((assistant) => (
                                            <li key={assistant._id}>
                                                {assistant.assistant.name} - Status: {assistant.status}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                {subOrg?.workflow?.metadata && (
                                    <div>
                                        <h3 className="font-semibold">Metadata</h3>
                                        <ul className="list-disc pl-5">
                                            {Object.entries(subOrg.workflow.metadata).map(([key, value]) => (
                                                <li key={key}>{key}: {value}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </CardContent>
                    </Card>
                </TabsContent>

                {/* <TabsContent value="projects">
                    <Card>
                        <CardHeader>
                            <CardTitle>Projects ({subOrg.projects?.length})</CardTitle>
                        </CardHeader>
                        <CardContent>
                            {subOrg.projects?.map((project) => (
                                <div key={project._id} className="mb-4 p-4 border rounded">
                                    <h3 className="font-semibold">{project.name}</h3>
                                    <p>Collections: {project.collections.length}</p>
                                    <ul className="list-disc pl-5">
                                        {project.collections.map((collection) => (
                                            <li key={collection._id}>
                                                {collection.name} - Documents: {collection.documentsLen}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </CardContent>
                    </Card>
                </TabsContent> */}

                <TabsContent value="subscription">
                    <Card>
                        <CardHeader>
                            <CardTitle>Subscription Details</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-2">
                                <div>Status: {subOrg.subscription?.stripeSubscription?.status}</div>
                                <div>Plan: {subOrg.subscription?.stripeSubscription?.plan.nickname || "N/A"}</div>
                                <div>Amount: ${subOrg.subscription?.stripeSubscription?.plan.amount / 100} / year</div>
                                <div>Current Period End: {new Date(subOrg.subscription?.stripeSubscription?.current_period_end * 1000).toLocaleDateString()}</div>
                            </div>
                        </CardContent>
                    </Card>
                </TabsContent>

                <TabsContent value="resources">
                    <SubOrgResourceDetail subOrg={subOrg} />
                </TabsContent>
            </Tabs>
        </div>
    )
}