import { useEffect, useState, useContext } from "react";
import { Label } from "components/ui/label";
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { ChevronDown } from "lucide-react";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "components/ui/select";
import { Button } from "components/ui/button";
import InputValidation from "components/InputValidation";
import { opCodeService } from "api/services/PROJECT-O/OpCodeBuilder.service";
import handler from "./OpCodeBuilderHandler";

function formatToTitleCase(str) {
    return str
        .replace(/_/g, ' ')           // Replace underscores with spaces
        .replace(/\w\S*/g, function(word) {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });                           // Convert to Title Case
}

export default function StepNonLLMBeXInsights({ group, index, inputGroups, setInputGroups, getPreviousOutputs }) {
    const [apis, setApis] = useState([]);
    const [functionRegistry, setFunctionRegistry] = useState({});
    const [selectedFunctionProps, setSelectedFunctionProps] = useState({});

    useEffect(() => {
        // Fetch the list of functions and their properties from FUNCTION_REGISTRY
        const fetchFunctions = async () => {
            try {
                const res = await opCodeService.getFunctionRegistry();
                if (res.data && typeof res.data === 'object') {
                    setFunctionRegistry(res.data);
                    setApis(Object.keys(res.data));

                    // Set properties if a function is already selected
                    if (group?.registry_key) {
                        setSelectedFunctionProps(res.data[group.registry_key]?.properties || {});
                    }
                }
            } catch (err) {
                console.error("Error fetching functions:", err);
            }
        };

        fetchFunctions();
    }, [group.registry_key]);

    const firstStep = inputGroups[0];
    let firstStepDynamicInput = [];
    if (firstStep?.step_type === "LLM") {
        firstStepDynamicInput = firstStep.dynamic_inputs?.map((value) => ({
            value,
            label: "Step 1 Input",
        })) || [];
    } else if (firstStep?.step_type === "Non-LLM" && firstStep.dynamic_inputs?.query_text) {
        firstStepDynamicInput = [
            {
                value: firstStep.dynamic_inputs.query_text,
                label: "Step 1 Input",
            },
        ];
    } else if (firstStep?.step_type === "Non-LLM - BeX Insights") {
        firstStepDynamicInput = firstStep?.dynamic_inputs?.map((value) => ({
            value,
            label: "Step 1 Input",
        })) || [];
    }

    // Combine previous outputs with the first step's dynamic input
    const inputSelection = [
        ...firstStepDynamicInput,
        ...getPreviousOutputs(index),
    ];

    const handleInputChange = (field, value) => {
        const newInputGroups = [...inputGroups];
        if (field === "registry_key") {
            newInputGroups[index].registry_key = value;
            setSelectedFunctionProps(functionRegistry[value]?.properties || {});
        } else if (field === "output_var") {
            newInputGroups[index].output_var = value;
        } else if (field === "dynamic_inputs") {
            if (value !== "-"){
                newInputGroups[index].dynamic_inputs = [value];
            }
            else{
                newInputGroups[index].dynamic_inputs = [];
            }
        }
        console.log(newInputGroups)
        setInputGroups(newInputGroups);
    };
    
    

    return (
        <div className="grid grid-cols-4 gap-4">
            {/* Function Name Selection */}
            <div className="col-span-1">
                <Label htmlFor={`functionName-${index}`}>API Name</Label>
                <Select
                    onValueChange={(value) => handleInputChange("registry_key", value)}
                    value={group?.registry_key || ""}
                >
                    <SelectTrigger className="w-full bg-white">
                        <SelectValue placeholder="Select an API" />
                    </SelectTrigger>
                    <SelectContent>
                        {apis.map((funcName) => (
                            <SelectItem key={funcName} value={funcName}>
                                {funcName}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>

            {/* Render Inputs Based on Properties */}
            <div className="col-span-1">
                <Label htmlFor={`input-${index}`}>Step Input</Label>
                {index === 0 ? (
                    // Text Input for the First Step
                    <InputValidation
                        id={`dynamicInput-${index}`}
                        value={group?.dynamic_inputs?.[0] || "real_time_data"}
                        className="bg-white"
                        disabled={true}
                    />
                ) : (
                    // Multi-select Dropdown for Subsequent Steps
                    <Select
                        onValueChange={(value) => handleInputChange("dynamic_inputs", value)}
                        value={group?.dynamic_inputs?.[0] || "-"}
                    >
                        <SelectTrigger className="w-full bg-white">
                            <SelectValue placeholder="Select input" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem key={"default"} value={"-"}>
                                None
                            </SelectItem>
                            {inputSelection.map((item) => (
                                <SelectItem key={item.label} value={item.value}>
                                    {item.label}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                )}
            </div>

            {/* Output Variable */}
            <div className="col-span-1">
                <Label htmlFor={`outputVar-${index}`}>Step Output</Label>
                <InputValidation
                    id={`outputVar-${index}`}
                    value={group?.output_var || ""}
                    onChange={(e) => handleInputChange("output_var", e.target.value)}
                    className="bg-white"
                    validation={handler.validateOutputName}
                    disabled={
                        group?.output_var !== "" && (
                            // For LLM steps
                            inputGroups.slice(index + 1).some(
                                nextGroup => nextGroup.step_type === "LLM" && nextGroup.dynamic_inputs?.includes(group.output_var)
                            )
                            ||
                            // For Non-LLM steps
                            inputGroups.slice(index + 1).some(
                                nextGroup => nextGroup.step_type === "Non-LLM" && nextGroup.dynamic_inputs?.query_text === group.output_var
                            )
                        )
                    }
                />
            </div>
        </div>
    );
}
