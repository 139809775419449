import styled from "styled-components";

export const TypingLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  margin: 5px 0;

  div {
    width: 8px;
    height: 8px;
    margin: 0 2px;
    background-color: #9ca3af;
    border-radius: 50%;
    animation: bounce 1.5s infinite ease-in-out;
  }

  div:nth-child(1) {
    animation-delay: -0.32s;
  }
  div:nth-child(2) {
    animation-delay: -0.16s;
  }
  @keyframes bounce {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`;