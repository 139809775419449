/* eslint-disable react/jsx-indent */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { NavTabV2 } from "./components/NavTabv2";
import styled from "styled-components";
import "./conversation-legal.css";
import DropdownMenu from "../../../../components/DropdownMenu";
import * as ShadeDD from "../../../../components/ui/dropdown-menu";
import * as pdfjs from "pdfjs-dist";
import { RedButton } from "../../../../components/Buttons";
import { Context } from "../../../../context/GlobalState";
import { useParams } from "react-router-dom";
import {
  ChatDocument,
  ClassifyDocument,
  CompareClause,
  DeleteDocument,
  GetDocuments,
  TestEndpoint,
  UploadDocuments,
} from "./utils/httpRequests-1";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { LoaderSpinner } from "../../../../components/LoaderSpinner";
import { IoMdEye } from "react-icons/io";
import { IoWarningOutline } from "react-icons/io5";
import useComponentVisible from "../../../../hooks/useClickOutside";
import { BsX } from "react-icons/bs";
import { PiNutFill } from "react-icons/pi";
import WebChat from "../../../../components/ChatComponent";
import RagList from "../../ListOptions/RAGMethod.json";
import { Combobox } from "../../../../components/ui/combobox";
import renderers from "../../renderers/renderers";
import { Input } from "../../../../components/ui/input";
import { httpRequestX } from "../../../../utils/httpsRequest";
import axios from "axios";
import DocumentUploadItem from "./components/DocumentUploadCard";
import { Button } from "../../../../components/ui/button";
import { Viewport } from "@radix-ui/react-scroll-area";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../../components/ui/tabs";
import { Card, CardDescription, CardFooter, CardContent, CardHeader, CardTitle } from "../../../../components/ui/card";
// import { CardContent } from "@mui/material";
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "../../../../components/ui/dialog";
import { EyeIcon, InfoIcon } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../../../components/ui/tooltip";
import { SubOrgContext } from "context/SubOrganizationContext";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const IframeStyled = styled.iframe`
  flex-shrink: 0;
  //   height: 1px;
  border: none;
  width: 40%;
  margin-bottom: 1rem;
`;
const IframeStyledFull = styled.iframe`
  flex-shrink: 0;
  //   height: 1px;
  border: none;
  width: 40vw;
  height: 40vh;
  margin-bottom: 1rem;
`;
const DemoDiv = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color:#fbfbfb;

  
`;
const PanelViewDiv = styled.div`
display: flex;
flex-direction: column;
  border-style: solid;
  border-radius: 0.5rem;
  border-width: ${({ $isSelected }) => ($isSelected ? "2px" : "1px")};
  border-color: "lightgrey";
  padding: 1rem;
  box-sizing: border-box;
  width: 100%;
  box-shadow: 0 5px 5px
    ${({ $withShadow }) => ($withShadow ? "rgba(0,0,0,0.1)" : "rgba(0,0,0,0)")};
  font-size: 0.9rem;
  cursor: ${({ $isSelectable }) => ($isSelectable ? "pointer" : "default")};
`;
const FloatingDiv = styled.div`
  position: absolute;
  padding: 0.75rem;
  background-color: white;
  min-width: 100px;
  min-height: 100px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);

  left: 10px;
  transform: translate(-50%, 0%);
  top: 20%;
  left: 50%;
`;

function PanelView({
  panelId,
  children,
  selectable,
  setSelected,
  selected,
  withShadow,
  value,
}) {
  const [isSelected, setIsSelected] = useState(false);
  const PanelRef = useRef(null);

  useEffect(() => {
    console.log(selected);
    if (panelId === selected?.content && selectable) {
      PanelRef.current.scrollIntoView({ behavior: "smooth" });
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [selected?.content]);
  const handleClick = () => {
    console.log("Selected document, view keyterm: " + selected)
    if (selectable) {
      setSelected(value);
    }
  };
  return (
    <PanelViewDiv
      ref={PanelRef}
      $withShadow={withShadow}
      $isSelected={isSelected}
      $isSelectable={selectable}
      onClick={handleClick}
    >
      {children}
    </PanelViewDiv>
  );
}
function PanelView2({
  panelId,
  children,
  selectable,
  setSelected,
  selected,
  withShadow,
  value,
}) {
  const [isSelected, setIsSelected] = useState(false);
  const PanelRef = useRef(null);

  useEffect(() => {
    console.log("selected",selected);
    if (panelId === selected?.documentRecord?._id && selected) {
      PanelRef.current.scrollIntoView({ behavior: "smooth" });
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [selected?.content]);

  useEffect(() => {
    console.log("value is selected: ", isSelected)
    console.log("value: ", value)
  },[isSelected])

  const handleClick = () => {
    if (selectable) {
      console.log("KeyTerm Value selected",value)
      setSelected(value);
    }
  };
  return (
    <PanelViewDiv
      className=""
      ref={PanelRef}
      $withShadow={withShadow}
      $isSelected={isSelected}
      $isSelectable={selectable}
      onClick={handleClick}
    >
      {children}
    </PanelViewDiv>
  );
}
function FloatingPDFView({ children }) {
  // const [isOpen, setIsOpen] = useState(false)
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  return (
    <>
      <IoMdEye
        size={20}
        style={{ cursor: "pointer" }}
        onClick={() => {
          document.getElementById("compareDocument").style.display = "block";
        }}
        color={"gray"}
      />
      <div style={{ display: "none" }} id={"compareDocument"}>
        <FloatingDiv ref={ref}>
          <div className="v3-legal-floating-view-header">
            <BsX
              size={20}
              style={{ cursor: "pointer" }}
              onClick={() =>
                (document.getElementById("compareDocument").style.display =
                  "none")
              }
            />
          </div>
          {children}
        </FloatingDiv>
      </div>
    </>
  );
}
const MockDocumentList = [
  { name: "Document 1", value: "doc1" },
  { name: "Document 2", value: "doc2" },
  { name: "Document 3", value: "doc3" },
  { name: "Document 4", value: "doc4" },
];

export default function DemoModeX() {
  // const { subOragID } = useParams();
  const subOragID = "6639b5b112bba60113cde5c0";
  const oragID = "64f9f31ab8f2b3a65cbfc48d";
  const [activeTab, setActiveTab] = useState("selectDocument")
  const [processed, setProcessed] = useState(false);
  const [docList, setDocList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [messageLoading, setMessageLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [comparisonResult, setComparisonResult] = useState(null);
  const [passageToCompare, setPassageToCompare] = useState(null);
  const [viewedPdf, setViewedPdf] = useState(null);
  const [viewKeyTerm, setViewKeyTerm] = useState(null);
  const [mode, setMode] = useState(null);
  const [showCompareResult, setShowCompareResult] = useState(false);
  const [messages, setMessages] = useState([]);
  const [docClassificationResult, setDocClassificationResult] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState([]);
  const [selectedRag, setSelectedRag] = useState("methodB");
  const [fileArrStatus, setFileArrStatus] = useState([]);
  const [fileArr, setFileArr] = useState([]);
  const [docCompare, setDocCompare] = useState(null);
  const { user, addNewNotifcation } =
    useContext(Context);
  const [selectedTab, setSelectedTab] = useState(null);
  const [testStream, setTestStream] = useState([]);
  const [checked, setChecked] = useState(false);

  const FileInputRef = useRef(null);
  const handleGetDocuments = async () => {
    console.log(oragID, subOragID);
    GetDocuments(
      oragID,
      subOragID,
      JSON.stringify({
        project: "ProjectXDemo",
      })
    )
      .then((response) =>
        setDocList(
          response.data.map((file) => ({
            documentRecord: { ...file },
          }))
        )
      )
      .catch(() => {
        addNewNotifcation(
          "Cannot retrieve documents for the dropdown.",
          "danger"
        );
      });
  };
  const handleDocListKeyTerm = () => {
    if (fileArrStatus.length > 0) {
      return fileArrStatus.map((file) => (
        <PanelView2
          panelId={file?.documentRecord?._id}
          withShadow
          selected={viewKeyTerm}
          setSelected={setViewKeyTerm}
          selectable
          value={file}
        >
          <div style={{ fontWeight: "bold" }}>{file.label}</div>
          {/* <pre className="v3-legal-pre-wrap">
          {keyClause.content}
        </pre> */}
        </PanelView2>
      ));
    } else if (selectedDocument.length > 0) {
      return selectedDocument.map((file) => (
        <PanelView2
          panelId={file.documentRecord?._id}
          withShadow
          selected={viewKeyTerm}
          setSelected={setViewKeyTerm}
          selectable
          value={file}
        >
          <div style={{ fontWeight: "bold" }}>
            {file.documentRecord.filename}
          </div>
        </PanelView2>
      ));
    }
  };
  const handleListClassify = () => {
    if (fileArrStatus.length > 0) {
      return fileArrStatus.map((file) => (
        <PanelView>
          <span className="block text-md ">{file.label}</span>
          <span className="block text-lg font-bold">
            {file.documentTypeWithDescription?.Classification}
          </span>
          <span className="block text-md ">
            {file.documentTypeWithDescription?.Explanation}
          </span>
          {/* <pre className="v3-legal-pre-wrap">
            {keyClause.content}
          </pre> */}
        </PanelView>
      ));
    } else if (selectedDocument.length > 0) {
      return selectedDocument.map((file) => (
        <PanelView>
          <span className="block text-md ">{file.documentRecord.filename}</span>
          <span className="block text-lg font-bold">
            {file.documentTypeWithDescription?.Classification}
          </span>
          <span className="block text-md ">
            {file.documentTypeWithDescription?.Explanation}
          </span>
          {/* <pre className="v3-legal-pre-wrap">
            {keyClause.content}
          </pre> */}
        </PanelView>
      ));
    }
  };
  const handleClassifyDocument = async () => {
    switch (selectedTab) {
      case "Document Comparison":
        setIsLoading(true);
        setShowResult(true);
        CompareClause({
          documentId: docCompare._id,
          organizationId: oragID,
          subOrganizationId: subOragID,
          refDoc: selectedDocument.filename,
          compareDoc: docCompare.filename,
          selectedClause: passageToCompare,
        })
          .then((response) => {
            setShowCompareResult(true);
            setComparisonResult(response.data);
            setIsLoading(false);
          })
          .catch(() => {
            addNewNotifcation("Error occured in comparing document", "danger");
          });
        break;
      default:
        console.log(oragID, subOragID);
        setIsLoading(true);
        setShowResult(true);
        setDocClassificationResult(null);
        ClassifyDocument({
          organizationId: oragID,
          subOrganizationId: subOragID,
          documentId: selectedDocument?._id,
        })
          .then((response) => {
            console.log(response.data);
            setDocClassificationResult(response.data);
            setIsLoading(false);
          })
          .catch((e) => {
            console.log("Error processing:", e);
            addNewNotifcation(
              "Error occured in classifying document.",
              "error"
            );
            setIsLoading(false);
          });
    }
  };

  useEffect(() => {
    console.log(viewKeyTerm);
  }, [viewKeyTerm]);
  useEffect(() => {
    console.log("viewedPDF:", viewedPdf);
  }, [viewedPdf]);

  useEffect(()=>{
    console.log("fileArrstatus:", fileArrStatus)
  },[])
  useEffect(() => {
    console.log(testStream);
  }, [testStream]);

  const disableDecide = () => {
    switch (selectedTab) {
      case "Document Comparison":
        if (isLoading || !passageToCompare || !docCompare) {
          return true;
        }
        break;
      default:
        return isLoading || !selectedDocument ? true : false;
    }
  };

  useEffect(() => {
    handleGetDocuments();
    console.log(user);

    window.addEventListener("beforeunload", function (e) {
      var confirmationMessage = "o/";
      e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
      return "Hello"; // Gecko, WebKit, Chrome <34
    });

    return () => {
      for (let doc of fileArrStatus) {
        console.log("delete instide for loop");
        DeleteDocument({
          projectId: doc.documentRecord.projectIdDiscovery,
          collectionId: doc.documentRecord.collectionIdDiscovery,
          methodAId: doc.documentRecord.documentIds.methodA,
          methodBId: doc.documentRecord._id,
          organizationId: oragID,
          subOrganizationId: subOragID,
        });
      }
      console.log("Unmounts");
    };
  }, []);
  useEffect(() => {
    console.log(fileArrStatus);
  }, [fileArrStatus]);

  const IdPicker = () => {
    // switch(selectedRag){
    //   case "methodA":
    //     return mode === "upload" ? fileArrStatus.map((file)=>file.documentRecord?.documentIds.methodA) : selectedDocument.map((file)=>file.documentRecord?.documentIds.methodA)
    //   case "methodB":
    //     return mode === "upload" ? fileArrStatus.map((file)=>file.documentRecord?._id) : selectedDocument.map((file)=>file.documentRecord?._id)
    //   default:
    //     return "";
    // }
    
    return {
      // methodA:
      //   mode === "upload"
      //     ? fileArrStatus.map(
      //         (file) => {
      //           console.log("file", file)
      //           return file.documentRecord?.documents._id
      //         }
      //       )
      //     : selectedDocument.map(
      //         (file) => {
      //           console.log("file", file)
      //           return file.documentRecord?.documents._id
      //         }
      //       ),
      methodB:
        mode === "upload"
          // ? fileArrStatus.map((file) => file.documentRecord?._id)
          // : selectedDocument.map((file) => file.documentRecord?._id),
          ? fileArrStatus.map(
            (file) => {
              console.log("file", file)
              return file.documentRecord?.documents._id
            }
          )
          : selectedDocument.map(
            (file) => {
              console.log("file", file)
              return file.documentRecord?.documents._id
            }
          )
    };
  };
  const handleProcessFiles = async () => {
    setActiveTab("selectDocument");
    setIsLoading(true);
  
    if (mode === "upload") {
      if (fileArrStatus.length < 4) {
        for (const [count, file] of Array.from(fileArr).entries()) {
          try {
            const response = await UploadDocuments(
              file,
              "d7ed06c6-e9a1-7b04-0000-018f852de203",
              "165cd165-93f8-48d9-a070-48977d14d8a3",
              oragID,
              subOragID,
              1000,
              100,
              "ProjectXTempDemo",
              user.email,
              "methodA",
              true
            ).catch((e) => console.log("Error uploading:", e));
  
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
  
            while (true) {
              const { done, value } = await reader.read();
              if (done) break;
              const chunk = decoder.decode(value, { stream: true });
              console.log(chunk);
              if (fileArrStatus.length) {
                setFileArrStatus((prevArr) => {
                  let parsedChunk = JSON.parse(chunk);
                  console.log("LINE 413", parsedChunk);
                  const copyArr = [...prevArr];
                  copyArr[count].progress = Math.round(parsedChunk.progress);
                  copyArr[count].status = parsedChunk.status;
                  if (parsedChunk.resps) {
                    copyArr[count].documentRecord = parsedChunk.resps[0].documentRecord;
                    copyArr[count].extractedParameters = parsedChunk.resps[0].extractedParameters;
                    copyArr[count].documentTypeWithDescription = parsedChunk.resps[0].documentTypeWithDescription;
                  }
                  return copyArr;
                });
              }
            }
          } catch (error) {
            console.error("Error fetching the data", error);
          }
        }
        setProcessed(true);
        setIsLoading(false);
      }
    } else if (mode === "dropdown") {
      for (const [index, file] of selectedDocument.entries()) {
        try {
          console.log(file);
          const classifyExtract = await ClassifyDocument({
            documentId: [file.documentRecord._id],
            organizationId: file.documentRecord.organization_id,
            sub_organization_id: file.documentRecord.sub_organization_id,
          });
  
          setSelectedDocument((prevArr) => {
            const copyPrev = [...prevArr];
            copyPrev[index].extractedParameters = classifyExtract.data.keyClauses;
            copyPrev[index].documentTypeWithDescription = {
              Classification: classifyExtract.data.Classification,
              Explanation: classifyExtract.data.Explanation,
            };
            return copyPrev;
          });
        } catch (error) {
          console.error("Error classifying the document", error);
          addNewNotifcation("Error classifying the document. Please try again.", "error");
        }
      }
      setProcessed(true);
      addNewNotifcation("Processed file(s) successfully.", "success");
      setIsLoading(false);
    }
  };
  

  const handleSetUploadFiles = (e) => {
    if (Array.from(e.target.files).length < 3) {
      setMode("upload");
      console.log("files", e.target.files);
      setFileArr(e.target.files);
      setFileArrStatus(
        Array.from(e.target.files).map((file) => ({
          progress: 0,
          status: "Ready to upload",
          label: file.name,
          resps: [],
        }))
      );
    } else {
      e.preventDefault();
      addNewNotifcation("Cannot upload more than 2 files", "danger");
      e.target.value = null;
    }
  };

  useEffect(() => {
    setViewKeyTerm(null);
    setViewedPdf(null);
    // setProcessed(false)
  }, [selectedDocument, fileArrStatus]);

  useEffect(() => {
    console.log("line 541", selectedDocument);
    if (selectedDocument.length > 0) {
      setMode("dropdown");
    }
  }, [selectedDocument]);
  useEffect(() => {
    console.log(mode);
    if (mode) {
      switch (mode) {
        case "upload":
          setSelectedDocument([]);

          break;
        case "dropdown":
          setFileArr([]);
          setFileArrStatus([]);
          FileInputRef.current.value = null;
          break;
        default:
      }
    }
  }, [mode]);

  const listToUploadDocument = () => {
    return fileArrStatus.map((value) => (
      <DocumentUploadItem
        label={value.label}
        progress={value.progress}
        status={value.status}
      />
    ));
  };
  const listSelectedDocument = () => {
    return selectedDocument.map((value) => (
      <Card className="mt-2">
        <CardHeader>
          <CardTitle>{value.name}</CardTitle>
        </CardHeader>
      </Card>
    ))
  }
  const handleIfSelected = (doc) => {
    // console.log("handleIfSelected", doc);
    if (
      selectedDocument.find(
        (document) => document.documentRecord._id === doc.documentRecord._id
      )
    )
      return true;
    else return false;
  };
  return (
    <div className="flex gap-2 flex-wrap flex-grow">
    <Card className="h-full min-w-[70%] relative flex flex-grow flex-col p-1">
      <CardHeader>
        <CardTitle>Legal Assistant Demo</CardTitle>
        <CardDescription>Process documents and interact with a BeX Assistant for Legal.</CardDescription>

        {/* <Dialog */}
      </CardHeader>
        <CardContent className="flex flex-grow h-1 flex-col box-border">
          <Tabs onValueChange={(value) => setActiveTab(value)} value={activeTab} className="w-full flex-grow h-1">
            <TabsList className="flex gap-2">
              <TabsTrigger value="selectDocument">Select Document</TabsTrigger>
              <TabsTrigger disabled={processed && !isLoading ? false :true} value="documentClassifier">
                Document Classifier
              </TabsTrigger>
              <TabsTrigger disabled={processed && !isLoading ? false :true} value="keyTermsExtracted">
                Key terms extracted
              </TabsTrigger>
              <TabsTrigger disabled={processed && !isLoading ? false :true} value="chatBex">Chat BeX Assistant</TabsTrigger>
            </TabsList>
           
            <TabsContent className="h-[90%] overflow-auto" value="selectDocument">

                <Card>
                  <CardHeader>
                    <CardTitle>Select Documents</CardTitle>
                    <CardDescription>Select up to two files from the selection.</CardDescription>
                  </CardHeader>

                  <CardContent>
                    <label>
                      <ShadeDD.DropdownMenu>
                        <ShadeDD.DropdownMenuTrigger asChild>
                          <Button className="w-fit block mt-1">Select files</Button>
                        </ShadeDD.DropdownMenuTrigger>
                        <ShadeDD.DropdownMenuContent>
                          <ShadeDD.DropdownMenuLabel>
                            Documents
                          </ShadeDD.DropdownMenuLabel>
                          <ShadeDD.DropdownMenuSeparator />
                          {/* <ShadeDD.DropdownMenuItem onSelect={event => event.preventDefault()}>Hello</ShadeDD.DropdownMenuItem> */}
                          {docList.map((doc) => (
                            <ShadeDD.DropdownMenuCheckboxItem
                              checked={handleIfSelected(doc)}
                              onSelect={(event) => {
                                const result = selectedDocument.find(
                                  (document) =>
                                    document.documentRecord._id ===
                                    doc.documentRecord._id
                                );
                                event.preventDefault();
                                if (result) {
                                  setSelectedDocument((prev) =>
                                    prev.filter(
                                      (docTofilter) =>
                                        docTofilter.documentRecord._id !==
                                        result.documentRecord._id
                                    )
                                  );
                                } else {
                                  if (selectedDocument.length < 2) {
                                    setSelectedDocument((prev) => [
                                      ...prev,
                                      {
                                        name: doc.documentRecord?.filename,
                                        value: { doc },
                                        documentRecord: doc.documentRecord,
                                      },
                                    ]);
                                  }
                                }
                              }}
                            >
                              {doc.documentRecord?.filename}
                            </ShadeDD.DropdownMenuCheckboxItem>
                          ))}
                        </ShadeDD.DropdownMenuContent>
                      </ShadeDD.DropdownMenu>
                    </label>
                    {listSelectedDocument()}
                  </CardContent>
                </Card>
                <span className="text-sm block mt-2 mb-2">Or</span>

                <Card>
                <CardHeader>
                    <CardTitle>Upload Documents</CardTitle>
                    <CardDescription> Upload files by clicking the "Choose files".</CardDescription>
                  </CardHeader>
                  <CardContent>
                    <label htmlFor="">
                     
                      <Input
                        ref={FileInputRef}
                        multiple
                        type="file"
                        className="bg-background block mt-1"
                        onChange={handleSetUploadFiles}
                      />
                    </label>
                    <div className="flex-grow overflow-auto">
                      {listToUploadDocument()}
                    </div>
                  </CardContent>
                </Card>
                <div className="flex flex-row-reverse">
                  
                </div>
                  

                

                
            </TabsContent>
            <TabsContent className="h-[90%]" value="documentClassifier">
              <div className="flex flex-col gap-1">{handleListClassify()}</div>
            </TabsContent>
            <TabsContent className="h-[90%]" value="keyTermsExtracted">
              <div className="v3-legal-extractor-height h-full">
                <div className="v3-legal-extractor-container">
                  <div className="flex flex-grow h-1">
                    <div className="border border-solid flex flex-col gap-1 flex-shrink-0 w-[30%] p-4">
                      {handleDocListKeyTerm()}
                    </div>
                    <div className="border flex-grow border-solid flex flex-col gap-3 p-4 overflow-auto">
                      {viewKeyTerm?.extractedParameters?.map((param, i) => (
                        <PanelView>
                          <span className="block font-bold text-md">
                            {param.keyTerm || param.label}
                          </span>
                          <span className="block text-md">
                            {param.value || param.content}
                          </span>
                        </PanelView>
                      ))}
                    </div>
                  </div>

                  <div className=""></div>
                </div>
              </div>
            </TabsContent>
            <TabsContent className="h-[90%]" value="chatBex">

                <WebChat
              className="h-full max-h-full"
                parentMessage={{
                  messages,
                  setMessages,
                  messageLoading,
                  setMessageLoading,
                }}
                SendFunction={ChatDocument}
                SendFunctionParams={{
                  organization_id: oragID,
                  sub_organization_id: subOragID,
                  ragMethod: selectedRag,
                  documentIds: IdPicker(),
                  projectId: "165cd165-93f8-48d9-a070-48977d14d8a3",
                  collectionIds: ["d7ed06c6-e9a1-7b04-0000-018f852de203"],
                  count: 5,
                }}
              />
            </TabsContent>

          </Tabs>
          
          </CardContent>
          <CardFooter className="pt-1 flex gap-2 flex-row-reverse">
          {activeTab == "selectDocument" ? <Button
            disabled={
              fileArr.length < 1 !== selectedDocument.length < 1 &&
              !isLoading
                ? false
                : true
            }
            onClick={() => {
              handleProcessFiles();
            }}
            className="w-fit hover:cursor-pointer right-0"
          >
            {isLoading ? <LoaderSpinner /> : "Process"}
          </Button> : null}
            <Dialog>
            {processed && !isLoading ? <DialogTrigger asChild><Button>View Document</Button></DialogTrigger> :null}
            <DialogContent className="max-w-[70vw] w-[70vw]">
              <DialogTitle>Document View</DialogTitle>
                <Combobox
                defaultDisplayed="Select to View"
                  className="mb-5"
                  items={
                    mode === "upload"
                      ? fileArrStatus.map((doc) => {
                          if (doc?.documentRecord) {
                            return {
                              value: doc.documentRecord?.documents?.file_url,
                              name: doc.label,
                            };
                          }
                        })
                      : selectedDocument.map((doc) => {
                          if (doc?.documentRecord) {
                            return {
                              value: doc?.documentRecord?.file_url,
                              name: doc?.documentRecord?.filename,
                            };
                          }
                        })
                  }
                  selectedValue={viewedPdf}
                  itemCategory="Document"
                  setter={setViewedPdf}
                />
              {viewedPdf?.value ? <IframeStyled 
                className="flex-grow h-[75vh] w-full" 
                src={viewedPdf?.value?.split(".")[viewedPdf?.value.split(".").length-1] === "pdf" 
                  ? viewedPdf?.value 
                  : `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(viewedPdf?.value)}`}
              /> : null}

            </DialogContent>
            </Dialog>
            

          </CardFooter>
          {/* <CardFooter> */}
            
        {/* </CardFooter> */}
        {/* </div> */}
        
      {/* </div> */}
    </Card>
    </div>
  );
}
