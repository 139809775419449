/* eslint-disable max-lines */
import { useContext, useEffect, useState } from 'react'
import { Check, ChevronDown, ChevronsUpDown, ChevronUp, Search } from 'lucide-react'
import { Button } from "components/ui/button"
import { Input } from "components/ui/input"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "components/ui/table"
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs"
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from 'components/ui/dialog'
import { kbService } from 'api/services/PROJECT-O/KnowledgeBuilder.service'
import { useParams } from 'react-router-dom'
import { Context } from 'context/GlobalState'
import { ScrollArea } from 'components/ui/scroll-area'
import { Label } from 'components/ui/label'
import { unifiedModelService } from 'api/services/PROJECT-O/UnifiedModel.service'
import { cn } from "lib/utils"
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandSeparator } from "components/ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover"

export default function L2ProcessingResult({ isOpen, setIsOpen, setError }) {

    const [loading, setLoading] = useState(true)
    const [jsonData, setJsonData] = useState(null)

    const { oragID, subOragID, collectionId } = useParams()

    const [expandedRows, setExpandedRows] = useState(new Set())
    const [searchTerm, setSearchTerm] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedDocumentType, setSelectedDocumentType] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const toggleRowExpansion = (index) => {
        setIsLoading(true)
        setTimeout(() => {
        setExpandedRows(prevExpandedRows => {
            const newExpandedRows = new Set(prevExpandedRows)
            if (newExpandedRows.has(index)) {
            newExpandedRows.delete(index)
            } else {
            newExpandedRows.add(index)
            }
            return newExpandedRows
        })
        setIsLoading(false)
        }, 500) // Simulating a delay for loading
    }


    useEffect(() => {
        setError(null)
        const getObj = {
            "organization_id": oragID,
            "sub_organization_id": subOragID,
            "collection_id": collectionId,
            "limit": 10,
            "offset": 0,
            "export_as_csv": false
        }
        kbService.getL2Processing(getObj)
        .then((res) => {
            console.log('res: ', res);
            setJsonData(res.data)
        }).catch((error) => {
            console.log('error: ', error);
            setError(error)
        }).finally(() => {
            setLoading(false)
        })

    }, [])

    // if (loading) {
    //     return <h1>loading...</h1>
    // }

    let filteredData = []
    if (jsonData && jsonData?.data) {
        filteredData = jsonData?.data?.filter(doc =>
            (selectedDocumentType ? doc.document_type === selectedDocumentType : true) &&
            (doc.document_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            doc.document_type.toLowerCase().includes(searchTerm.toLowerCase()) ||
            doc.entries?.some(entry => 
            entry.extracted?.some(extractedData => 
                Object.values(extractedData)?.some(value => 
                value && value.toLowerCase().includes(searchTerm.toLowerCase())
                )
            )
            ))
        ) 
    }

    const itemsPerPage = 20
    const totalPages = Math.ceil(filteredData.length / itemsPerPage)
    const paginatedData = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)

    const documentTypes = Array.from(new Set(jsonData?.data.map(doc => doc.document_type)))

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen} className="mx-auto p-0">
            <DialogContent className='w-full max-w-[900px] p-0'>
                <ScrollArea className='max-h-[600px] p-4'>
                    <DialogHeader className='mb-4'>
                        <DialogTitle>Document Query</DialogTitle>
                    </DialogHeader>

                    <div className="flex flex-col md:flex-row justify-between items-center mb-6 gap-4">
                        <div className="relative w-full md:w-1/3">
                            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                            <Input
                            type="text"
                            placeholder="Search documents..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="pl-10"
                        />
                        </div>
                        <select
                        value={selectedDocumentType || ''}
                        onChange={(e) => setSelectedDocumentType(e.target.value || null)}
                        className="w-[300px] truncate p-2 border rounded"
                        >
                            <option value="">All Document Types</option>
                            {documentTypes.map((type, index) => (
                                <option key={index} className='w-[300px] truncate' value={type}>{type}</option>
                        ))}
                        </select>
                    </div>

                    <Card className="mb-6">
                        <CardContent className="p-0">
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead className="w-[50px]">Select</TableHead>
                                        <TableHead>Document Name</TableHead>
                                        <TableHead>Document Type</TableHead>
                                        <TableHead>Total Entries</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {paginatedData.map((doc, index) => (
                                        <TableRow key={index} className="cursor-pointer hover:bg-gray-100">
                                            <TableCell>
                                                <Button
                                                variant="ghost"
                                                size="sm"
                                                onClick={() => toggleRowExpansion(index)}
                                                >
                                                    {expandedRows.has(index) ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
                                                </Button>
                                            </TableCell>
                                            <TableCell>{doc.document_name}</TableCell>
                                            <TableCell>{doc.document_type}</TableCell>
                                            <TableCell>{doc.entries.length}</TableCell>
                                        </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>

                    {Array.from(expandedRows).map((expandedIndex) => {
                        const doc = paginatedData[expandedIndex]
                        if (!doc) return null

                        return (
                            <Card key={expandedIndex} className="mb-6">
                                <CardHeader>
                                    <CardTitle>Document Details: {doc.document_name}</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    {isLoading ? (
                                        <div className="flex justify-center items-center p-4">
                                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                                        </div>
                                    ) : (
                                        <Tabs defaultValue="entries">
                                            <TabsList className="mb-4">
                                                <TabsTrigger value="entries">Entries</TabsTrigger>
                                                <TabsTrigger value="extracted">Extracted Data</TabsTrigger>
                                            </TabsList>
                                            <TabsContent value="entries">
                                                <Table>
                                                    <TableHeader>
                                                        <TableRow>
                                                            <TableHead>ID</TableHead>
                                                            <TableHead>Page Number</TableHead>
                                                            <TableHead>Org ID</TableHead>
                                                            <TableHead>Sub Org ID</TableHead>
                                                        </TableRow>
                                                    </TableHeader>
                                                    <TableBody>
                                                        {doc.entries.map((entry, entryIndex) => (
                                                            <TableRow key={entryIndex}>
                                                                <TableCell>{entry.id}</TableCell>
                                                                <TableCell>{entry.page_number}</TableCell>
                                                                <TableCell>{entry.org_id}</TableCell>
                                                                <TableCell>{entry.sub_org_id}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TabsContent>
                                            <TabsContent value="extracted">
                                                <div className="space-y-6">
                                                    {doc.entries[0].extracted.map((extractedData, dataIndex) => (
                                                        <div key={dataIndex} className="bg-gray-50 p-4 rounded-lg">
                                                            <h3 className="text-lg font-semibold mb-2">Extracted Data {dataIndex + 1}</h3>
                                                            <dl className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-2">
                                                                {Object.entries(extractedData).map(([key, value]) => (
                                                                value && (
                                                                    <div key={key} className="flex flex-col">
                                                                        <dt className="text-sm font-medium text-gray-500">{key}</dt>
                                                                        <dd className="mt-1 text-sm text-gray-900">{value}</dd>
                                                                    </div>
                                                                )
                                                                ))}
                                                            </dl>
                                                        </div>
                                                    ))}
                                                </div>
                                            </TabsContent>
                                        </Tabs>
                                    )}
                                </CardContent>
                            </Card>
                        )
                    })}

                    <div className="flex justify-between items-center mt-6">
                        <Button
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                        >
                            Previous
                        </Button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <Button
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                        disabled={currentPage === totalPages}
                        >
                            Next
                        </Button>
                    </div>
                </ScrollArea>

            </DialogContent>
        </Dialog>
    )
}

export const L2ProcessingDialog = ({ isOpen, setIsOpen }) => {

    const [btnLoading, setBtnLoading] = useState(false)
    const [instructions, setInstructions] = useState([])
    const [modelsLoading, setModelsLoading] = useState(true)
    // const [selectedExtractionItems, setSelectedExtractionItems] = useState(null)
    const [selectedValidationItems, setSelectedValidationItems] = useState(null)
    
    const { oragID, subOragID, collectionId } = useParams()
    const { addNewNotifcation } = useContext(Context)

    const handleProcessing = () => {
        setBtnLoading(true)

        const processObj = {
            "organization_id": oragID,
            "sub_organization_id": subOragID,
            "collection_id": collectionId,
            // "unique_name_for_extraction": selectedExtractionItems.name,
            "unique_name_for_validation": selectedValidationItems.name,
            "filters": {}
        }

        kbService.l2Processing(processObj)
        .then((res) => {
            console.log('res: ', res);
            addNewNotifcation('L2 started processing',  'success')
            setIsOpen(false)
        }).catch((error) => {
            console.log('error: ', error);
            addNewNotifcation('Something went wrong. Please try again',  'danger')
        }).finally(() => {
            setBtnLoading(false)
        })
    }


    const fetchAllModels = async () => {
        try {
            setInstructions([]);
            const res = await unifiedModelService.GetModelsByOrgSubOrg(oragID, subOragID, 'Groq');
            console.log('res.data: ', res.data);
            
            if (!res.data?.FoundationModels) {
                return;
            }

            if (res.data?.FoundationModels.length === 0){
                console.log("No instructions saved.")
                return;
            }
            setInstructions(res.data?.FoundationModels.map(item => ({name: item, id: item})));

        } catch (e) {
            console.error("Error fetching models:", e);
        } finally {
            setModelsLoading(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            fetchAllModels().then((res) => console.log('fetch ress:', res))
        }
    }, [isOpen])

    // useEffect(() => console.log('instructions: ', instructions), [instructions])

    const handleValidationSelectChange = (item) => {
        setSelectedValidationItems(item)
    }

    // const handleExtractionSelectChange = (item) => {
    //     setSelectedExtractionItems(item)
    // }


    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen} className="mx-auto p-4">
            <DialogContent className='min-w-[900px]'>
                <DialogHeader>
                    <DialogTitle>Process L2 Document</DialogTitle>
                </DialogHeader>

                <div className='min-h-[100px] space-y-4'>
                    <div className='flex flex-col gap-2'>
                        <Label htmlFor="unique_name_for_extraction">Unique name for instruction</Label>
                        <ProcessingCombobox items={instructions} selectedValue={selectedValidationItems} setter={handleValidationSelectChange} disabled={modelsLoading} />
                    </div>

                    {/* <div className='flex flex-col gap-2'>
                        <Label htmlFor="unique_name_for_extraction">Unique name for validation</Label>
                        <ProcessingCombobox items={instructions} selectedValue={selectedExtractionItems} setter={handleExtractionSelectChange} disabled={modelsLoading} />
                    </div> */}
                </div>

                <DialogFooter>
                    <Button disabled={btnLoading} onClick={handleProcessing}> Start Processing </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>

    )
}


function ProcessingCombobox({ className, items, selectedValue, setter, defaultDisplayed = "Select Item" }) {
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState(selectedValue)

    useEffect(() => {
        setValue(selectedValue)
    }, [selectedValue])

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                variant="outline"
                role="combobox"
                aria-expanded={open}
                className="w-full justify-between text-accent-foreground overflow-hidden"
                >
                    <span className="truncate" title={value ? value?.name : defaultDisplayed ? defaultDisplayed : "Select Item"}>
                        {value ? value?.name : defaultDisplayed ? defaultDisplayed : "Select Item"}
                    </span>
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className={cn("w-full p-0 z-[9999]", className)}>
                <Command>
                    <CommandInput placeholder="Search..." />
                    <CommandEmpty>No item found.</CommandEmpty>
                    <CommandGroup>
                        <ScrollArea className="max-h-60">
                            {items?.map((item) => (
                                <CommandItem
                                    key={item?.id}
                                    value={item?.name}
                                    onSelect={() => {
                                    setValue(item)
                                    setter && setter(item)
                                    setOpen(false)
                                    }}
                                >
                                    <Check
                                    className={cn(
                                        "mr-2 h-4 w-4",
                                        value?.name === item?.name ? "opacity-100" : "opacity-0"
                                    )}
                                    />
                                    {item?.name}
                                </CommandItem>
                            ))}
                        </ScrollArea>
                    </CommandGroup>
                    <CommandSeparator />
                </Command>
            </PopoverContent>
        </Popover>
    )
}
