import { Button } from "components/ui/button";
import { Trash2 } from "lucide-react";

import StepLLM from "./StepLLM";
// import StepNonLLMFunction from "./StepNonLLMFunction";
import StepNonLLMAPI from "./StepNonLLMAPI";
import StepNonLLMBeXInsights from "./StepNonLLMBeXInsights";
function extractKeys(str) {
    const regex = /{{\s*output\['(\w+)'\]\s*}}/g;
    let match;
    const keys = [];

    while ((match = regex.exec(str)) !== null) {
        keys.push(match[1]);
    }

    return keys;
}

export default function InputGroup({ collections, organizationId, subOrganizationId, group, index, handleRemoveGroup, inputGroups, setInputGroups, modelDetails }) {
    
    // console.log(group)
    const getPreviousOutputs = (currentIndex) => {
        if (currentIndex <= 0) return [];

        return inputGroups
            .slice(0, currentIndex)
            .map((group, index) => ({
                value: group.output_var || "",
                label: `Step ${index + 1} Output`,
            }))
            .filter((item) => item.value.trim() !== "");
    };

    console.log(group)
    return (
        <div className="space-y-2 bg-gray-50 border shadow rounded p-2">
            <div className="flex flex-row gap-2 items-center justify-between">
                <h3 className="text-lg font-semibold">
                    Step {index + 1} - {
                        group?.step_type === "LLM" ?
                        "LLM" : group?.step_type === "Non-LLM" ?
                        "Non-LLM - RAG API" : group?.step_type
                    }
                </h3>
                <div className="flex items-center">
                    {inputGroups.length > 0 && (
                        <Button variant="destructive" className="w-full" onClick={() => handleRemoveGroup(index)}>
                            <Trash2 className="h-5 w-5" />
                        </Button>
                    )}
                </div>
            </div>
            
            {group?.step_type === "LLM" ? (
                <StepLLM organizationId={organizationId}
                subOrganizationId={subOrganizationId} group={group} index={index} inputGroups={inputGroups} setInputGroups={setInputGroups} modelDetails={modelDetails} getPreviousOutputs={getPreviousOutputs}/>
            ) 
            // : group?.registry_type === "function" ? (
            //     <StepNonLLMFunction  group={group} index={index} inputGroups={inputGroups} setInputGroups={setInputGroups} getPreviousOutputs={getPreviousOutputs}/>
            // )
             : group?.step_type === "Non-LLM - BeX Insights" ? (
                <StepNonLLMBeXInsights  group={group} index={index} inputGroups={inputGroups} setInputGroups={setInputGroups} getPreviousOutputs={getPreviousOutputs}/>
            ) : group?.registry_type === "api" ? (
                <StepNonLLMAPI collections={collections} group={group} index={index} inputGroups={inputGroups} setInputGroups={setInputGroups} getPreviousOutputs={getPreviousOutputs}/>
            ) : "" }

        </div>
    );
}
