import React, { useContext, useRef, useEffect, useState, useLayoutEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardFooter, CardDescription } from "components/ui/card";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { SendHorizontal, RotateCcw } from 'lucide-react';
import { AITeacherContext } from 'pages/PromptingAndRagTraining/context/AITeacherContext';
import { ScrollArea } from 'components/ui/scroll-area';
import { Context } from 'context/GlobalState';
import { DocumentList } from './DocumentList';
import { ragService } from 'api/services/PROJECT-O';
import { OrganizationContext } from 'context/OrganizationContext';
import { groqService } from 'api/services/PROJECT-O/GROQ.service';
import Markdown from "react-markdown";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import "../aiLab.css";
import remarkGfm from "remark-gfm";
import CopyButton from "../CopyButton";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select";

const AIChatBox = () => {
    const scrollTargetRef = useRef(null);
    const {
        initialMessage,
        messages, setMessages,
        isMessageLoading, setIsMessageLoading,
        selectedDocs,
        selectedMode, setSelectedMode
    } = useContext(AITeacherContext);
    const { addNewNotifcation } = useContext(Context);
    const { selectedOrganization } = useContext(OrganizationContext);
    
    
    const [input, setInput] = useState("");
    const [shouldScroll, setShouldScroll] = useState(false);

    useEffect(() => {
        if (shouldScroll && scrollTargetRef.current) {
            scrollTargetRef.current.scrollIntoView({ behavior: "smooth" });
            setShouldScroll(false); // Reset after scrolling
        }
    }, [shouldScroll]);


    // KeyDown on focused input
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          handleSend();
        }
    };

    const handleSend = async () => {
        if (!selectedDocs.length) {
            addNewNotifcation("Please select a document.", "warning");
            return;
        }
        if (isMessageLoading) {
            return;
        }

        // Validate input
        if (!input.trim()) {
            return;
        }
    
        let userInput = input.trim();
    
        try {
            setIsMessageLoading(true);
            const updatedMessages = [...messages, { role: "user", content: userInput }];
            setMessages(updatedMessages);
            setInput("");

            setShouldScroll(true);

            const RAGresults = await handleQuery();
            const compiledRAGResults = compileRAGResults(RAGresults);
            const constructedGroqMessage = constructGroqMessage(compiledRAGResults, updatedMessages, 6);
            const body = {
                "messages": constructedGroqMessage,
                "parameters": {
                    "temperature": 0,
                    "seed": null,
                    "max_tokens": 900,
                    "top_p": 1,
                    "stop": ["<|endofcode|>", "<|startoftext|>"],
                    "frequency_penalty": 0.0,
                    "presence_penalty": 0.0,
                },
                "modelId": "llama3-70b-8192"
            }

            await groqService.streamingExperiment(body, (assistantMessage) => {
                setMessages((prevMessages) => {
                    // Check if the last message is from the assistant
                    const lastMessage = prevMessages[prevMessages.length - 1];
                    if (lastMessage && lastMessage.role === "assistant") {
                        // Append new content to the last assistant message
                        return [
                            ...prevMessages.slice(0, -1), // Remove the last message
                            {
                                role: "assistant",
                                content: lastMessage.content + assistantMessage, // Append the new content
                            },
                        ];
                    } else {
                        // If the last message is not from the assistant, add a new message
                        return [
                            ...prevMessages,
                            {
                                role: "assistant",
                                content: assistantMessage,
                            },
                        ];
                    }
                });
            });
            
        } catch (error) {
            console.error(error);
        } finally {
            setIsMessageLoading(false);
        }
    };


    const handleQuery = async () => {
        const queries = selectedDocs.map(async (doc) => {
            const form = new FormData();
            form.append('document_id', doc._id);
            form.append("organization_id", selectedOrganization._id);
            form.append("sub_organization_id", process.env.REACT_APP_DEMO_MODE);
            form.append("query", input.trim());
            form.append("k", 3);
    
            const response = await ragService.queryData(form);
            return response.data.data.documents;
        });
    
        try {
            const responses = await Promise.all(queries);
            const allDocuments = responses.flat();
            console.log("All queries completed successfully:", allDocuments);
            return allDocuments || [];
        } catch (error) {
            console.error("Error occurred while querying:", error);
        }
        
    };

    const compileRAGResults = (documents) => {
        let compiled = "";
        documents.forEach(doc => {
            const metadata = doc.metadata;
            let source;
    
            if (metadata.start_page) {
                // Format with start_page
                source = `[${metadata.name} - Page ${metadata.start_page}](${encodeURIComponent(metadata.name)})`;
            } else if (metadata.slide_number) {
                // Format with slide_number
                source = `[${metadata.name} - Slide ${metadata.slide_number}](${encodeURIComponent(metadata.name)})`;
            } else {
                // Default to just the name
                source = `[${metadata.name}](${encodeURIComponent(metadata.name)})`;
            }
    
            // Compile information with updated source format
            compiled += `\nInformation: ${doc.page_content} and the source: ${source}\n`;
        });
    
        return compiled;
    };

    const constructGroqMessage = (context, msg_object, last_n_pages) => {
        // Create a deep copy of the msg_object's last messages
        const lastMessages = JSON.parse(JSON.stringify(msg_object.slice(-last_n_pages)));
    
        // Modify the content of the last message in the copied array
        if (lastMessages.length > 0) {
            lastMessages[lastMessages.length - 1].content = getPromptByMode(selectedMode) + lastMessages[lastMessages.length - 1].content;
        }
    
        // Create the system prompt
        const system_prompt = {
            role: "system",
            content: `You are AI Teacher. You act as an expert that can answer student questions based on the given context.
Context: ${context}`
        };
    
        // Combine system prompt and last messages into a new array
        const final_msg_object = [system_prompt, ...lastMessages];
    
        return final_msg_object;
    };
    


    const getPromptByMode = (mode) => {
        switch (mode) {
            case "teacher":
                return `Instructions:
- Act as an AI Teacher tutoring a student.
- Be helpful and answer questions concisely. If you don't know the answer, say 'I don't know'
- Utilize the context provided for accurate and specific information.
- Incorporate your preexisting knowledge to enhance the depth and relevance of your response.
- Explain the concept from the context then add a statement to encourage students to ask questions and think critically, and provide explanations that are clear, structured, and relatable.
- Use examples, analogies, metaphors and questions to make the explanation understandable.
- If appropriate, break down the information into smaller, digestible parts.
- Ask follow-up questions at the end of response to spark curiosity and critical thinking to user.
- Respond using markdown.
- Cite your sources for every information.
- Cite your sources using the markdown link format in the source

Student query: `

            case "quiz":
                return `Instructions:
- Act as an Quiz Generator for a student.
- Be helpful and respond concisely.
- Utilize the context provided for accurate and specific information. If there is no context about the given topic, say "I can't find that in the document" and do not give any quiz even if context is provided.
- The quiz should have multiple choices.
- Each item shall strictly have one correct answer only.
- Ensure questions test the user's knowledge accurately.
- Do not give the correct answer aside from the choices.
- Respond using markdown.
- Cite your sources for every question.
- Cite your sources using the markdown link format in the source

Student query: `
        }
    }

    const handleClear = async () => {
        setMessages(initialMessage);
    };

    

    return (
        <Card className="w-full flex flex-col justify-between h-fit min-h-[480px] max-h-[480px]">
            
            <CardHeader className="max-h-[72px] border-b-[1px] border-gray-200 px-4 py-3.5 flex flex-row flex-nowrap items-center gap-2 select-none">
                <div className="w-full flex flex-col">
                    <CardTitle className="text-sm">AI Teacher</CardTitle>
                    <CardDescription className="text-xs">Study with an AI.</CardDescription>
                </div>
                <div className="w-fit flex flex-row gap-2 items-center justify-end">
                    <Select
                        value={selectedMode}
                        onValueChange={(value) => setSelectedMode(value)}
                    >
                        <SelectTrigger id="mode-select" className="w-[180px] text-left">
                            <SelectValue placeholder="Select Mode" />
                        </SelectTrigger>
                        <SelectContent className="w-56">
                            <SelectItem value="teacher">
                                <div>
                                    <span className="font-bold">AI Teacher Mode</span>
                                    <p className="text-xs text-gray-500">Receive guided explanations and answers.</p>
                                </div>
                            </SelectItem>
                            <SelectItem value="quiz">
                                <div>
                                    <span className="font-bold">Quiz Mode</span>
                                    <p className="text-xs text-gray-500">Generate questions to test your knowledge.</p>
                                </div>
                            </SelectItem>
                        </SelectContent>
                    </Select>
                    
                    <DocumentList/>
                </div>
            </CardHeader>

            <CardContent className="flex flex-col justify-end gap-4 p-0 flex-grow">
                <ScrollArea className="h-[340px] px-2">
                    {
                        messages.filter((message) => message.role !== "system").map((chat, index) => (
                            <div key={index}>
                                
                                <div>
                                    {chat.role === "user" ? (
                                        <UserMessage content={chat.content} />
                                    ) : (
                                        <AssistantMessage content={chat.content} />
                                    )}
                                </div>

                                {isMessageLoading &&
                                    ((index === messages.length - 2 &&
                                        messages[messages.length - 1].role === "assistant") ||
                                        (index === messages.length - 1 &&
                                            messages[messages.length - 1].role === "user")) && (
                                                <div className="italic text-gray-400 text-sm mb-4">
                                                    <span>Thinking...</span>
                                                </div>
                                            )
                                }
                            </div>
                        ))
                    }
                    <div ref={scrollTargetRef} />
                </ScrollArea>
            </CardContent>

            <CardFooter className=" h-16 max-h-16 border-t-[1px] border-gray-200 px-4 py-4 flex flex-row flex-nowrap gap-2">
                <Input
                    value={input}
                    onChange={(e)=>{setInput(e.target.value)}}
                    onKeyDown={handleKeyDown}
                    type="text"
                    placeholder="Ask the AI Teacher..."
                    className="col-span-1 h-10 text-xs border-none shadow-none focus-visible:ring-0 focus-visible:ring-offset-0 focus:ring-0 ring-0 focus:outline-0 focus-visibe:outline-none outline-none"
                />
                <Button variant="ghost" size="icon" className="px-2" disabled={!input.trim() || isMessageLoading} onClick={handleSend}>
                    <SendHorizontal className="w-5 h-5" />
                </Button>
                <Button variant="ghost" size="icon" className="px-2" onClick={handleClear}>
                    <RotateCcw className="w-5 h-5" />
                </Button>
            </CardFooter>
        </Card>
    );
};


const UserMessage = ({ content }) => (
    <div className="flex mb-4 mt-4 gap-2 items-start justify-end">
        <div className="bg-gray-100 text-gray-800 rounded-lg p-3">
            <p className="text-xs break-words whitespace-pre-wrap">{content}</p>
        </div>
    </div>
);

const AssistantMessage = ({ content }) => {
    const markdownRef = useRef(null);
    const { selectedDocs, setClickedLink, setPageNumber } = useContext(AITeacherContext);

    useEffect(() => {
        if (markdownRef.current) {
            // Query all <a> elements within the Markdown content
            const links = markdownRef.current.querySelectorAll("a");

            links.forEach((link) => {
                const href = link.href;

                // Extract the file name from the href
                const fileName = decodeURIComponent(href.split("/").pop());

                // Find a matching document in selectedDocs
                const matchedDoc = selectedDocs.find((doc) => fileName.includes(doc.name));

                // Extract page number if present in the text (e.g., "page 7")
                const parentText = link.textContent || "";
                const pageMatch = parentText.match(/Page\s*(\d+)/i);
                const pageNumber = pageMatch ? parseInt(pageMatch[1], 10) : null;

                // Override the click behavior
                link.onclick = (e) => {
                    e.preventDefault(); // Prevent default navigation

                    const finalUrl = matchedDoc ? matchedDoc.file_url : href;

                    if (!matchedDoc) {
                        console.log(`No matching document found for "${fileName}"`);
                        return;
                    }
                    // Set the final URL in state
                    setClickedLink(finalUrl);


                    // Set the page number in state if it exists
                    if (pageNumber) {
                        setPageNumber(pageNumber);
                        // console.log(`Set page number: ${pageNumber}`);
                    } else {
                        console.log("No page number found.");
                    }
                };

                // Optionally, update the href in the DOM for consistency
                if (matchedDoc) {
                    link.href = matchedDoc.file_url; // Keep the file URL in the DOM
                }
            });
        }
    }, [content, selectedDocs]);
    
    return (
        <div className="flex mb-4 mt-4 gap-2 items-start min-h-full">
            <div className="bg-white text-gray-900 p-3 min-h-full">
                <div className="text-xs break-words whitespace-pre-wrap min-h-full" ref={markdownRef}>
                    <Markdown className="ai-lab-markdown" remarkPlugins={[remarkGfm]} 
                        components={{
                        code({ node, inline, className, children, ...props }) {
                            const match = /language-(\w+)/.exec(className || '');
                            const code = String(children).replace(/\n$/, '');
                            
                            inline = !className && !/\n/.test(code);
                            if (inline) {
                            // Inline Code Block
                            return (
                                <code
                                className="bg-gray-200 text-gray-800 rounded-[4px] px-1 text-xs font-mono"
                                style={{ whiteSpace: 'pre-wrap' }}
                                >
                                {children}
                                </code>
                            );
                            }
                    
                            if (!inline && match) {
                            // Multi-Line Code Block with Language
                            return (
                                <div className="relative rounded-[12px]">
                                <span className="absolute top-2 left-2 bg-gray-600 text-white text-xs px-2 py-1 rounded select-none">
                                    {match[1]}
                                </span>
                                <CopyButton code={code} className="text-gray-200" />
                                <SyntaxHighlighter
                                    style={darcula}
                                    language={match[1]}
                                    PreTag="div"
                                    {...props}
                                    customStyle={{ paddingTop: '2.5rem' }}
                                >
                                    {code}
                                </SyntaxHighlighter>
                                </div>
                            );
                            }
                    
                            // Multi-Line Code Block Without Language
                            return (
                            <div
                                className="bg-[#2c2c2c] rounded-md p-2 pt-[1.5rem] text-xs text-gray-200 font-mono whitespace-pre-wrap relative"
                                style={{ overflowX: 'auto' }}
                            >
                                <CopyButton code={code} className="text-gray-200" />
                                {children}
                            </div>
                            );
                        },
                    }}>
                        {content}
                    </Markdown>
                </div>
            </div>
        </div>
    )
};


export default AIChatBox;