import { useContext, useEffect, useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { OpCodeBuilderContext } from "pages/PromptingAndRagTraining/context/OpCodeBuilderContext";
import OpCodeForm from "pages/PromptingAndRagTraining/components/OpcodeBuilder/OpCodeForm";
import OpCodeExecution from "pages/PromptingAndRagTraining/components/OpcodeBuilder/OpCodeExecution";
import { OpCodeBuilderProvider } from 'pages/PromptingAndRagTraining/context/OpCodeBuilderContext';
import { redisCollectionService } from "api";

export default function DemoOpCodes() {

    return (
        <div className='w-full'>
            <div className='h-auto py-8'>
                <div className='flex justify-center items-center'>
                    <OpCodeBuilderProvider organizationId='demo' subOrganizationId='demo' >
                        <DemoOpCodeCreator />
                    </OpCodeBuilderProvider>
                </div>
            </div>
        </div>
    )
}


function DemoOpCodeCreator() {
    const { currentStep, setCurrentStep } = useContext(OpCodeBuilderContext)

    const [collections, setCollections] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        redisCollectionService.listCollections('demo', 'demo')
        .then((projectsResponse) => {
            console.log('projectsResponse: ', projectsResponse);
            setCollections(projectsResponse.data)
        }).catch((error) => {
            console.log('error:R', error);
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    return (
        <Card className="w-[1000px] select-none">
            <CardHeader>
                <CardTitle className="text-2xl font-bold">Workflow Builder</CardTitle>
            </CardHeader>
            <CardContent>
                <Tabs value={currentStep.toString()} className="w-full">
                    <TabsList className="grid w-full grid-cols-2">
                        <TabsTrigger value="0" onClick={() => setCurrentStep(0)}>Create Workflow</TabsTrigger>
                        <TabsTrigger value="1" onClick={() => setCurrentStep(1)}>Execute Workflow</TabsTrigger>
                    </TabsList>
                    <TabsContent value="0">
                        <OpCodeForm organizationId={'demo'} subOrganizationId={'demo'} collections={collections} />
                    </TabsContent>
                    <TabsContent value="1">
                        <OpCodeExecution organizationId={'demo'} subOrganizationId={'demo'} />
                    </TabsContent>
                </Tabs>
            </CardContent>
        </Card>
    );
}